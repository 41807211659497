import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import Ordering from "./Ordering";

import http from "../../../../helpers/http";

import "./style.css";

import config from "../../../../config/config";
import { useMediaQuery } from "react-responsive";

import urldata from "../../../../urldata";

function Index(props) {
  const { confirm } = Modal;
  const [loading, setloading] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [isOrderOpen, setisOrderOpen] = useState(false);

  const [data, setData] = useState({});
  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(1);
  const [editKey, seteditKey] = useState(1);
  const [datas, setDatas] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [btnSize, setBtnSize] = useState("middle");
  const [switchSize, setSwitchSize] = useState("default");
  
  const moduleName = "Media";
  const moduleNamePural = "Gallery";

  const base_url = urldata + "admin/careers/galleries";
  const image_url = urldata + "public/uploads/careerGallery/";
  const [modal, contextHolder] = Modal.useModal();

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + 1;
      });
      setData(data);
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + 1;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey(createKey + 1);
    setCreateOpen(false);
    fetchDatas();
  };

  const handleOrderClose = () => {
    setisOrderOpen(!isOrderOpen);
    fetchDatas();
  };

  const handleStatus = async (value, id) => {
    const status = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      status: status,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);

  };

  useEffect(() => {
    fetchDatas();
  },[]);

  useEffect(() => {
    if (isMobile) {
      setBtnSize("small");
      setSwitchSize("small");
    }
  }, [isMobile]);

  const columns = [
   
    
    {
      title: "Image",

      key: "image",
      render: (text, record) => (
        <Image
          width={isMobile ? 80 : 100}
          src={
            record.type == "image"
              ? image_url + record.image
              : image_url + record.thumbnail
          }
          fallback="https://via.placeholder.com/100"
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      expandable: true,
      responsive: ["lg"],
    },

    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            size={switchSize}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              handleStatus(value, record._id);
            }}
            defaultChecked={record.status}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleEdit(record._id);
            }}
            size={btnSize}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
            size={btnSize}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
        {/* {contextHolder} */}
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false} >
              <Row style={{ marginBottom: "20px" }}>
                <Col span={24}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleCreate}
                      size={btnSize}
                    >
                      Add {moduleName}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        console.log("order clicked");
                        setisOrderOpen(true);
                      }}
                      size={btnSize}
                    >
                      View Ordering
                    </Button>
                  </Space>
                </Col>
              </Row>

              <Table
                loading={loading}
                size={switchSize}
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                // expandable={{
                //   expandedRowRender: (record) => (
                //     <>
                //       {columns
                //         .filter((col) => col.expandable)
                //         .map((col) => {
                //           return (
                //             <p>
                //               <b>{col.title}: </b>{" "}
                //               {col.isDate
                //                 ? helper.ISTDate(record[col.dataIndex])
                //                 : record[col.dataIndex] || "-"}
                //             </p>
                //           );
                //         })}
                //     </>
                //   ),
                //   showExpandColumn: isMobile,
                //   // rowExpandable: (record) => record.name !== "Not Expandable",
                // }}
                footer={() => `Total ${moduleNamePural} =  ${datas.length}`}
              />

              <Edit
                key={"edit" + editKey}
                isVisible={editOpen}
                handleClose={handleEditClose}
                dbData={data}
                moduleName={moduleName}
                base_url={base_url}
                image_url={image_url}
              />
              <Create
                key={"create" + createKey}
                isVisible={createOpen}
                handleClose={handleCreateClose}
                moduleName={moduleName}
                base_url={base_url}
                image_url={image_url}
              />
              <Ordering
                // key={"order" + orderKey}

                isOpen={isOrderOpen}
                onClose={handleOrderClose}
                datas={datas}
                moduleName={moduleName}
                isMobile={isMobile}
                base_url={base_url}
                image_url={image_url}
              />
             
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
