import React, { useState, useEffect } from "react";
import "./style.less";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import TvAdsItem from "../TvAdsItem/TvAdsItem";

import { Col, Row } from "react-bootstrap";
import JobApplyModel from "../JobApplyModel/JobApplyModel";
import TvAdsModel from "../TvAdsModel/TvAdsModel";
import LAXMISMR30SEC from "../../images/tvads/LAXMISMR30SEC.jpg";
import LaxmiBesanDiwalifilmyoutube from "../../images/tvads/LaxmiBesanDiwalifilmyoutube.jpg";
import COVIDThankyou from "../../images/tvads/COVIDThankyou.jpg";
import CovidstaysafeLaxmiCares from "../../images/tvads/CovidstaysafeLaxmiCares.jpg";
import CovidHOScommitment from "../../images/tvads/CovidHOScommitment.jpg";
import GarviGujrat60SecsLowRes from "../../images/tvads/GarviGujrat60SecsLowRes.jpg";
import LAXMIHAPPYDIWALI60SEC from "../../images/tvads/LAXMIHAPPYDIWALI60SEC.jpg";
import LaxmiBrownBasmatiRice from "../../images/tvads/LaxmiBrownBasmatiRice.jpg";
import LaxmiSharbattiChappatiFlour from "../../images/tvads/LaxmiSharbattiChappatiFlour.jpg";
import LaxmiLowerGIValueRice from "../../images/tvads/LaxmiLowerGIValueRice.jpg";
import ChatpatProduct from "../../images/tvads/ChatpatProduct.jpg";
import HOSOldVideo from "../../images/tvads/HOSOldVideo.jpg";
import HOSCorporateFilm60sec from "../../images/tvads/HOSCorporateFilm60sec.jpg";
import itemImg from "../../images/tvads/thumb.jpg";

import useWindowDimensions from "../../useWindowDimensions";
import Imageurldata from "../../Imageurldata";


// const props.tvAdsArr = [
//   { img: LAXMISMR30SEC, date: "22 Feb, 2021 ", youtubeId: "ysiFm_WUjm4" },
//   {
//     img: LaxmiBesanDiwalifilmyoutube,
//     date: "22 Feb, 2021",
//     youtubeId: "De2urICk42E",
//   },
//   { img: COVIDThankyou, date: "19 Feb, 2021", youtubeId: "BHbpj1dsyzE" },
//   {
//     img: CovidstaysafeLaxmiCares,
//     date: "19 Feb, 2021",
//     youtubeId: "RkZb8zN8V1s",
//   },
//   { img: CovidHOScommitment, date: "18 Feb, 2021", youtubeId: "HFE32pCtlzQ" },
//   {
//     img: GarviGujrat60SecsLowRes,
//     date: "03 Feb, 2021",
//     youtubeId: "zyFBfTjISyo",
//   },
//   {
//     img: LAXMIHAPPYDIWALI60SEC,
//     date: "03 Feb, 2021",
//     youtubeId: "nX0YJgUP3rQ",
//   },
//   {
//     img: LaxmiBrownBasmatiRice,
//     date: "03 Feb, 2021",
//     youtubeId: "fwn6nw8sE9k",
//   },
//   {
//     img: LaxmiSharbattiChappatiFlour,
//     date: "03 Feb, 2021",
//     youtubeId: "jGvUOBJJoPQ",
//   },
//   {
//     img: LaxmiLowerGIValueRice,
//     date: "03 Feb, 2021",
//     youtubeId: "ksy  eoJJMvy4",
//   },
//   { img: ChatpatProduct, date: "03 Feb, 2021", youtubeId: "t31xUfpKLGc" },
//   { img: HOSOldVideo, date: "08 Jan, 2021", youtubeId: "uhl7RB2XKWg" },
//   {
//     img: HOSCorporateFilm60sec,
//     date: "07 Jan, 2021",
//     youtubeId: "68dHzRYgVEc",
//   },
// ];

const TvAds = (props) => {
  const { height, width } = useWindowDimensions();

  const [totalPages, setTotalPage] = useState(10);
  const [selectedPage, setSelectedPage] = useState(0);
  const [totalPageArray, setTotalPageArray] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [pgLoading, setPgLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [selectedImage, setSelectedImg] = useState("");
  const [youtubeId, setYoutubeId] = useState("");

  const [selectedArray, setSelectedArray] = useState([]);
  const [totalSlicePageArray, settotalSlicePageArray] = useState([]);
  console.log(selectedArray);
  useEffect(() => {
    document.title = "TV ADS - Brand Commercials";
    document.getElementsByTagName("META")[3].content =
      "HOS Global Foods Brands Commercials - TV ADS.";
    document.getElementsByTagName("META")[4].content =
      "HOS Global Foods Brands commercials, HOS Global Foods TV ADS";

    setSelectedPage(0);
    if (width < 1024) {
      setTotalPage(Math.ceil(props.tvAdsArr.length / 3));
      let temp = [];
      ////console.log(
      //   Math.ceil(AutomotiveArr.length / 4),
      //   AutomotiveArr.length
      // )
      for (let i = 1; i <= Math.ceil(props.tvAdsArr.length / 3); i++) {
        temp.push(i);
      }
      setTotalPageArray(...[temp]);
      ////console.log(temp)
      settotalSlicePageArray(...[temp.slice(0, 3)]);

      let pagestart = 0;
      let temp2 = [...props.tvAdsArr];
      setSelectedArray(temp2.slice(pagestart, pagestart + 3));
    } else {
      setTotalPage(Math.ceil(props.tvAdsArr.length / 9));

      let temp = [];

      for (let i = 1; i <= Math.ceil(props.tvAdsArr.length / 9); i++) {
        temp.push(i);
      }
      setTotalPageArray(...[temp]);
      settotalSlicePageArray(...[temp.slice(0, 3)]);

      let pagestart = 0;
      let temp2 = [...props.tvAdsArr];
      setSelectedArray(temp2.slice(pagestart, pagestart + 9));
    }
  }, []);

  useEffect(() => {
    if (width < 1024) {
      let pagestart = selectedPage * 3;
      let temp = [...props.tvAdsArr];
      setSelectedArray(temp.slice(pagestart, pagestart + 3));
    } else {
      let pagestart = selectedPage * 9;
      let temp = [...props.tvAdsArr];
      setSelectedArray(temp.slice(pagestart, pagestart + 9));
    }
    leftArrowClickOpacityHandle();
    rightArrowClickOpacityHandle();
  }, [selectedPage]);

  const leftArrowClick = () => {
    if (!(selectedPage - 1 < 0)) {
      //   ////console.log("total page", totalPageArray.length)
      //   ////console.log("current page", selectedPage)
      //   ////console.log("total page-2", totalPageArray.length - 2)
      //   ////console.log("==>", totalPageArray.length - 2 >= selectedPage)

      if (totalPageArray.length - 2 >= selectedPage) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage - 1, selectedPage + 2)
        );
      }

      setSelectedPage(selectedPage - 1);
      scrollToDiv();
    }
  };
  const nextArrowClick = () => {
    if (selectedPage + 1 !== totalPageArray.length) {
      if ((selectedPage + 1) % 2 === 0 || selectedPage >= 2) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage, selectedPage + 3)
        );
      }

      setSelectedPage(selectedPage + 1);

      scrollToDiv();
    }
    // totalPageArray.length === (selectedPage+1) ?null :
  };
  const scrollToDiv = () => {
    const id = "list";
    const yOffset = -170;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const paginationNoClick = (i) => {
    setSelectedPage(i - 1);
    scrollToDiv();
  };

  const tvAdsItemClick = (id, img) => {
    //console.log(id)
    setYoutubeId(id);
    setSelectedImg(Imageurldata + img);
    setModalShow(true);
    setPlayVideo(false);
  };

  const tvAdslist = selectedArray.map((item, i) => {
    return (
      <TvAdsItem
        key={i}
        img={Imageurldata + item.imgUrl}
        date={item.date}
        title={item.title}
        tvAdsClick={() => tvAdsItemClick(item.youtubeId, item.imgUrl)}
      />
    );
  });

  const leftArrowClickOpacityHandle = () => {
    if (selectedPage === 0) {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = ".5";
        document.getElementById("paginationLeftarrow").style.cursor = "initial";
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = "1";
        document.getElementById("paginationLeftarrow").style.cursor = "pointer";
      } catch (error) {}
    }
  };

  const rightArrowClickOpacityHandle = () => {
    if (totalPages === selectedPage + 1) {
      try {
        document.getElementById("paginationrightarrow").style.opacity = ".5";
        document.getElementById("paginationrightarrow").style.cursor =
          "initial";
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationrightarrow").style.opacity = "1";
        document.getElementById("paginationrightarrow").style.cursor =
          "pointer";
      } catch (error) {}
    }
  };

  return (
    <div className="TvAds">
      <div className="title">Enjoy our Brand Commercials.</div>

      <Row id="list" className="row m-0">
        {tvAdslist}
      </Row>
      {totalPageArray.length === 1 ? null : (
        <div className="d-f j-c a-c productListPaginationContainer">
          <div onClick={leftArrowClick} id="paginationLeftarrow">
            Prev
          </div>
          <PaginationComponent
            // paginationNoClick={(item) => paginationNoClick(item)}
            paginationNoClick={(item) => paginationNoClick(item)}
            totalPageArray={totalSlicePageArray}
            selectedPage={selectedPage}
          />

          <div onClick={nextArrowClick} id="paginationrightarrow">
            Next
          </div>
        </div>
      )}
      <TvAdsModel
        show={modalShow}
        props={props}
        img={selectedImage}
        youtubeId={youtubeId}
        playVideo={playVideo}
        onClickVideo={() => setPlayVideo(true)}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default TvAds;
