import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";
const expinyearsOptions = [
  {
    name: "",
  },
  {
    name: "Months",
  },
  {
    name: "Years",
  },
];

const JobPostlist = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [exptabshow, setExptabshow] = useState(false);
  const [designation, setDesignation] = useState("");
  const [designationerr, setDesignationerr] = useState("");
  const [location, setLocation] = useState("");
  const [locationerr, setLocationErr] = useState("");
  const [desc, setDesc] = useState("");
  const [descerr, setDescErr] = useState("");
  const [skills, setSkills] = useState("");
  const [skillserr, setSkillsErr] = useState("");
  const [minexp, setMinexp] = useState("0");
  const [experr, setexpErr] = useState("");
  const [minexperr, setMinexpErr] = useState("");
  const [maxexp, setMaxexp] = useState("0");
  const [maxexperr, setMaxexpErr] = useState("");
  const [expinyears, setExpinyears] = useState("");
  const [expinyearserr, setExpinyearsErr] = useState("");

  const [updateModel, setUpdateModel] = useState(false);
  const [jobId, setjobId] = useState("");

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);
    setExptabshow(false);
    setDesignation("");
    setLocation("");
    setDesc("");
    setSkills("");
    setMinexp("");
    setMaxexp("");
    setExpinyears("");
    setDescErr("");
    setDesignationerr("");
    setExpinyearsErr("");
    setLocationErr("");
    setMaxexpErr("");
    setMinexpErr("");
    setSkillsErr("");
    setexpErr("");
    setShow(true);
  };

  const handleShowForUpdate = (rowData) => {
    setjobId(rowData._id);
    setUpdateModel(true);
    if (rowData.experienceInYear === "Years") {
      setExptabshow(true);
      setMinexp(rowData.fromExp);
      setMaxexp(rowData.toExp);
    } else {
      setExptabshow(false);
    }

    setDesignation(rowData.designation);
    setLocation(rowData.location);
    setDesc(rowData.desc);
    setSkills(rowData.skills);

    setExpinyears(rowData.experienceInYear);
    setDescErr("");
    setDesignationerr("");
    setExpinyearsErr("");
    setLocationErr("");
    setMaxexpErr("");
    setMinexpErr("");
    setSkillsErr("");
    setexpErr("");
    setShow(true);
  };

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/jobPost");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
      })
      .catch((error) => {
        // showToast("danger");
        setLoading(false)
      });
  }, []);

  const submit = () => {
    var formIsValid = true;

    if (!designation) {
      formIsValid = false;
      setDesignationerr("*Please enter designation.");
    } else {
      setDesignationerr("");
    }

    if (!location) {
      formIsValid = false;
      setLocationErr("*Please enter location.");
    } else {
      setLocationErr("");
    }

    if (!desc) {
      formIsValid = false;
      setDescErr("*Please enter Desc.");
    } else {
      setDescErr("");
    }

    if (!skills) {
      formIsValid = false;
      setSkillsErr("*Please enter skills.");
    } else {
      setSkillsErr("");
    }

    if (updateModel) {
      if (
        !(
          document.getElementById("Fresher").checked ||
          document.getElementById("Experienced").checked
        )
      ) {
        formIsValid = false;
        setexpErr("Please enter experience");
      }
    }

    if (exptabshow) {
      if (minexp === "") {
        formIsValid = false;
        setMinexpErr("*Please enter minimum experiece.");
      } else {
        setMinexpErr("");
      }

      if (maxexp === "") {
        formIsValid = false;
        setMaxexpErr("*Please enter maximum experiece.");
      } else {
        setMaxexpErr("");
      }
    }

    if (formIsValid) {
      if (updateModel) {
        updateJobPost();
      } else {
        createJobPost();
      }
    }
  };

  const createJobPost = () => {
    let data = {};
    data.designation = designation;
    data.location = location;
    data.desc = desc;
    data.skills = skills;
    if (exptabshow) {
      data.fromExp = minexp;
      data.toExp = maxexp;
      data.experienceInYear = "Years";
    } else {
      data.fromExp = 0;
      data.toExp = 0;
      data.experienceInYear = "Fresher";
    }

    let url = new URL(urldata + "admin/jobPost");

    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        setDesignation("");
        setLocation("");
        setDesc("");
        setSkills("");
        setMinexp("");
        setMaxexp("");
        setExpinyears("");
        setexpErr("");
        showToast("success");
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
      })
      .catch((error) => {
        // showToast("danger");
      });
  };

  const updateJobPost = () => {
    let data = {};
    data.designation = designation;
    data.location = location;
    data.desc = desc;
    data.skills = skills;
    if (exptabshow) {
      data.fromExp = minexp;
      data.toExp = maxexp;
      data.experienceInYear = "Years";
    } else {
      data.fromExp = 0;
      data.toExp = 0;
      data.experienceInYear = "Fresher";
    }
    data.id = jobId;

    let url = new URL(urldata + "admin/updateJobPost");

    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        setDesignation("");
        setLocation("");
        setDesc("");
        setSkills("");
        setMinexp("");
        setMaxexp("");
        setExpinyears("");
        setexpErr("");
        showToast("success");
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
      })
      .catch((error) => {
        // showToast("danger");
      });
  };

  const deleteBtnClick = (id) => {
    // alert(id);
    setLoading(true);
    let data = {};
    data.userId = id;

    let url = new URL(urldata + "admin/deleteJobPost");

    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");

        setLoading(false);
      });
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Job Post Saved Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Job post deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Job Opening" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Job Post List"
        columns={[
          {
            title: "Designation",
            field: "designation",
            cellStyle: {
              width: 20,
              maxWidth: 20,
            },
            headerStyle: {
              width: 20,
              maxWidth: 20,
            },
          },
          { title: "Location", field: "location" },
          { title: "Desc", field: "desc" },
          { title: "Skills", field: "skills" },
          { title: "Minimum Experience", field: "fromExp" },
          { title: "Maximum Experience", field: "toExp" },
          { title: "Experience In Year", field: "experienceInYear" },
          {
            field: "url",
            width: 1000,
            title: "Action  ",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>

                <Button
                  title=" Delete"
                  onClick={() => {
                    deleteBtnClick(rowData._id);
                  }}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        //   size="lg"
        //   aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="addJobModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model">
          <div className="title">
            {updateModel ? "Update Job Post" : "Add a Job Opening"}
          </div>
          <Row>
            <Col lg={6}>
              <InputText
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                title="Designation*"
              />
              <div className="err">{designationerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                title="Location*"
              />
              <div className="err">{locationerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                title="Description*"
              />
              <div className="err">{descerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                title="Skills*"
              />
              <div className="err">{skillserr}</div>
            </Col>
            <Col lg={3}>
              <label className="user-input-wrp m-0 d-f a-c fileTxt rblabel mt-4">
                Experience type:
              </label>
            </Col>
            <Col lg={9}>
              <div className="user-input-wrp m-0 d-f a-c fileTxt rblabel mt-4 mb-4">
                <input
                  onClick={(e) => setExptabshow(false)}
                  type="radio"
                  id="Fresher"
                  checked={exptabshow ? false : true}
                  name="Experience"
                  value="Fresher"
                />
                <label htmlFor="Fresher" className="pl-3 pr-3">
                  Fresher
                </label>

                <input
                  onClick={(e) => {
                    setExptabshow(true);
                  }}
                  type="radio"
                  id="Experienced"
                  checked={exptabshow ? true : false}
                  name="Experience"
                  value="Experienced"
                />
                <label htmlFor="Experienced" className="pl-3 pr-3">
                  Experienced
                </label>
              </div>
              <div className="err">{experr} </div>
            </Col>
            {exptabshow ? (
              <>
                <Col lg={6}>
                  <InputText
                    value={minexp}
                    type="number"
                    onChange={(e) => setMinexp(e.target.value)}
                    title="Minimum experience*"
                  />
                  <div className="err">{minexperr} </div>
                </Col>
                <Col lg={6}>
                  <InputText
                    value={maxexp}
                    type="number"
                    onChange={(e) => setMaxexp(e.target.value)}
                    title="Maximum experience*"
                  />
                  <div className="err">{maxexperr} </div>
                </Col>
              </>
            ) : null}
          </Row>
          <div className="btnContainer  mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default JobPostlist;
