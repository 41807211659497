import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Button from "../../../../component/Button/Button";
import Imageurldata from "../../../../Imageurldata";
const CvUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getCvUpload");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        const list = response.data.data.reverse();
        let filteredData=[];
        let liveUrl = Imageurldata ;
        for (let index = 0; index < list.length; index++) {
          
          filteredData.push({...list[index],resumeLink:`${liveUrl}${list[index].resumeLink}`})
        }
       console.log({filteredData});

        setList(filteredData);

        setLoading(false);
      })
      .catch((error) => {
        // alert("Something Went Wrong");
      });
  }, []);
  return (
    <>
      <MaterialTable
        title="Drop Your CV"
        columns={[
          {
            title: "Date",
            field: "date",
          },
          {
            title: "Time",
            field: "time",
          },

          {
            field: "url",
            title: "Resume Link",
            export:false,

            render: (rowData) => (
              <Button
                title=" View Resume"
                onClick={() => {
                  // let testUrl = "http://13.233.110.14:4000/api" 
                  let liveUrl = urldata 
                  
                  window.open(
                   rowData.resumeLink,
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              ></Button>
            ),
          },
          {
            field: "resumeLink",
            title: "Resume Link",
            export:true,
           hidden:true
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};

export default CvUpload;
