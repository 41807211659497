import {
  careersBanner,
  careersMobBanner,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  mbgallery01,
  mbgallery02,
  mbgallery03,
  mbgallery04,
  mbgallery05,
  mbgallery06,
  mbgallery07,
  mbgallery08,
  mbgallery09,
  mbgallery10,
  mbgallery11,
  mbgallery12,
  vaccountability,
  value1,
  value2,
  value3,
  value4,
  value5,
  mbvalue1,
  mbvalue2,
  mbvalue3,
  mbvalue4,
  mbvalue5,
  vambition,
  vcommitment,
  vexcellence,
  vrespect,
  gallery12,
  gallery11,
  gallery10,
  gallery09,
} from "../images";

export const bannerData = [{ banImg: careersBanner, mobImg: careersMobBanner }];

export const valuesData = [
  {
    bgImg: value1,
    mbBgImg: mbvalue1,
    initial: "A",
    icon: vaccountability,
    title: "ACCOUNTABILITY",
    desc: "We take personal accountability for our actions and if we can not resolve an issue, we will find someone who can",
  },
  {
    bgImg: value2,
    mbBgImg: mbvalue2,
    initial: "C",
    icon: vcommitment,
    title: "COMMITMENT",
    desc: "We foster relationships across our business that make a difference with all internal & external stakeholders",
  },
  {
    bgImg: value3,
    mbBgImg: mbvalue3,
    initial: "A",
    icon: vambition,
    title: "AMBITION",
    desc: "We exhibit a strong will to win in the marketplace and in every aspect of our business",
  },
  {
    bgImg: value4,
    mbBgImg: mbvalue4,
    initial: "R",
    icon: vrespect,
    title: "RESPECT",
    desc: "We treat everyone in a respectful, polite and helpful manner",
  },
  {
    bgImg: value5,
    mbBgImg: mbvalue5,
    initial: "E",
    icon: vexcellence,
    title: "EXCELLENCE",
    desc: "We strive to provide the best experience across services, products, interactions",
  },
];

export const hosLifeData = [
  { mainImg: gallery09, mbMainImg: mbgallery09, thumb: gallery09 },
  { mainImg: gallery10, mbMainImg: mbgallery10, thumb: gallery10 },
  { mainImg: gallery11, mbMainImg: mbgallery11, thumb: gallery11 },
  { mainImg: gallery12, mbMainImg: mbgallery12, thumb: gallery12 },
  { mainImg: gallery01, mbMainImg: mbgallery01, thumb: gallery01 },
  // { mainImg: gallery02, mbMainImg: mbgallery02, thumb: gallery02 },
  // { mainImg: gallery03, mbMainImg: mbgallery03, thumb: gallery03 },
  { mainImg: gallery04, mbMainImg: mbgallery04, thumb: gallery04 },
  // { mainImg: gallery05, mbMainImg: mbgallery05, thumb: gallery05 },
  { mainImg: gallery06, mbMainImg: mbgallery06, thumb: gallery06 },
  { mainImg: gallery07, mbMainImg: mbgallery07, thumb: gallery07 },
  { mainImg: gallery08, mbMainImg: mbgallery08, thumb: gallery08 },
];

export const teamData = [
  {
    id: 1,
    tagline: "Journey with HOS",
    msg: "Throughout my time at HOS from warehouse supervisor to Operations Manager, I have had the privilege of working on various projects and initiatives that have allowed me to showcase my skills and contribute to the company's objectives. I have been fortunate to work alongside talented individuals who have supported and mentored me, enabling me to expand my knowledge and enhance my skill set. Moreover, HOS's emphasis on innovation and continuous improvement has been truly inspiring. The company's dedication to staying ahead of industry trends and embracing new technologies has provided me with exciting challenges and opportunities to learn and grow.",
    name: "DP",
    desg: "Operations Manager – Operation Department",
  },
  {
    id: 2,
    tagline: "Journey with HOS",
    msg: "I came across an advertisement on social media and initially dismissed it due to my lack of knowledge about the company and its brand value. However, during a casual conversation with my wife, I mentioned the advertisement, and she encouraged me to apply for the position as she was familiar with the company and its reputation in the business. Following the interview process, I was fortunate enough to be selected. Throughout my journey so far, I have experienced both successes and challenges. I have had the opportunity to forge new friendships that I believe will endure for a long time. As this is my first corporate job, it was initially challenging for me to adapt. However, with the support and guidance of my seniors, I was able to overcome those initial difficulties and blend into the corporate environment.",
    name: "DD",
    desg: "FSQA Specialist - Quality Assurance Department",
  },
  {
    id: 3,
    tagline: "Journey with HOS",
    msg: "My journey with HOS has been remarkable, I am from a completely different profession but I have learned so many new things at HOS and developed new skills to provide excellent service to our valuable customers. I enjoy the work environment and bonding with fellow colleagues which is rarely seen in other firms. If we say HOS family, we really mean it.",
    name: "SG",
    desg: "Senior Associate - Sales Support - Operations",
  },
  {
    id: 4,
    tagline: "Average day at HOS",
    msg: "My average day in HOS is a mixture, we have a work and fun environment. I enjoy working along my fellow colleagues. I also get to learn and implement new skills.",
    name: "RS",
    desg: "Senior Associate Sales Support – Operations Department",
  },
  {
    id: 5,
    tagline: "Average day at HOS",
    msg: "Not boring at all :-) its never the same and that's what keeps it exciting!",
    name: "SP",
    desg: "VP Marketing – Marketing Department",
  },
  {
    id: 6,
    tagline: "Work Culture",
    msg: "I really like the work culture at HOS. Its positive and enthusiastic as well as flexible. Everyone can put forth their thoughts and ideas in front of the management team.",
    name: "SAB",
    desg: "Sr. Purchase Executive – Supply Chain",
  },
  {
    id: 7,
    tagline: "Work Culture",
    msg: "A few terms that come to my mind when thinking about the work culture at HoS are challenging, motivating, nurturing, and autonomous. Employees are trusted to have ownership over their work, feel invested, and we are encouraged to explore the full potential of our skill sets. The company is inspiring, and employees will feel compelled to work hard so they can grow with this growing company.",
    name: "JP",
    desg: "Senior FP&A – Finance Department",
  },
  {
    id: 8,
    tagline: "I love about my Job",
    msg: "I love working with my team. We are truly an exceptional and unique bunch.",
    name: "RA",
    desg: "Senior Vice President Human Resources – Human resources",
  },
  {
    id: 9,
    tagline: "I love about my Job",
    msg: "I take a small part in the company's growth which motivates me to continue till I’m capable.",
    name: "SJ",
    desg: "Regional Sales Manager – Sales",
  },
  {
    id: 10,
    tagline: "I love about my Job",
    msg: "I feel incredibly fortunate to be part of a company like HOS where I can genuinely say that I love my job. The stimulating work, supportive environment, continuous learning opportunities, impactful contributions, and emphasis on work-life balance all contribute to my overall job satisfaction. I am grateful for the experiences I have had so far.",
    name: "DP",
    desg: "",
  },
  {
    id: 11,
    tagline: "Things I enjoyed or benefitted the most",
    msg: "At HOS I benefit from an understanding and supportive boss as well as helpful collogues. I enjoy HOS’s happy work atmosphere.",
    name: "SM",
    desg: "Accounts Supervisor – Finance",
  },
  {
    id: 12,
    tagline: "Things I enjoyed or benefitted the most",
    msg: "I enjoy working in an environment where the members of the team have a strong sense and a good work ethic.",
    name: "MP",
    desg: "Territory Sales Manager – Sales",
  },
  {
    id: 13,
    tagline: "Things I enjoyed or benefitted the most",
    msg: "I came from a background in childcare and applied to HOS Global Foods thinking it was a restaurant looking for a receptionist. I had no idea that over 3.5 years my journey would take me from the front desk to Sales, Marketing and now HR. HOS has helped me grow as I have played different functions and roles within the company. I love a challenge and being in different departments has allowed me to keep trying and learn new things.",
    name: "KV",
    desg: " HR Associate - Human Resources – Khushboo Vaidya, HR Department",
  },
];
