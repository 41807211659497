import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const FooterContent = () => {
  const [loading, setLoading] = useState(false);

  const [desc, setDesc] = useState("");
  const [descErr, setDescErr] = useState("");

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [titleOne, setTitleOne] = useState("");
  const [titleOneErr, setTitleOneErr] = useState("");

  const [titleTwo, setTitleTwo] = useState("");
  const [titleTwoErr, setTitleTwoErr] = useState("");

  const [titleThree, setTitleThree] = useState("");
  const [titleThreeErr, setTitleThreeErr] = useState("");

  const [titleFour, setTitleFour] = useState("");
  const [titleFourErr, setTitleFourErr] = useState("");


  const [copyrightText, setcopyrightText] = useState("");
  const [copyrightTextErr, setcopyrightTextErr] = useState("");

  const [visitUsLink, setVisitUsLink] = useState("");
  const [visitUsLinkErr, setVisitUsLinkErr] = useState("");

  const [icononelink, seticononelink] = useState("");
  const [icononelinkerr, seticononelinkerr] = useState("");

  const [icontwolink, seticontwolink] = useState("");
  const [icontwolinkerr, seticontwolinkerr] = useState("");

  const [iconthreelink, seticonthreelink] = useState("");
  const [iconthreelinkerr, seticonthreelinkerr] = useState("");

  const [iconfourlink, seticonfourlink] = useState("");
  const [iconfourlinkerr, seticonfourlinkerr] = useState("");

  const [mbBannerImg, setMbBannerImg] = useState("");
  const [mbBannerImgErr, setMbBannerImgErr] = useState("");
  const [showMbImg, setShowMbImg] = useState("");
  const [checkMobileImgPresent, setCheckMobileImgPresent] = useState("");

  const [deskBannerImg, setDeskBannerImg] = useState("");
  const [deskBannerImgErr, setDeskBannerImgErr] = useState("");
  const [showDesktopImg, setShowDesktopImg] = useState("");
  const [checkDesktopImgPresent, setCheckDesktopImgPresent] = useState("");

  const [iconOne, setIconOne] = useState("");
  const [iconOneErr, setIconOneErr] = useState("");
  const [showiconOneImg, setshowIconOneImg] = useState("");
  const [checkIconOneImgPresent, setCheckIconOneImgPresent] = useState("");

  const [iconTwo, setIconTwo] = useState("");
  const [iconTwoErr, setIconTwoErr] = useState("");
  const [showiconTwoImg, setshowIconTwoImg] = useState("");
  const [checkIconTwoImgPresent, setCheckIconTwoImgPresent] = useState("");

  const [iconThree, setIconThree] = useState("");
  const [iconThreeErr, setIconThreeErr] = useState("");
  const [showiconThreeImg, setshowIconThreeImg] = useState("");
  const [checkIconThreeImgPresent, setCheckIconThreeImgPresent] = useState("");

  const [countOne, setCountOne] = useState("");
  const [countOneErr, setCountOneErr] = useState("");

  const [countOneSpan, setCountOneSpan] = useState("");
  const [countOneSpanErr, setCountOneSpanErr] = useState("");

  const [countTwo, setCountTwo] = useState("");
  const [countTwoErr, setCountTwoErr] = useState("");

  const [countTwoSpan, setCountTwoSpan] = useState("");
  const [countTwoSpanErr, setCountTwoSpanErr] = useState("");

  const [countThree, setCountThree] = useState("");
  const [countThreeErr, setCountThreeErr] = useState("");

  const [countThreeSpan, setCountThreeSpan] = useState("");
  const [countThreeSpanErr, setCountThreeSpanErr] = useState("");

  const [plusOne, setPlusOne] = useState(false);

  const [plusTwo, setPlusTwo] = useState(false);

  const [plusThree, setPlusThree] = useState(false);

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Footer Data Saved Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Data deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    setShowMbImg(false);
    // setShowDesktopImg(false);
    // setshowIconOneImg(false);
    // setshowIconTwoImg(false);
    // setshowIconThreeImg(false);

    let url = new URL(urldata + "admin/getfooter");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        let data = response.data.data[0];

        setMbBannerImg(data.amazonimg ? Imageurldata + data.amazonimg : "");
        setCheckMobileImgPresent(data.amazonimg ? false : true);
        
        setTitle(data.contacttitle ? data.contacttitle : "");
        setDesc(data.address ? data.address : "");
        
        setTitleOne(data.customertitle ? data.customertitle : "");
        setTitleTwo(data.visittitle ? data.visittitle : "");
        setTitleThree(data.onlinetitle ? data.onlinetitle : "");
        setTitleFour(data.followtitle ? data.followtitle : "");
        setcopyrightText(data.copyrightText ? data.copyrightText : "");
        setCountOne(data.contact ? data.contact : "");  
        setCountThree(data.email ? data.email : "");
        setVisitUsLink(data.amazonimglink ? data.amazonimglink : "")


        // setDeskBannerImg(data.fbimg ? Imageurldata + data.fbimg : "");
        // setCheckDesktopImgPresent(data.fbimg ? false : true);
        // setIconOne(data.instaimg ? Imageurldata + data.instaimg : "");
        // setIconTwo(data.linkedinimg ? Imageurldata + data.linkedinimg : "");
        // setIconThree(data.youtubeimg ? Imageurldata + data.youtubeimg : "");
        // setCheckIconOneImgPresent(data.instaimg ? false : true);
        // setCheckIconTwoImgPresent(data.linkedinimg ? false : true);
        // setCheckIconThreeImgPresent(data.youtubeimg ? false : true);

        // setPlusOne(data.plusSpan1 ? data.plusSpan1: false);
        // setPlusTwo(data.plusSpan2 ? data.plusSpan2: false);
        // setPlusThree(data.plusSpan3 ? data.plusSpan3: false);


        // setCountOneSpan(data.plusSpan1 ? data.plusSpan1 : "");

        // setCountTwo(data.count2 ? data.count2 : "");
        // setCountTwoSpan(data.plusSpan2 ? data.plusSpan2 : "");

        // setCountThreeSpan(data.plusSpan3 ? data.plusSpan3 : "");

        // seticononelink(data.fbimglink ? data.fbimglink : "")
        // seticontwolink(data.instaimglink ? data.instaimglink : "")
        // seticonthreelink(data.linkedinimglink ? data.linkedinimglink : "")
        // seticonfourlink(data.youtubeimglink ? data.youtubeimglink : "")

        setLoading(false);
        // showToast('success')
      })
      .catch((error) => {
        console.log("error:--", error);
        setLoading(false);
        showToast("danger");
      });
  }, []);

  const addJourney = () => {
    let formData = new FormData();

    if (checkMobileImgPresent) {
      formData.append("amazonimg", mbBannerImg);
      setCheckMobileImgPresent(false);
    }
    formData.append("fbimg", 'http://13.233.110.14:4000/api/public/images/social/SI_zpEbF0znFA.png');
    formData.append("instaimg", 'http://13.233.110.14:4000/api/public/images/social/SI_GGXjVG86ME.png');
    formData.append("linkedinimg", 'http://13.233.110.14:4000/api/public/images/social/SI_Ek1aEHFZUW.png');
    formData.append("youtubeimg", 'http://13.233.110.14:4000/api/public/images/social/SI_emjEVj5e5A.png');
    // if (checkDesktopImgPresent) {
    //   setCheckDesktopImgPresent(false);
    // }
    // if (checkIconOneImgPresent) {
    //   setCheckIconOneImgPresent(false);
    // }
    // if (checkIconTwoImgPresent) {
    //   setCheckIconTwoImgPresent(false);
    // }
    // if (checkIconThreeImgPresent) {
    //   setCheckIconThreeImgPresent(false);
    // }

    formData.append("contacttitle", title);
    formData.append("address", desc);

    formData.append("customertitle", titleOne);
    formData.append("visittitle", titleTwo);
    formData.append("onlinetitle", titleThree);
    formData.append("followtitle", titleFour);
    formData.append("copyrightText", copyrightText);

    formData.append("amazonimglink", visitUsLink);

    formData.append("fbimglink", 'footer');
    formData.append("instaimglink", 'footer');
    formData.append("linkedinimglink", 'footer');
    formData.append("youtubeimglink", 'footer');

    formData.append("contact", countOne);
    formData.append("email", countThree);

    let url = new URL(urldata + "admin/addfooter");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        showToast("success");
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        // showToast('danger')
        setLoading(false);
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title) {
      formValid = false;
      setTitleErr("*Please add contact us title");
    } else {
      setTitleErr("");
    }

    if (!desc) {
      formValid = false;

      setDescErr("*Please add address");
    } else {
      setDescErr("");
    }
    if (!titleFour) {
      formValid = false;

      setTitleFourErr("*Please add follow us title");
    } else {
      setTitleFourErr("");
    }
    if (!copyrightText) {
      formValid = false;

      setcopyrightTextErr("*Please add copyright text");
    } else {
      setcopyrightTextErr("");
    }

    if (!titleOne) {
      formValid = false;
      setTitleOneErr("*Please add customer service title");
    } else {
      setTitleOneErr("");
    }
    if (!countOne) {
      formValid = false;
      setCountOneErr("*Please add number");
    } else {
      setCountOneErr("");
    }
    if (!titleTwo) {
      formValid = false;
      setTitleTwoErr("*Please add visit us title");
    } else {
      setTitleTwoErr("");
    }

    if (!titleThree) {
      formValid = false;
      setTitleThreeErr("*Please add order us title");
    } else {
      setTitleThreeErr("");
    }
    if (!countThree) {
      formValid = false;
      setCountThreeErr("*Please add mail");
    } else {
      setCountThreeErr("");
    }

    if (!mbBannerImg) {
      formValid = false;
      setMbBannerImgErr("*Please add visit us image");
    } else {
      setMbBannerImgErr("");
    }
    if (!visitUsLink) {
      formValid = false;
      setVisitUsLinkErr("*please add visit us link");
    } else {
      setVisitUsLinkErr("");
    }

    // if (!deskBannerImg) {
    //   formValid = false;
    //   setDeskBannerImgErr("*Please add icon one");
    // } else {
    //   setDeskBannerImgErr("");
    // }
    // if (deskBannerImg) {
    //   if (deskBannerImg.size / 1024 > 300) {
    //     formValid = false;

    //     setDeskBannerImgErr("*File size should be less than 300Kb");
    //   }
    // }

    // if (!iconOne) {
    //   formValid = false;
    //   setIconOneErr("*Please add icon two");
    // } else {
    //   setIconOneErr("");
    // }

    // if (!iconTwo) {
    //   formValid = false;
    //   setIconTwoErr("*Please add icon three");
    // } else {
    //   setIconTwoErr("");
    // }

    // if (!iconThree) {
    //   formValid = false;
    //   setIconThreeErr("*Please add icon four");
    // } else {
    //   setIconThreeErr("");
    // }

    // if (!icononelink) {
    //   formValid = false;
    //   seticononelinkerr("*please add icon one link");
    // } else {
    //   seticononelinkerr("");
    // }
    // if (!icontwolink) {
    //   formValid = false;
    //   seticontwolinkerr("*please add icon two link");
    // } else {
    //   seticontwolinkerr("");
    // }
    // if (!iconthreelink) {
    //   formValid = false;
    //   seticonthreelinkerr("*please add icon three link");
    // } else {
    //   seticonthreelinkerr("");
    // }

    // if (!iconfourlink) {
    //   formValid = false;
    //   seticonfourlinkerr("*please add icon four link");
    // } else {
    //   seticonfourlinkerr("");
    // }

    if (formValid) {
      addJourney();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Footer Section</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Contact Us Title:- </div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          />
          <div className="err">{titleErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Address:-</div>
        </Col>
        <Col className="column" lg={6}>
          <ReactQuill
            value={desc}
            modules={modules}
            onChange={(e) => {
              setDesc(e);
            }}
          />

          <div className="err">{descErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Customer Service Title:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleOne}
            onChange={(e) => setTitleOne(e.target.value)}
            type="text"
          />
          <div className="err">{titleOneErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Number:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            type="tel"
            value={countOne}
            onChange={(e) => setCountOne(e.target.value)}
          />
          <div className="err">{countOneErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Visit Us Title:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleTwo}
            onChange={(e) => setTitleTwo(e.target.value)}
            type="text"
          />
          <div className="err">{titleTwoErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>
        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Visit Us Image:-</div>
          <div className="desc">Size :- 227 × 45</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setMbBannerImg(e.target.files[0]);
              setCheckMobileImgPresent(true);
              setShowMbImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{mbBannerImgErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {mbBannerImg !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={showMbImg ? URL.createObjectURL(mbBannerImg) : mbBannerImg}
              className="img-fluid"
            />
          )}
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Visit Us Link:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={visitUsLink}
            onChange={(e) => setVisitUsLink(e.target.value)}
            type="text"
          />
          <div className="err">{visitUsLinkErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ************************* */}
        <Col className="column" lg={3}>
          <div className="title">Online Order Title:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleThree}
            onChange={(e) => setTitleThree(e.target.value)}
            type="text"
          />
          <div className="err">{titleThreeErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>
        {/* ****************** */}
        <Col className="column" lg={3}>
          <div className="title">Online Order Mail</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            type="email"
            value={countThree}
            onChange={(e) => setCountThree(e.target.value)}
          />
          <div className="err">{countThreeErr} </div>
        </Col>
        <Col lg={3}></Col>
        {/* ********************* */}

        <Col className="column" lg={3}>
          <div className="title">Follow Us Title:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleFour}
            onChange={(e) => setTitleFour(e.target.value)}
            type="text"
          />
          <div className="err">{titleFourErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ****************** */}
        {/* ********************* */}

        <Col className="column" lg={3}>
          <div className="title">Copyright Text:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={copyrightText}
            onChange={(e) => setcopyrightText(e.target.value)}
            type="text"
          />
          <div className="err">{copyrightTextErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* ****************** */}
        {/* <Col className="column" lg={3}>
          <div className="title">Icon One Image:-</div>
          <div className="desc">Size :- 42 × 30</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage1"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setDeskBannerImg(e.target.files[0]);
              setCheckDesktopImgPresent(true);
              setShowDesktopImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage1" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{deskBannerImgErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {deskBannerImg !== "" && (
            <img
              style={{ height: "100px", width: "100px", backgroundColor:'gray'}}
              src={
                showDesktopImg
                  ? URL.createObjectURL(deskBannerImg)
                  : deskBannerImg
              }
              className="img-fluid"
            />
          )}
        </Col> */}
        {/* *************** */}
        {/* <Col className="column" lg={3}>
          <div className="title">Icon One Link:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={icononelink}
            onChange={(e) => seticononelink(e.target.value)}
            type="text"
          />
          <div className="err">{icononelinkerr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Two Image:-</div>
          <div className="desc">Size :- 42 × 30</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage3"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconOne(e.target.files[0]);
              setCheckIconOneImgPresent(true);
              setshowIconOneImg(true);
              //   setShowImageurl(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage3" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconOneErr} </div>
        </Col>

        <Col className="column" lg={3}>
          {iconOne !== "" && (
            <img
              style={{ height: "100px", width: "100px" , backgroundColor:'gray' }}
              src={showiconOneImg ? URL.createObjectURL(iconOne) : iconOne}
              className="img-fluid"
            />
          )}
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Two Link:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={icontwolink}
            onChange={(e) => seticontwolink(e.target.value)}
            type="text"
          />
          <div className="err">{icontwolinkerr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Three Image:-</div>
          <div className="desc">Size :- 42 × 30</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage4"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconTwo(e.target.files[0]);
              setCheckIconTwoImgPresent(true);
              setshowIconTwoImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage4" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconTwoErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {iconTwo !== "" && (
            <img
              style={{ height: "100px", width: "100px" , backgroundColor:'gray' }}
              src={showiconTwoImg ? URL.createObjectURL(iconTwo) : iconTwo}
              className="img-fluid"
            />
          )}
        </Col> */}
{/* 
        <Col className="column" lg={3}>
          <div className="title">Icon Three Link:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={iconthreelink}
            onChange={(e) => seticonthreelink(e.target.value)}
            type="text"
          />
          <div className="err">{iconthreelinkerr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Four Image:-</div>
          <div className="desc">Size :- 42 × 30</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage5"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconThree(e.target.files[0]);
              setCheckIconThreeImgPresent(true);
              setshowIconThreeImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage5" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid"  />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconThreeErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {iconThree !== "" && (
            <img
              style={{ height: "100px", width: "100px" , backgroundColor:'gray' }}
              src={
                showiconThreeImg ? URL.createObjectURL(iconThree) : iconThree
              }
              className="img-fluid"
            />
          )}
        </Col> */}

        {/* <Col className="column" lg={3}>
          <div className="title">Icon Four Link:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={iconfourlink}
            onChange={(e) => seticonfourlink(e.target.value)}
            type="text"
          />
          <div className="err">{iconfourlinkerr} </div>
        </Col>
        <Col className="column" lg={3}></Col> */}

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default FooterContent;
