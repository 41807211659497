import { useMutation, useQuery } from "react-query";
import { request } from "../../helpers/axios-utils";

const fetchCategories = () => {
  return request({ url: `products/brands`, method: "get" });
};
// const fetchProducts = (catgoryId, offsetVal) => {
//   return request({
//     url: `products/${catgoryId}?offset=${offsetVal}`,
//     method: "get",
//   });
// };

const fetchProducts = (categoryIdsArr, offsetVal) => {
  let requiredCategoryIdsArr = categoryIdsArr.map(
    (element) => element.subCatId
  );
  let data = {};
  data.categoryId = requiredCategoryIdsArr;
  return request({
    url: `products/byCategory?offset=${offsetVal}`,
    method: "POST",
    data: data,
  });
};

const fetchFeaturedProducts = (offsetVal) => {
  return request({ url: `products?offset=${offsetVal}`, method: "get" });
};
export const useGetCategories = () => {
  const { data: categoriesData, isLoading: isCategoriesLoading } = useQuery(
    "categories",
    () => fetchCategories()
  );

  return { categoriesData, isCategoriesLoading };
};
// export const useGetProducts = (catgoryId, offsetVal, onProductsSuccess) => {
//   const { isLoading: isProductsLoading, refetch: refetchProducts } = useQuery(
//     ["products", catgoryId, offsetVal],
//     () => fetchProducts(catgoryId, offsetVal),
//     {
//       enabled: false,
//       onSuccess: onProductsSuccess,
//     }
//   );
//   return { isProductsLoading, refetchProducts };
// };
export const useGetProductsByMultipleIds = (
  categoryIdsArr,
  offsetVal,
  onProductsSuccess
) => {
  return useMutation(() => fetchProducts(categoryIdsArr, offsetVal), {
    onSuccess(data) {
      onProductsSuccess(data);
    },
  });
};
export const useGetFeaturedProducts = (offsetVal, onProductsSuccess) => {
  const {
    isLoading: isFeaturedProductsLoading,
    refetch: refetchFeaturedProducts,
  } = useQuery(
    ["featured-products", offsetVal],
    () => fetchFeaturedProducts(offsetVal),
    {
      enabled: false,
      onSuccess: onProductsSuccess,
    }
  );
  return {
    isFeaturedProductsLoading,
    refetchFeaturedProducts,
  };
};
