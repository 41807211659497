import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

const OurPartnerBrands = (props) => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [desc, setdesc] = useState("");
  const [descErr, setdescErr] = useState("");

  const [link, setlink] = useState("");
  const [linkErr, setlinkErr] = useState("");

  const [productImg, setproductImg] = useState("");
  const [productImgErr, setproductImgErr] = useState("");
  const [productImgNotPresent, setProductImgNotPresent] = useState("");
  const [showProImg, setShowProImg] = useState("");

  const [partnerId, setPartnerId] = useState(false);

  const [show, setShow] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);

  const [logoImg, setLogoImg] = useState("");
  const [logoImgErr, setLogoImgErr] = useState("");
  const [logoImgNotPresent, setlogoImgNotPresent] = useState("");
  const [showLogoImg, setShowLogoImg] = useState("");

  const [showImg, setShowImg] = useState("");
  // const [imgNotPresent, setImgNotPresent] = useState("");

  const [bgimg, setbgimg] = useState("");
  const [bgimgErr, setbgimgErr] = useState("");
  const [bgImgNotPresent, setBgImgNotPresent] = useState("");
  const [showBgImg, setShowBgImg] = useState("");

  const [brandShow, setBrandShow] = useState(false);

  const [brandImg, setBrandImg] = useState("");
  const [brandImgErr, setBrandImgErr] = useState("");

  const [brandImgShow, setBrandImgShow] = useState("");
  const [brandQuery, setBrandQuery] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [addResponse, setAddResponse] = useState("");
  // console.log(addResponse);
  // const [showBgImg, setShowBgImg] = useState("");

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Brand ${
            updateModel ? "updated" : "added"
          } Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Brand deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);

    // setShowProImg(false);
    // setShowBgImg(false);
    // setShowLogoImg(false);

    setTitle("");
    setdesc("");
    setLogoImg("");
    setproductImg("");
    setbgimg("");

    setlink("");

    // setShowImg(false);
    setShow(true);

    setTitleErr("");
    setdescErr("");
    setproductImgErr("");
    setLogoImgErr("");
    setbgimgErr("");
  };

  // const handleBrandShow = () => {
  //   setBrandShow(true)
  // }
  const handleBrandClose = () => {
    setBrandShow(false);
  };

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getOurBrands");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        const tempPartnerList = response.data.data.reverse();
        setList(tempPartnerList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // showToast("danger");
      });
  }, []);

  const handleShowForUpdate = (rowData) => {
    setUpdateModel(true);
    setPartnerId(rowData._id);

    setTitle(rowData.title);
    setdesc(rowData.desc);

    setlink(rowData.link);

    // setShowProImg(false);
    // setShowBgImg(false);
    // setShowLogoImg(false);

    setLogoImg('');
    setproductImg('');
    setbgimg('');

    setShow(true);

    // setlogoImgNotPresent(rowData.logo ? false : true);
    // setBgImgNotPresent(rowData.bgimg ? false : true);
    // setProductImgNotPresent(rowData.productImg ? false : true);
  };

  //*************update partner
  const updatePartner = () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("desc", desc);
    formData.append("link", link);
    formData.append("logo", logoImg);
    formData.append("bgimg", bgimg);
    formData.append("productImg", productImg);

    // if (logoImgNotPresent) {
    //   setlogoImgNotPresent(false);
    // }

    // if (bgImgNotPresent) {
    //   setBgImgNotPresent(false);
    // }

    // if (productImgNotPresent) {
    //   setProductImgNotPresent(false);
    // }

    let url = new URL(urldata + "admin/updateOurBrands");

    url.searchParams.append("id", partnerId);
    var decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
        showToast("success");
        setLoading(false);
      })
      .catch((error) => {
        // showToast("danger");
        setLoading(false);
        setShow(false);
      });
  };

  //**************adding partner
  const createPartner = () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("desc", desc);

    formData.append("logo", logoImg);
    formData.append("bgimg", bgimg);
    formData.append("productImg", productImg);
    formData.append("link", link);

    let url = new URL(urldata + "admin/addOurBrands");

    setLoading(true);

    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        showToast("success");
        const tempPartnerList = response.data.data.reverse();
        setList(tempPartnerList);
        setShow(false);
        setLoading(false);
      })
      .catch((error) => {
        // showToast("danger");
        // console.log("error", error);
        setLoading(false);
      });
  };

  //submitting details
  const submit = () => {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
      setTitleErr("*Please add Partner name");
    } else {
      setTitleErr("");
    }
    if (!desc) {
      formIsValid = false;
      setdescErr("*Please add Partner Description");
    } else {
      setdescErr("");
    }

    if (!logoImg) {
      formIsValid = false;
      setLogoImgErr("*Please upload brand logo image.");
    } else {
      setLogoImgErr("");
    }

    if (!productImg) {
      formIsValid = false;
      setproductImgErr("*Please upload brand product image.");
    } else {
      setproductImgErr("");
    }
  

    if (!bgimg) {
      formIsValid = false;
      setbgimgErr("*Please upload brand background image.");
    } else {
      setbgimgErr("");
    }
    
    if (!link) {
      formIsValid = false;
      setlinkErr("*Please enter product link.");
    } else {
      setlinkErr("");
    }

    if (formIsValid) {
      if (updateModel) {
        updatePartner();
      } else {
        createPartner();
      }
    }
  };

  //deleting partner
  const deleteBtnClick = (id) => {
    let url = new URL(urldata + "admin/deleteOurBrands");

    url.searchParams.append("id", id);
    var decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");
        // console.error(error);
        setLoading(false);
      });
  };

  //brands update
  const handleBrands = (queryId) => {
    setBrandShow(true);
    setBrandImgShow(false);
    setBrandImg("");
    setBrandQuery(queryId);
  };

  // const addBrand = () => {
  //   let formData = new FormData();
  //   formData.append("logo", brandImg);

  //   let url = new URL(urldata + "admin/addOurBrandsProducts");

  //   url.searchParams.append("categoryId", brandQuery);
  //   var decoded = decodeURIComponent(url);

  //   setLoading(true);

  //   axios({
  //     method: "post",
  //     url: decoded,
  //     data: formData,
  //     withCredentials: true,
  //   })
  //     .then((response) => {
  //       // showToast("success");
  //       const tempResp = response.data.result
  //       // console.log(response);
  //       // console.log(tempBrandList);
  //       // setBrandList(tempBrandList);
  //       setAddResponse(tempResp)
  //       setBrandShow(false);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       // showToast("danger");
  //       // console.log("error", error);
  //       setLoading(false);
  //       setBrandShow(false);
  //     });
  // }

  // const submitBrand = () => {
  //   let formIsValid = true
  //   if(!brandImg){
  //     formIsValid = false;
  //     setBrandImgErr('*Please add brand image')
  //   }else{
  //     setBrandImgErr('')
  //   }

  //   if(formIsValid){
  //     addBrand()
  //   }

  // }

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Brand" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Our Brands"
        columns={[
          { title: "Title", field: "title" },
          { title: "Desc", field: "desc" },

          {
            field: "url",
            title: "Product image (homepage)",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.productImg, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },

          {
            field: "url",
            title: "Background image (homepage)",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.bgimg, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },

          {
            field: "url",
            title: "Logo (homepage)",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.logo, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "View Brands",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="Brands"
                  onClick={() => {
                    props.history.push(
                      "/admin/dashboard/ourproductbrands/" + rowData._id
                    );
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "Action  ",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model">
          <div className="title">
            {updateModel ? "Update Brands" : "Add Brands"}
          </div>

          <Row>
            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Add title:-</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
              />

              <div className="err">{titleErr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Add Description:-</div>
            </Col>

            <Col lg={9} className="column">
              <input
                value={desc}
                onChange={(e) => setdesc(e.target.value)}
                type="text"
              />

              <div className="err">{descErr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Link :-</div>
            </Col>

            <Col lg={9} className="column">
              <input
                value={link}
                onChange={(e) => setlink(e.target.value)}
                type="text"
              />

              <div className="err">{linkErr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Add Product Image</div>
              <div className="desc">Size :- 400 × 278</div>
            </Col>
            <Col lg={6} className="column">
              <input
                id="bannerImage2"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setproductImg(e.target.files[0]);
                  setShowProImg(true);
                  // setProductImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage2" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {productImg ? productImg.name : ""}
                </div>
                {/* <div className="fileTxt">File Size: Less than 100kb </div> */}
              </div>
              <div className="err">{productImgErr} </div>
            </Col>
            <Col className="column" lg={3}>
              {productImg !== "" && (
                <img
                  src={
                    showProImg ? URL.createObjectURL(productImg) : ''
                  }
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Add Background Image</div>
              <div className="desc">Size :- 2560 × 839</div>
            </Col>
            <Col lg={6} className="column">
              <input
                id="bannerImage3"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setbgimg(e.target.files[0]);
                  // setBgImgNotPresent(true);
                  setShowBgImg(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage3" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {bgimg ? bgimg.name : ""}
                </div>
                {/* <div className="fileTxt">File Size: Less than 100kb </div> */}
              </div>
              <div className="err">{bgimgErr} </div>
            </Col>
            <Col className="column" lg={3}>
              {bgimg !== "" && (
                <img
                  src={showBgImg ? URL.createObjectURL(bgimg) : ''}
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Add Logo</div>
              <div className="desc">Size :- 139 × 123</div>
            </Col>
            <Col lg={6} className="column">
              <input
                id="bannerImage1"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG"
                type="file"
                onChange={(e) => {
                  setLogoImg(e.target.files[0]);
                  // setlogoImgNotPresent(true);
                  setShowLogoImg(true);
                  // setShowImg(true);
                  // setImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage1" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {logoImg ? logoImg.name : ""}
                </div>
                {/* <div className="fileTxt">File Size: Less than 100kb </div> */}
              </div>
              <div className="err">{logoImgErr} </div>
            </Col>
            <Col className="column" lg={3}>
              {logoImg !== "" && (
                <img
                  src={showLogoImg ? URL.createObjectURL(logoImg) : ''}
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </Col>
          </Row>

          <div className="btnContainer mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
        </Modal.Body>
      </Modal>

      {/* 
      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={brandShow}
        onHide={handleBrandClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model">
          <div className="title">Brands</div>

          <Row>
            <Col lg={3} className="column">
              <div className="titleitem">Add Brand Image</div>
              <div className="desc">Size :- </div>
            </Col>
            <Col lg={6} className="column">
              <input
                id="bannerImage10"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG"
                type="file"
                onChange={(e) => {
                  setBrandImg(e.target.files[0]);
                  setBrandImgShow(true);
                  // setproductImg(e.target.files[0]);
                  // setShowProImg(true)
                  // setProductImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage10" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {brandImg ? brandImg.name : ""}
                </div>
                <div className="fileTxt">File Size: Less than 300kb </div>
              </div>
              <div className="err">{brandImgErr} </div>
            </Col>
            <Col className="column" lg={3}>
              {brandImg !== "" && (
                <img
                  src={brandImgShow ? URL.createObjectURL(brandImg) : ""}
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </Col>
          </Row>

          <div className="btnContainer mt-4">
            <Button onClick={submitBrand} title="SUBMIT" />
          </div>
        </Modal.Body>
      </Modal>

     
     
      */}
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default OurPartnerBrands;
