import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
  Checkbox,
  Divider,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi";
import _ from "lodash";

// import config from "../../config/config";
// import HtmlEditor from "../../components/HtmlEditor";
import http from "../../../../helpers/http";
import slugify from "react-slugify";
import urldata from "../../../../urldata";
import helper from "../../../../helpers/helper";

function Edit({ isVisible, handleClose, dbData, moduleName, brands }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const base_url = urldata + "admin/roles"; //without trailing slash
  const image_url = urldata + "public/uploads/role/"; //with trailing slash
  const sizeLimit = 1;

  const { TextArea } = Input;
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;

  const permissions = [
    {
      label: "Homepage",
      value: "homepage",
    },
    {
      label: "About Us",
      value: "about_us",
    },
    {
      label: "Our Brands",
      value: "our_brands",
    },
    {
      label: "Media",
      value: "media",
    },
    {
      label: "Collaborate",
      value: "collaborate",
    },
    {
      label: "Career",
      value: "career",
    },
    {
      label: "Contact Us",
      value: "contact_us",
    },
    {
      label: "Footer",
      value: "footer",
    },
    {
      label: "Privacy Policy",
      value: "privacy_policy",
    },
    {
      label: "PDF Content",
      value: "pdf_content",
    },
    {
      label: "Our Catalogue",
      value: "our_catalogue",
    },
  ];
  const schema = Joi.object({
    name: Joi.string().required().label("Name"),
    permissions: Joi.array().min(1).required().label("Permissions")
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = schema.validate(value, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "ProductCategory");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    console.log(value);

    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, []));

    // let filteredCategory2 = allCategory.filter(
    //   (data) => data.brand == dbData.brand
    // );
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
        <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Permissions:"
              name="permissions"
              {...(formError.permissions && {
                help: formError.permissions,
                validateStatus: "error",
              })}
            >
              <CheckboxGroup
                style={{
                  width: "100%",
                }}
              >
                
                <Row gutter={[16,16]}>
                {
                  permissions.map(({label,value})=>{
                    return  <Col span={8}>
                    <Checkbox value={value}>{label}</Checkbox>
                  </Col>
                  })
                }
                  {/* <Col span={8}>
                    <Checkbox value="A">A</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="B">B</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="C">C</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="D">D</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="E">E</Checkbox>
                  </Col> */}
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
