import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const expinyearsOptions = [
  {
    name: "",
  },
  {
    name: "Months",
  },
  {
    name: "Years",
  },
];


const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
  ],
};

const HomepageAboutSec1 = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [desc, setdesc] = useState("");
  const [descerr, setdescerr] = useState("");
  const [title, settitle] = useState("");
  const [titleerr, settitleErr] = useState("");

  const [link, setlink] = useState("");
  const [linkerr, setlinkErr] = useState("");

  const [bannerimg, setbannerimg] = useState("");
  const [bannerimgerr, setbannerimgErr] = useState("");
  const [showimageurl, setShowImageurl] = useState(false);

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  
  
  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Data added Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };


  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getHomeAboutData");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data[0];
        setdesc(templist.desc);
        settitle(templist.title);
        setlink(templist.link);
        setbannerimg(Imageurldata + templist.imgUrl);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // showToast("danger");
      });
  }, []);

  const submit = () => {
    var formData = new FormData();
    formData.append("title", title);
    formData.append("link", link);
    formData.append("desc", desc);
    
    if (showimageurl) {
      formData.append("banner", bannerimg);
    }

    let url = new URL(urldata + "admin/homeaboutsec2");

    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        //setShow(false);
        showToast("success");

      })
      .catch((error) => {
        showToast("danger");
      });
  };

  const checkSubmit = () => {
    let formIsValid = true

    if(!title){
      formIsValid = false;
      settitleErr('*Please add title')
    }else{
      settitleErr('')
    }
    console.log({desc});
    if(!desc){
      formIsValid = false
      setdescerr('Please add description')
    }else{
      setdescerr('')
    }
    if(!link){
      formIsValid = false
      setlinkErr('Please add button link')
    }else{
      setlinkErr('')
    }
    if(!bannerimg){
      formIsValid = false
      setbannerimgErr('Please add Image')
    }else{
      setbannerimgErr('')
    }
    // if(bannerimg){
    //   if(bannerimg.size / 1024 > 300){
    //     formIsValid = false
    //     setbannerimgErr('Image should be less than 300kb')
    //   }
    // }

    if(formIsValid){
      submit()
    }
  } 

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Homepage About us section</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        <Col className="column" lg={3}>
          <div className="title">Title*:- </div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title}
            onChange={(e) => settitle(e.target.value)}
            type="text"
          />
          <div className="err">{titleerr} </div>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={desc}
            modules={modules}
            onChange={(e) => {
              setdesc(e);
            }}
          />
          <div className="err">{descerr} </div>

        </Col>
        <Col className="column" lg={3}>
          <div className="title">Button Link*:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={link}
            onChange={(e) => setlink(e.target.value)}
            type="text"
          />
          <div className="err">{linkerr} </div>

        </Col>
        <Col className="column" lg={3}>
          <div className="title">Right Side Image:-</div>
          <div className="desc">Size :- 871 × 503</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG"        
            type="file"
            onChange={(e) => {
              setbannerimg(e.target.files[0]);
              setShowImageurl(true);
            }}
            name="passport_proof_upload"
          />

          <label for="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{bannerimgerr} </div>
        </Col>
        <Col className="column" lg={3}>
          <img
            src={showimageurl ? URL.createObjectURL(bannerimg) : bannerimg}
            className="img-fluid"
          />
        </Col>
        <Col className="column d-f j-c a-c">
          <Button onClick={checkSubmit} title="SUBMIT" />
        </Col>
        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default HomepageAboutSec1;
