import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
} from "antd";
import Joi from "joi";
// import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import slugify from "react-slugify";

import http from "../../../../helpers/http";
// import config from "../../config/config";
// import HtmlEditor from "../../components/HtmlEditor";
import axios from "axios";
import _ from "lodash";
import urldata from "../../../../urldata";
import helper from "../../../../helpers/helper";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  brands,
  allCategory,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [content, setContent] = useState();
  const [categories, setCategories] = useState();

  const base_url = urldata + "admin/products"; //without trailing slash
  const image_url = urldata + "uploads/product/"; //with trailing slash
  const sizeLimit = 1;

  const { TextArea } = Input;
  const { Option } = Select;

  //validation schema

  const schema = Joi.object({
    name: Joi.string().required(),
    slug: Joi.string().required(),
    // item_code: Joi.string().required().label("item code"),
    // mcc: Joi.string().required(),
    image: Joi.required(),
    // barcode: Joi.required(),
    category: Joi.string().required(),
    brand: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // validating unique slug
    if (data.slug) {
      const result = await helper.validateSlug(data.slug, "Product");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    if (data.image) {
      if (data.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = ["image/png"];
      if (!allowedExt.includes(data.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    if (data.barcode) {
      if (data.barcode.file.size > sizeLimit * 1024 * 1024) {
        errors["barcode"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.barcode.file.type)) {
        errors["barcode"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "barcode"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.barcode) {
      formData.append("barcode", value.barcode.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      // form.resetFields();

      handleClose();
    }
  };
  const handleBrandChange = (brand) => {
    console.log(brand);
    console.log(allCategory);
    let filteredCategory = allCategory.filter((category) => {
      console.log(category);
      return category.brand?._id == brand;
    });

    setCategories(() => {
      return filteredCategory;
    });
  };
  useEffect(() => {
    // setCategories(allCategory);
  }, []);

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              required
              label="Brand:"
              name="brand"
              {...(formError.brand && {
                help: formError.brand,
                validateStatus: "error",
              })}
            >
              <Select onChange={handleBrandChange}>
                {brands &&
                  brands.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Category:"
              name="category"
              {...(formError.category && {
                help: formError.category,
                validateStatus: "error",
              })}
            >
              <Select>
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Item Code:"
              name="item_code"
              {...(formError.item_code && {
                help: formError.item_code,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="MCC:"
              name="mcc"
              {...(formError.mcc && {
                help: formError.mcc,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {/* <Col span={24}>
            <Form.Item
              required
              label="Content:"
              name="content"
              {...(formError.content && {
                help: formError.content,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="create-editor"
                textareaName="content"
                height={350}
                onEditorChange={(newText) => setContent(newText)}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              required
              label="Image: (160 X 200)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                accept=".png"
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Barcode: (200 X 72)"
              name="barcode"
              {...(formError.barcode && {
                help: formError.barcode,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                accept=".png,.jpg,.jpeg"
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
