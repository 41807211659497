import React, { useState, useEffect } from "react";

import "./App.less";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";

import DefaultLayout from "./DefaultLayout";
import "swiper/swiper.less";
import "react-quill/dist/quill.snow.css";
import Login from "../src/Admin/container/Login/Login";
import Dashboard from "../src/Admin/container/Dashboard/Dashboard";
import Content from "./container/content/Content";

import urldata from "./urldata";
import InternalServerError from "./container/InternalServerError/InternalServerError";
import Maintainence from "./container/Maintainence/Maintainence";
import Homepage from "./container/HomePage/HomePage";
// import Footer from "./component/Footer/Footer";
// import Header from "./component/Header/Header";
import About from "./container/About/About";

import OurBrands from "./container/OurBrands/OurBrands";
import Collaborate from "./container/Collaborate/Collaborate";

import Contactus from "./container/ContactUS/Contactus";
import Careers from "./container/Careers/Careers";
import PageNotFound from "./container/PageNotFound/PageNotFound";
// import InternalServerError from "./container/InternalServerError/InternalServerError";
import Media from "./container/Media/Media";
import PrivacyPolicy from "./container/PrivacyPolicy/PrivacyPolicy";
import ReactGA from "react-ga";
import CatalogueContextProvider from "./contexts/CatalogueContext";
import config from "./config/config";
import helper from "./helpers/helper";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
const history = createBrowserHistory();

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const isAuth = localStorage.getItem("isLoggedIn");
//   console.log(isAuth);
//   return (
//     // Show the component only when the user is logged in
//     // Otherwise, redirect the user to /signin page
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuth ? <Component {...props} /> : <Redirect to="/admin" />
//       }
//     />
//   );
// };

const PrivateRoute2 = ({ component: Component, ...rest }) => {
  const isAuth = localStorage.getItem("isLoggedIn");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/admin",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  let location = useLocation();
  console.log(location.pathname);
  let user;
  try {
    user = helper.getUser();
  } catch (error) {}

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} user={user} /> : <Redirect to="/admin" />
      }
    />
  );
};
const menus = [
  {
    category: "homepage",
    title: "banner",
    path: "admin/dashboard/hompeagebannerlist",
  },
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
const App = () => {
  const [pdfData, setPdfData] = useState("");

  useEffect(() => {
    let url = new URL(urldata + "admin/getpdf");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response);
        setPdfData(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1500,
  //     disable: function () {
  //       var maxWidth = 1279;
  //       return window.innerWidth < maxWidth;
  //     },
  //     // once: true,
  //   });
  // }, []);

  return (
    <>
    <SnackbarProvider anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }} />
    <QueryClientProvider client={queryClient}>
      <CatalogueContextProvider>
        <Router history={history}>
          <Switch>
            <Route exact path="/admin" component={Login} />
            {config.app_mode == "development" ? (
              <PrivateRoute path="/admin/dashboard/" component={Dashboard} />
            ) : (
              <PrivateRoute path="/admin/dashboard/" component={Dashboard} />
            )}

            <Route exact path="/content/salescatalog.pdf" component={Content} />
            <Route path="/" component={DefaultLayout} />
            {/* <Route exact path="/" component={Maintainence} /> */}
            {/* <Route exact path="/homepage" component={DefaultLayout} /> */}
          </Switch>
        </Router>
      </CatalogueContextProvider>
    </QueryClientProvider>
    </>
  );
};

export default App;
// "http://togglehead.net:8090/hos/"
// basename={"/hos"}
// ,"proxy": "."
// "proxy": "http://togglehead.net:8090/hos/"
// "homepage": ".",
// abc
