import { useRef, useState } from "react";
import "./accordion.less";
import { cdownarrow, cuparrow } from "../../images";

export const CustomAccordion = ({ items, firstActive = false }) => {
  const [openIndex, setOpenIndex] = useState(firstActive === true ? 0 : "");
  const activeAccordionRef = useRef(null);

  const handleItemClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {items.map((item, index) => (
        <div
          className={`accordion-wrapper ${openIndex === index ? "active" : ""}`}
          key={index}
          ref={openIndex === index ? activeAccordionRef : null}
        >
          <div
            className="accordion-title"
            onClick={() => handleItemClick(index)}
          >
            <span>{item.title}</span>
            <span className="plus-minus">
              {openIndex === index ? (
                <img className="arrow" src={cuparrow} alt="" loading="lazy" />
              ) : (
                <img className="arrow" src={cdownarrow} alt="" loading="lazy" />
              )}
            </span>
          </div>
          <div
            className={`accordion-item ${openIndex === index ? "active" : ""}`}
          >
            <div
              className="accordion-content"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        </div>
      ))}
    </>
  );
};
