import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const OurJourney = () => {
  const [loading, setLoading] = useState(false);

  const [desc, setDesc] = useState("");
  const [descErr, setDescErr] = useState("");

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [titleOne, setTitleOne] = useState("");
  const [titleOneErr, setTitleOneErr] = useState("");

  const [titleTwo, setTitleTwo] = useState("");
  const [titleTwoErr, setTitleTwoErr] = useState("");

  const [titleThree, setTitleThree] = useState("");
  const [titleThreeErr, setTitleThreeErr] = useState("");

  const [mbBannerImg, setMbBannerImg] = useState("");
  const [mbBannerImgErr, setMbBannerImgErr] = useState("");
  const [showMbImg, setShowMbImg] = useState("");
  const [checkMobileImgPresent, setCheckMobileImgPresent] = useState("");

  const [deskBannerImg, setDeskBannerImg] = useState("");
  const [deskBannerImgErr, setDeskBannerImgErr] = useState("");
  const [showDesktopImg, setShowDesktopImg] = useState("");
  const [checkDesktopImgPresent, setCheckDesktopImgPresent] = useState("");

  const [iconOne, setIconOne] = useState("");
  const [iconOneErr, setIconOneErr] = useState("");
  const [showiconOneImg, setshowIconOneImg] = useState("");
  const [checkIconOneImgPresent, setCheckIconOneImgPresent] = useState("");

  const [iconTwo, setIconTwo] = useState("");
  const [iconTwoErr, setIconTwoErr] = useState("");
  const [showiconTwoImg, setshowIconTwoImg] = useState("");
  const [checkIconTwoImgPresent, setCheckIconTwoImgPresent] = useState("");

  const [iconThree, setIconThree] = useState("");
  const [iconThreeErr, setIconThreeErr] = useState("");
  const [showiconThreeImg, setshowIconThreeImg] = useState("");
  const [checkIconThreeImgPresent, setCheckIconThreeImgPresent] = useState("");

  const [countOne, setCountOne] = useState("");
  const [countOneErr, setCountOneErr] = useState("");

  const [countOneSpan, setCountOneSpan] = useState("");
  const [countOneSpanErr, setCountOneSpanErr] = useState("");

  const [countTwo, setCountTwo] = useState("");
  const [countTwoErr, setCountTwoErr] = useState("");

  const [countTwoSpan, setCountTwoSpan] = useState("");
  const [countTwoSpanErr, setCountTwoSpanErr] = useState("");

  const [countThree, setCountThree] = useState("");
  const [countThreeErr, setCountThreeErr] = useState("");

  const [countThreeSpan, setCountThreeSpan] = useState("");
  const [countThreeSpanErr, setCountThreeSpanErr] = useState("");

  const [plusOne, setPlusOne] = useState(false);

  const [plusTwo, setPlusTwo] = useState(false);

  const [plusThree, setPlusThree] = useState(false);

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  
  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Data Saved Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Data deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    setShowMbImg(false);
    setShowDesktopImg(false);
    setshowIconOneImg(false);
    setshowIconTwoImg(false);
    setshowIconThreeImg(false);

    let url = new URL(urldata + "admin/getOurJourney");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        let data = response.data.data[0];
        // console.log(data);

        setMbBannerImg(data.mbparallaximgUrl ? Imageurldata + data.mbparallaximgUrl : "");
        setDeskBannerImg(data.parallaximgUrl ? Imageurldata + data.parallaximgUrl : "");
        setCheckMobileImgPresent(data.mbparallaximgUrl ? false : true);
        setCheckDesktopImgPresent(data.parallaximgUrl ? false : true);

        setIconOne(data.icon1 ? Imageurldata + data.icon1 : "");
        setIconTwo(data.icon2 ? Imageurldata + data.icon2 : "");
        setIconThree(data.icon3 ? Imageurldata + data.icon3 : "");
        setCheckIconOneImgPresent(data.icon1 ? false : true);
        setCheckIconTwoImgPresent(data.icon2 ? false : true);
        setCheckIconThreeImgPresent(data.icon3 ? false : true);

        setTitle(data.title ? data.title: '');
        setDesc(data.desc ? data.desc: '');

        // setPlusOne(data.plusSpan1 ? data.plusSpan1: false);
        // setPlusTwo(data.plusSpan2 ? data.plusSpan2: false);
        // setPlusThree(data.plusSpan3 ? data.plusSpan3: false);

        setTitleOne(data.title1 ? data.title1: '');
        setTitleTwo(data.title2 ? data.title2: '');
        setTitleThree(data.title3 ? data.title3: '');

        setCountOne(data.count1 ? data.count1: '');
        setCountOneSpan(data.plusSpan1 ? data.plusSpan1: '');

        setCountTwo(data.count2 ? data.count2: '');
        setCountTwoSpan(data.plusSpan2 ? data.plusSpan2: '');
        
        setCountThree(data.count3 ? data.count3: '');
        setCountThreeSpan(data.plusSpan3 ? data.plusSpan3 : "");

        setLoading(false);
        // showToast('success')
      })
      .catch((error) => {
        // console.log("error:--", error);
        setLoading(false);
        showToast("danger");
      });
  }, []);

  const addJourney = () => {
    let formData = new FormData();

    if (checkMobileImgPresent) {
      formData.append("bannerImageMobile", mbBannerImg);
      setCheckMobileImgPresent(false);
    }
    if (checkDesktopImgPresent) {
      formData.append("bannerImageWeb", deskBannerImg);
      setCheckDesktopImgPresent(false);
    }
    if (checkIconOneImgPresent) {
      formData.append("icon1", iconOne);
      setCheckIconOneImgPresent(false);
    }
    if (checkIconTwoImgPresent) {
      formData.append("icon2", iconTwo);
      setCheckIconTwoImgPresent(false);
    }
    if (checkIconThreeImgPresent) {
      formData.append("icon3", iconThree);
      setCheckIconThreeImgPresent(false);
    }

    formData.append("title", title);

    formData.append("title1", titleOne);
    formData.append("title2", titleTwo);
    formData.append("title3", titleThree);

    formData.append("desc", desc);

    formData.append("count1", countOne);
    formData.append("count2", countTwo);
    formData.append("count3", countThree);

    formData.append("plusSpan1", countOneSpan);
    formData.append("plusSpan2", countTwoSpan);
    formData.append("plusSpan3", countThreeSpan);

    let url = new URL(urldata + "admin/addOurJourney");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response);
        showToast('success')
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        // showToast('danger')
        setLoading(false);
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title) {
      formValid = false;
      setTitleErr("*Please add title");
    } else {
      setTitleErr("");
    }

    if (!desc) {
      formValid = false;

      setDescErr("*Please add Description");
    } else {
      setDescErr("");
    }

    if (!titleOne) {
      formValid = false;
      setTitleOneErr("*Please add title one");
    } else {
      setTitleOneErr("");
    }
    if (!titleTwo) {
      formValid = false;
      setTitleTwoErr("*Please add title two");
    } else {
      setTitleTwoErr("");
    }

    if (!titleThree) {
      formValid = false;
      setTitleThreeErr("*Please add title three");
    } else {
      setTitleThreeErr("");
    }

    if (!mbBannerImg) {
      formValid = false;
      setMbBannerImgErr("*Please add mobile banner image");
    } else {
      setMbBannerImgErr("");
    }
// <<<<<<< HEAD
    // if (mbBannerImg) {
    //   if (mbBannerImg.size / 1024 > 300) {
    //     formValid = false;

    //     setMbBannerImgErr("*File size should be less than 300Kb");
    //   }
    // }
// =======
  
// >>>>>>> vijay
    if (!deskBannerImg) {
      formValid = false;
      setDeskBannerImgErr("*Please add desktop banner image");
    } else {
      setDeskBannerImgErr("");
    }
    // if (deskBannerImg) {
    //   if (deskBannerImg.size / 1024 > 300) {
    //     formValid = false;

    //     setDeskBannerImgErr("*File size should be less than 300Kb");
    //   }
    // }
   
    if (!iconOne) {
      formValid = false;
      setIconOneErr("*Please add icon one");
    } else {
      setIconOneErr("");
    }
    
    if (!iconTwo) {
      formValid = false;
      setIconTwoErr("*Please add icon two");
    } else {
      setIconTwoErr("");
    }
   
    if (!iconThree) {
      formValid = false;
      setIconThreeErr("*Please add icon three");
    } else {
      setIconThreeErr("");
    }
  

    // if (!countOne) {
    //   formValid = false;
    //   setCountOneErr("*Please add count one");
    // } else {
    //   setCountOneErr("");
    // }

    // if (countOne < 0) {
    //   formValid = false;

    //   setCountOneErr("*count should not be less than 0");
    // }
    // if (!countTwo) {
    //   formValid = false;
    //   setCountTwoErr("*Please add count two");
    // } else {
    //   setCountTwoErr("");
    // }

    // if (countTwo < 0) {
    //   formValid = false;

    //   setCountTwoErr("*count should not be less than 0");
    // }
    // if (!countThree) {
    //   formValid = false;
    //   setCountThreeErr("*Please add count three");
    // } else {
    //   setCountThreeErr("");
    // }

    // if (countThree < 0) {
    //   formValid = false;

    //   setCountThreeErr("*count should not be less than 0");
    // }

    if (formValid) {
      addJourney();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Our Journey Section</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        <Col className="column" lg={3}>
          <div className="title">Title:- </div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          />
          <div className="err">{titleErr} </div>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={desc}
            modules={modules}
            onChange={(e) => {
              setDesc(e);
            }}
          />

          <div className="err">{descErr} </div>
        </Col>

        {/* banner image start */}
        <Col className="column" lg={3}>
          <div className="title">Mobile Parallax Image:-</div>
          <div className="desc">Size :- 640 × 1048</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setMbBannerImg(e.target.files[0]);
              setCheckMobileImgPresent(true);
              setShowMbImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{mbBannerImgErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {mbBannerImg !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={showMbImg ? URL.createObjectURL(mbBannerImg) : mbBannerImg}
              className="img-fluid"
            />
          )}
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Desktop Parallax Image:-</div>
          <div className="desc">Size :- 2560 × 859</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage1"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setDeskBannerImg(e.target.files[0]);
              setCheckDesktopImgPresent(true);
              setShowDesktopImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage1" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{deskBannerImgErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {deskBannerImg !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={
                showDesktopImg
                  ? URL.createObjectURL(deskBannerImg)
                  : deskBannerImg
              }
              className="img-fluid"
            />
          )}
        </Col>
        {/*************** field one */}
        <Col className="column" lg={3}>
          <div className="title">Field One:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleOne}
            onChange={(e) => setTitleOne(e.target.value)}
            type="text"
          />
          <div className="err">{titleOneErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        {/* icons start */}
        <Col className="column" lg={3}>
          <div className="title">Icon One:-</div>
          <div className="desc">Size :- 64 × 76</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage3"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconOne(e.target.files[0]);
              setCheckIconOneImgPresent(true);
              setshowIconOneImg(true);
              //   setShowImageurl(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage3" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconOneErr} </div>
        </Col>

        <Col className="column" lg={3}>
          {iconOne !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={showiconOneImg ? URL.createObjectURL(iconOne) : iconOne}
              className="img-fluid"
            />
          )}
        </Col>

        <Col
          className="column d-flex align-items-center jusitfy-content-start"
          lg={3}>
          <p>Count for field one</p>
          {/* <label className='display-5'></label>
          
          <input
            type="checkbox"
            checked={plusOne}
            onChange={(e) => setPlusOne((prevState) => !plusOne)}
          /> */}
        </Col>
        <Col
          className="column d-flex align-items-center jusitfy-content-md-center"
          lg={6}
        >
          <input
            type="number"
            value={countOne}
            onChange={(e) => setCountOne(e.target.value)}
          />
          <div className="err">{countOneErr} </div>
        </Col>
        <Col className="column" lg={3}>
          <input
            type="text"
            value={countOneSpan}
            onChange={(e) => setCountOneSpan(e.target.value)}
          />
          <div className="err">{countOneSpanErr} </div>
        </Col>

        {/* *************field two */}

        <Col className="column" lg={3}>
          <div className="title">Field Two:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleTwo}
            onChange={(e) => setTitleTwo(e.target.value)}
            type="text"
          />
          <div className="err">{titleTwoErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Two:-</div>
          <div className="desc">Size :- 76 × 76</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage4"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconTwo(e.target.files[0]);
              setCheckIconTwoImgPresent(true);
              setshowIconTwoImg(true);
              //   setShowImageurl(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage4" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconTwoErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {iconTwo !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={showiconTwoImg ? URL.createObjectURL(iconTwo) : iconTwo}
              className="img-fluid"
            />
          )}
        </Col>

        <Col
          className="column d-flex align-items-center jusitfy-content-start"
          lg={3}
        >
          <p>Count for field two</p>
          {/* <label>Count for field two</label>
          <input
            type="checkbox"
            checked={plusTwo}
            onChange={(e) => setPlusTwo(!plusTwo)} 
          />*/}
        </Col>
        <Col
          className="column d-flex align-items-center jusitfy-content-md-center"
          lg={6}
        >
          <input
            type="number"
            value={countTwo}
            onChange={(e) => setCountTwo(e.target.value)}
          />
          <div className="err">{countTwoErr} </div>
        </Col>
        <Col className="column" lg={3}>
          <input
            type="text"
            value={countTwoSpan}
            onChange={(e) => setCountTwoSpan(e.target.value)}
          />
          <div className="err">{countTwoSpanErr} </div>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Field Three:-</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            value={titleThree}
            onChange={(e) => setTitleThree(e.target.value)}
            type="text"
          />
          <div className="err">{titleThreeErr} </div>
        </Col>
        <Col className="column" lg={3}></Col>

        <Col className="column" lg={3}>
          <div className="title">Icon Three:-</div>
          <div className="desc">Size :- 78 × 76</div>
        </Col>
        <Col className="column" lg={6}>
          <input
            id="bannerImage5"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setIconThree(e.target.files[0]);
              setCheckIconThreeImgPresent(true);
              setshowIconThreeImg(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage5" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>
          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{iconThreeErr} </div>
        </Col>
        <Col className="column" lg={3}>
          {iconThree !== "" && (
            <img
              style={{ height: "100px", width: "100px" }}
              src={
                showiconThreeImg ? URL.createObjectURL(iconThree) : iconThree
              }
              className="img-fluid"
            />
          )}
        </Col>

        {/* count and plus span starts */}

        <Col
          className="column d-flex align-items-center jusitfy-content-start"
          lg={3}
        >
          <p>Count for field three</p>
          {/* <label>Count for field three</label>
          <input
            type="checkbox"
            checked={plusThree}
            onChange={(e) => setPlusThree(!plusThree)}
          /> */}
        </Col>
        <Col
          className="column d-flex align-items-center jusitfy-content-md-center"
          lg={6}
        >
          <input
            type="number"
            value={countThree}
            onChange={(e) => setCountThree(e.target.value)}
          />
          <div className="err">{countThreeErr} </div>
        </Col>
        <Col className="column" lg={3}>
          <input
            type="text"
            value={countThreeSpan}
            onChange={(e) => setCountThreeSpan(e.target.value)}
          />
          <div className="err">{countThreeSpanErr} </div>
        </Col>

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default OurJourney;
