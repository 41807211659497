import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AboutUsSecTwo = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [mobBannerImg, setMobBannerImg] = useState("");
  const [mobBannerImgErr, setMobBannerImgErr] = useState("");
  const [showMbImg, setShowMbImg] = useState("");
  const [checkMobileImgPresent, setCheckMobileImgPresent] = useState("");

  const [toastlist, setToastList] = useState([]);
  let toastProperties = null;

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  
  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Data added Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  
  
  useEffect(() => {
    setShowMbImg(false);
    let url = new URL(urldata + "admin/getaboutussec2");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        let data = response.data.data[0];
        setTitle(data.desc ? data.desc : "");
        setMobBannerImg(data.img ? Imageurldata + data.img : "");
        setLoading(false);
        setCheckMobileImgPresent(data.img ? false : true);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        //showToast("danger");
      });
  }, []);

  const addBannerData = () => {
    let formData = new FormData();

    formData.append("desc", title);

    if (checkMobileImgPresent) {
      formData.append("img", mobBannerImg);
      setCheckMobileImgPresent(false);
    }

    let url = new URL(urldata + "admin/addaboutussec2");
    // url.searchParams.append("screen", screenQuery);
    // let decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        showToast("success");
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        // showToast('danger')
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title) {
      formValid = false;
      setTitleErr("*Please add Description");
    } else {
      setTitleErr("");
    }
    if (!mobBannerImg) {
      formValid = false;
      setMobBannerImgErr("*Please add Image");
    } else {
      setMobBannerImgErr("");
    }
    // if(mobBannerImg){
    //   if(mobBannerImg.size / 1024 > 300){
    //     formValid = false;
    //     setMobBannerImgErr('*File Size should be less than 300kb')
    //   }
    // }
    if (formValid) {
      addBannerData();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>About Us Section Two</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        {/*sub row 1*/}
        <Col className="column" lg={3}>
          <div className="title">Description:- </div>
        </Col>
        <Col className="column" lg={9}>
          {/* <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          /> */}

          <ReactQuill
            value={title}
            modules={modules}
            onChange={(e) => {
              setTitle(e);
            }}
          />
          <h4 className="err">{titleErr}</h4>
        </Col>

        {/*sub row 2*/}
        <Col className="column" lg={3}>
          <div className="title">Left Side Image:-</div>
          <div className="desc">Size :- 674 × 483</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage1"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setMobBannerImg(e.target.files[0]);
              setShowMbImg(true);
              setCheckMobileImgPresent(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage1" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{mobBannerImgErr} </div>
          <Col className="column" lg={3}>
            {mobBannerImg !== "" && (
              <img
                src={
                  showMbImg ? URL.createObjectURL(mobBannerImg) : mobBannerImg
                }
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default AboutUsSecTwo;
