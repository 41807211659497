import React, { useState, useEffect } from "react";
import moment from "moment";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

const AboutUsSecFour = (props) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [updateModel, setUpdateModel] = useState(false);

  const [memberId, setmemberid] = useState("");

  const [show, setShow] = useState(false);

  const [name, setname] = useState("");
  const [nameerr, setnameerr] = useState("");

  const [desg, setdesg] = useState("");
  const [desgerr, setdesgerr] = useState("");

  const [desc, setdesc] = useState("");
  const [descerr, setdescerr] = useState("");

  const [bannerImg, setBannerImg] = useState("");
  const [bannerImgErr, setBannerImgErr] = useState("");

  const [imgNotPresent, setImgNotPresent] = useState("");

  const [showImg, setShowImg] = useState("");

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Member ${
            updateModel ? "updated" : "added"
          } Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Member deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    let url = new URL(urldata + "admin/getaboutussec4");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        //showToast("danger");
      });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);
    setShowImg(false);
    setBannerImg("");
    setname("");
    setdesg("");
    setdesc("");
    setShow(true);
  };

  const handleShowForUpdate = (rowData) => {
    setUpdateModel(true);
    setShow(true);
    setBannerImg(Imageurldata + rowData.img);
    setShowImg(false);
    setImgNotPresent(rowData.img ? false : true);
    setname(rowData.name);
    setdesg(rowData.designation);
    setdesc(rowData.desc);
    setmemberid(rowData._id)
  };

  const updateMember = () => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("designation", desg);
    formData.append("desc", desc);
    
    if(imgNotPresent){
    
    formData.append("img", bannerImg);
      setImgNotPresent(false)
    }

    let url = new URL(urldata + "admin/updateaboutussec4");

    url.searchParams.append("id", memberId);
    var decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
        setLoading(false);
        showToast("success");
        
      })
      .catch((error) => {
        // showToast("danger");
        setLoading(false);
      });
    
  };

  const addMember = () => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("designation", desg);
    formData.append("desc", desc);
    formData.append("img", bannerImg);

    let url = new URL(urldata + "admin/addaboutussec4");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const tempList = response.data.data.reverse();
        setList(tempList);
        setShow(false);
        setLoading(false);
        showToast("success");
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
        // showToast("danger");
      });
  };

  const submit = () => {
    let formIsValid = true;

    if (!name) {
      formIsValid = false;
      setnameerr("*Please add member name");
    } else {
      setnameerr("");
    }

    if (!desc) {
      formIsValid = false;
      setdescerr("*Please add member description");
    } else {
      setdescerr("");
    }

    if (!desg) {
      formIsValid = false;
      setdesgerr("*Please add designation");
    } else {
      setdesgerr("");
    }

    if (!bannerImg) {
      formIsValid = false;
      setBannerImgErr("*Please add team member image");
    } else {
      setBannerImgErr("");
    }

    if (formIsValid) {
      if (updateModel) {
        updateMember();
      } else {
        addMember();
      }
    }
  };

  const deleteBtnClick = (id) => {

    let url = new URL(urldata + "admin/deleteaboutussec4");
  
    url.searchParams.append('id', id);
    let decoded = decodeURIComponent(url)

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: decoded,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        setLoading(false);
      });

  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Team Member" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Team Members List"
        columns={[
          { title: "Name", field: "name" },
          { title: "Designation", field: "designation" },
          { title: "Description", field: "desc" },
          {
            field: "img",
            title: "Team Member Image",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.img, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "Action",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model" style={{ position: "relative" }}>
          <div className="title">
            {updateModel ? "Update Team Member" : "Add Team Member"}
          </div>

          <Row>
            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Name</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={name}
                onChange={(e) => setname(e.target.value)}
                type="text"
              />
              <div className="err">{nameerr}</div>
            </Col>

            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Designation</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={desg}
                onChange={(e) => setdesg(e.target.value)}
                type="text"
              />
              <div className="err">{desgerr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Description</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={desc}
                onChange={(e) => setdesc(e.target.value)}
                type="text"
              />
              <div className="err">{descerr}</div>
            </Col>

            {/* col-4 */}
            <Col lg={3} className="column">
              <div className="titleitem">Team Member Image</div>
          <div className="desc">Size :- 152 × 152</div>

            </Col>
            <Col lg={9} className="column">
              <input
                id="bannerImage"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setBannerImg(e.target.files[0]);
                  setShowImg(true);
                  setImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {bannerImg ? bannerImg.name : ""}
                </div>
              </div>
              <div className="err">{bannerImgErr} </div>
            </Col>
          </Row>

          <div className="btnContainer btn_press mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
          <div className="text-right">
            {bannerImg !== "" && (
              <img
                src={showImg ? URL.createObjectURL(bannerImg) : bannerImg}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default AboutUsSecFour;
