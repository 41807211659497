import React, { useState, useEffect } from "react";
import "./style.less";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import TvAdsItem from "../TvAdsItem/TvAdsItem";

import { Col, Row } from "react-bootstrap";
import JobApplyModel from "../JobApplyModel/JobApplyModel";
import TvAdsModel from "../TvAdsModel/TvAdsModel";
import itemImg from "../../images/tvads/thumb.jpg";
import useWindowDimensions from "../../useWindowDimensions";
import uniqid from "uniqid";
import yahoo from "../../images/homepage/yahoo.svg";
import desitalk from "../../images/homepage/desitalk.png";
import indian from "../../images/homepage/indian.png";
import universal from "../../images/homepage/universal.png";

import insider from "../../images/pressrelease/insider.png";
import newsindia from "../../images/pressrelease/newsindia.png";
import indiawest from "../../images/pressrelease/indiawest.png";
import weekly from "../../images/pressrelease/weekly.png";
import hiindia from "../../images/pressrelease/hiindia.png";

import IndiaWestbanner from "../../images/pressrelease/IndiaWestbanner.png";
import WeeklyBangaleeBanner from "../../images/pressrelease/WeeklyBangaleeBanner.jpg";

import WOW from "wowjs";
import PressReleaseItem from "../PressReleaseItem/PressReleaseItem";
import Imageurldata from "../../Imageurldata";
// const props.pressReleaseArr = [
//   {
//     img: yahoo,
//     desc: "House of Spices co-president on COVID-19…",
//     date: "November 18, 2020",
//     link:
//       "https://finance.yahoo.com/video/house-spices-co-president-covid-164207431.html",
//   },
//   {
//     img: desitalk,
//     desc: "One of the oldest South Asian food company set...",
//     date: "December 11, 2020",
//     link: "http://epaper.desitalk.com/2020_12_11/16/ ",
//   },

//   {
//     img: universal,
//     desc: "House Of Spices” Set To Expand In The Us With...",
//     date: "December 2, 2020",
//     link:
//       "https://theunn.com/house-of-spices-set-to-expand-in-the-us-with-2nd-generation-family-owners-share-new-vision-for-the-u-s-market/",
//   },
//   {
//     img: indian,
//     desc: "House of Spices, the Oldest South Asian...",
//     date: "December 4, 2020",
//     link:
//       "https://www.theindianpanorama.news/wp-content/uploads/2020/12/TIP-December-4-Dual-Edition.pdf",
//   },
//   {
//     img: insider,
//     desc: "House of Spices, the oldest South Asian Food Company...",
//     date: "December 5, 2020",
//     link:
//       "http://thesouthasianinsider.com/emagazine/05dec2020/mobile/index.html",
//   },
//   {
//     img: newsindia,
//     desc: "One of the oldest South Asian food companies...",
//     date: "December 4, 2020",
//     link:
//       "https://www.newsindiatimes.com/one-of-the-oldest-south-asian-food-company-set-to-expand-in-the-u-s/",
//   },

//   {
//     img: indiawest,
//     desc: "Oldest South Asian Food Company, House...",
//     date: "December 4, 2020",
//     link: "",
//     modelImg: IndiaWestbanner,
//   },
//   {
//     img: weekly,
//     desc: "The New generation is leading House of spices",
//     date: "December 5, 2020",
//     link: "",
//     modelImg: WeeklyBangaleeBanner,
//   },

//   {
//     img: hiindia,
//     desc: "House Of Spices The Oldest South Asian Food...",
//     date: "December 7, 2020",
//     link:
//       "https://www.hiindia.com/blog/2020/12/07/house-of-spices-the-oldest-south-asian-food-company-set-to-expand-in-the-u-s-a/",
//   },
// ];

const PressRelease = (props) => {
  const { height, width } = useWindowDimensions();

  const [totalPages, setTotalPage] = useState(10);
  const [selectedPage, setSelectedPage] = useState(0);
  const [totalPageArray, setTotalPageArray] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [pgLoading, setPgLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [selectedArray, setSelectedArray] = useState([]);
  const [totalSlicePageArray, settotalSlicePageArray] = useState([]);

  useEffect(() => {
    // new WOW.WOW({
    //   live: false,
    // }).init()

    // new WOW.WOW().init()
    // document.title = "Hos - Media (Press Release)";

    document.title = "Media: Press Release - Latest Updates";
    document.getElementsByTagName("META")[3].content =
      "Latest updates of HOS Global Foods most recent activities.";
    document.getElementsByTagName("META")[4].content =
      "HOS Global Foods Press Release, HOS Global Foods Latest Updates";

    setSelectedPage(0);
    if (width < 1024) {
      setTotalPage(Math.ceil(props.pressReleaseArr.length / 3));
      let temp = [];
      ////console.log(
      //   Math.ceil(AutomotiveArr.length / 4),
      //   AutomotiveArr.length
      // )
      for (let i = 1; i <= Math.ceil(props.pressReleaseArr.length / 3); i++) {
        temp.push(i);
      }
      setTotalPageArray(...[temp]);
      ////console.log(temp)
      settotalSlicePageArray(...[temp.slice(0, 3)]);

      let pagestart = 0;
      let temp2 = [...props.pressReleaseArr];
      setSelectedArray(temp2.slice(pagestart, pagestart + 3));
    } else {
      setTotalPage(Math.ceil(props.pressReleaseArr.length / 12));

      let temp = [];

      for (let i = 1; i <= Math.ceil(props.pressReleaseArr.length / 12); i++) {
        temp.push(i);
      }
      setTotalPageArray(...[temp]);
      settotalSlicePageArray(...[temp.slice(0, 3)]);

      let pagestart = 0;
      let temp2 = [...props.pressReleaseArr];
      setSelectedArray(temp2.slice(pagestart, pagestart + 12));
    }
  }, []);

  useEffect(() => {
    if (width < 1024) {
      let pagestart = selectedPage * 3;
      let temp = [...props.pressReleaseArr];
      setSelectedArray(temp.slice(pagestart, pagestart + 3));
    } else {
      let pagestart = selectedPage * 12;
      let temp = [...props.pressReleaseArr];
      setSelectedArray(temp.slice(pagestart, pagestart + 12));
    }
    leftArrowClickOpacityHandle();
    rightArrowClickOpacityHandle();
  }, [selectedPage]);

  const leftArrowClickOpacityHandle = () => {
    if (selectedPage === 0) {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = ".5";
        document.getElementById("paginationLeftarrow").style.cursor = "initial";
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = "1";
        document.getElementById("paginationLeftarrow").style.cursor = "pointer";
      } catch (error) {}
    }
  };

  const rightArrowClickOpacityHandle = () => {
    if (totalPages === selectedPage + 1) {
      try {
        document.getElementById("paginationrightarrow").style.opacity = ".5";
        document.getElementById("paginationrightarrow").style.cursor =
          "initial";
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationrightarrow").style.opacity = "1";
        document.getElementById("paginationrightarrow").style.cursor =
          "pointer";
      } catch (error) {}
    }
  };

  const leftArrowClick = () => {
    if (!(selectedPage - 1 < 0)) {
      //   ////console.log("total page", totalPageArray.length)
      //   ////console.log("current page", selectedPage)
      //   ////console.log("total page-2", totalPageArray.length - 2)
      //   ////console.log("==>", totalPageArray.length - 2 >= selectedPage)

      if (totalPageArray.length - 2 >= selectedPage) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage - 1, selectedPage + 2)
        );
      }

      setSelectedPage(selectedPage - 1);
      scrollToDiv();
    }
  };
  const nextArrowClick = () => {
    if (selectedPage + 1 !== totalPageArray.length) {
      if ((selectedPage + 1) % 2 === 0 || selectedPage >= 2) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage, selectedPage + 3)
        );
      }

      setSelectedPage(selectedPage + 1);

      scrollToDiv();
    }
    // totalPageArray.length === (selectedPage+1) ?null :
  };
  const scrollToDiv = () => {
    const id = "list";
    const yOffset = -170;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const paginationNoClick = (i) => {
    setSelectedPage(i - 1);
    scrollToDiv();
  };

  const pressSliderList = selectedArray.map((item, i) => (
    <PressReleaseItem
      key={i}
      img={Imageurldata + item.imgUrl}
      desc={item.desc}
      date={item.date}
      link={item.link}
      modelImg={item.modelImg}
    />
  ));

  return (
    <div className="pressRelease">
      <div className="title">
        Get the latest updates on some of our most recent activities.
      </div>

      <Row id="list" className="row m-0">
        {pressSliderList}
      </Row>
      {totalPageArray.length === 1 ? null : (
        <div className="d-f j-c a-c productListPaginationContainer">
          <div onClick={leftArrowClick} id="paginationLeftarrow">
            Prev
          </div>
          <PaginationComponent
            // paginationNoClick={(item) => paginationNoClick(item)}
            paginationNoClick={(item) => paginationNoClick(item)}
            totalPageArray={totalSlicePageArray}
            selectedPage={selectedPage}
          />

          <div onClick={nextArrowClick} id="paginationrightarrow">
            Next
          </div>
        </div>
      )}
    </div>
  );
};

export default PressRelease;
