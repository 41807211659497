// *****************
// Component Created by - Vijay Navale
// Date - 05/10/2022
// Component use case:- To show products with it's categories
// *****************

import React, { useEffect } from "react";
import { useWindowSize } from "react-use";
import "./catalogueCta.less";
import BannerItem from "../../component/BannerItem/BannerItem";
import bannerimg from "../../images/catalogue-cta/catalogue_banner.jpg";
import mbbannerimg from "../../images/catalogue-cta/mb_catalogue_banner_2.jpg";
import catalogueFile from "../../images/catalogue-cta/New-Partner-Brands-Product-Catalogue.pdf";
import { ReactComponent as Downloadicon } from "../../images/catalogue/download-icon.svg";
import { Link } from "react-router-dom";

const CatalogueCta = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scroll(0, 0);
    document.title =
      "Explore the full catalogue of Laxmi (HOS) - North America's #1 South Asian Food Brand";
    document.getElementsByTagName("META")[3].content =
      "Checkout the full catalogue of Laxmi (HOS).The Laxmi Promise is to provide Purity, Quality and Value. Explore Laxmi's endless range of products now!";
    document.getElementsByTagName("META")[4].content =
      "Checkout the full catalogue of Laxmi (HOS).The Laxmi Promise is to provide Purity, Quality and Value. Explore Laxmi's endless range of products now!";
  }, []);

  return (
    <main className="main_catalog_sec1">
      <BannerItem
        color="black"
        img={width > 767 ? bannerimg : mbbannerimg}
        title="cAtalogUE"
      />

      <section className="catalog_sec2">
        <div className="catasec2_heading">
          <div className="cta_wrapper">
            <Link to={"/hos-brand-catalogue"} className="download_cta">
              <span className="downloadtext">HOS Brand Catalogue</span>
              <Downloadicon className="downloadicon" />
            </Link>
            <a href={catalogueFile} target="_blank" className="download_cta">
              <span className="downloadtext">Partner Brand Catalogue</span>
              <Downloadicon className="downloadicon" />
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CatalogueCta;
