import React, { useState, useEffect } from "react";
import "./ApplyModal.less";
import { Modal, Button } from "react-bootstrap";
import { uploadIcon, uploadResume, addDoc } from "../../images";
import useWindowDimensions from "../../useWindowDimensions";
import Joi from "joi";
import config from "../../config/config";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import urldata from "../../urldata";
import _ from "lodash";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const ApplyModal = ({ btnText, data, codes }) => {
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [values, setValues] = useState({
    country: data.country?.title,
    location: data.location?.title,
    department: data.department?.title,
    country_code: "+91",
    admin_email: data.country?.admin_email,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  // const [consent, setConsent] = useState(false);

  const base_url = new URL(urldata + "careers/apply-job");

  let sizeLimit = 2;

  const allowedExtensions = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const joiOptions = config.joiOptions;

  // const navigate = useNavigate();

  const validate = async (value) => {
    console.log({ value });
    const schemaObj = {
      position: Joi.string().trim().required().label("Position"),
      name: Joi.string().trim().required().label("Name"),

      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email ID"),
      phone: Joi.number().required().label("Phone Number"),
      qualification: Joi.string().trim().required().label("Qualification"),
      experience: Joi.string().trim().required().label("Experience"),
      expected_salary: Joi.string().trim().required().label("Expected Salary"),
      resume: Joi.required().label("Resume"),

      // phone: Joi.string()
      //   .length(10)
      //   .pattern(/^[0-9]+$/)
      //   .messages({ "string.pattern.base": `Phone Number. must have 10 digits.` })
      //   .required()
      //   .label("Phone Number"),

      // message: Joi.string().trim().label("Message"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }
    if (value.resume) {
      helper.validateSize("resume", { file: value.resume }, sizeLimit, errors);
      helper.validateExt(
        "resume",
        { file: value.resume },
        allowedExtensions,
        errors
      );
    }

    if (value.document) {
      helper.validateSize(
        "document",
        { file: value.document },
        sizeLimit,
        errors
      );
      helper.validateExt(
        "document",
        { file: value.document },
        allowedExtensions,
        errors
      );
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(values, ["resume", "document"])
    )) {
      formData.append(key, data || "");
    }
    if (values.resume) {
      formData.append("resume", values.resume);
    }
    if (values.document) {
      formData.append("document", values.document);
    }

    // console.log(...formData);
    // return;

    const { data: response } = await http.post(base_url, formData);

    if (response) {
      setValues({
        position: data.title,
        phone: "",
        qualification: "",
        experience: "",
        expected_salary: "",
        name: "",
        email: "",
        country: data.country?.title,
        location: data.location?.title,
        department: data.department?.title,
        admin_email: data.country?.admin_email,

        country_code: "+91",
      });
      // setIsThankyouVisible(true);
      // navigate("/thank-you");
      enqueueSnackbar("Thank you for Applying", { variant: "success" });
      handleClose();
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    console.log(e);
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  useEffect(() => {
    setValues((prevState) => {
      return {
        ...prevState,
        position: data.title,
      };
    });
  }, []);

  return (
    <>
      <section className="apply_modal_wrapper">
        <Button onClick={handleShow}>{btnText}</Button>

        <Modal
          show={show}
          onHide={handleClose}
          className="apply_modal_details my_container full_width_container no_padding_container"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="modal_title">APPLY FOR THE JOB</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form_container" onSubmit={handleSubmit}>
              {width > 767 ? (
                <div className="row form_main_row">
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Post Applied For*"
                              name="position"
                              value={values.position}
                              onChange={handleChange}
                              readOnly
                            />
                            <label for="name">Post Applied For*</label>
                            <p className="err">{formError.position}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 form_col">
                        <div className="country_drop_wrap">
                          <div className="input_wrapper">
                            <select
                              className="input_field select_field"
                              name="country_code"
                              value={values.country_code}
                              onChange={handleChange}
                            >
                              {codes.map((item, i) => {
                                return (
                                  <option
                                    value={`+${item}`}
                                  >{`+${item}`}</option>
                                );
                              })}
                            </select>
                            <p className="err"></p>
                          </div>
                          <div className="input_wrapper">
                            <div class="form-floating">
                              <input
                                type="tel"
                                className="form-control input_field"
                                placeholder="Phone Number*"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <label for="name">Phone Number*</label>
                              <p className="err">{formError.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Qualification*"
                              name="qualification"
                              value={values.qualification}
                              onChange={handleChange}
                            />
                            <label for="name">Qualification*</label>
                            <p className="err">{formError.qualification}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Experience*"
                              name="experience"
                              value={values.experience}
                              onChange={handleChange}
                            />
                            <label for="name">Experience*</label>
                            <p className="err">{formError.experience}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Minimum Expected Salary*"
                              name="expected_salary"
                              value={values.expected_salary}
                              onChange={handleChange}
                            />
                            <label for="name">Minimum Expected Salary*</label>
                            <p className="err">{formError.expected_salary}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Name*"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <label for="name">Name*</label>
                            <p className="err">{formError.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="email"
                              className="form-control input_field"
                              placeholder="Email ID*"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <label for="email">Email ID*</label>
                            <p className="err">{formError.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={uploadResume}
                              alt="resume"
                              className="resume_icon"
                            />
                            <p className="drop_text">Drop Your CV Here*</p>
                            <p className="reply_text">
                              We will consider your profile for future jobs
                            </p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile2"
                            className="input_field"
                            name="resume"
                            hidden
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile2" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                            <span className="upload_text">
                              Drag File to Upload OR
                            </span>
                          ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">
                              File Size: Less than {sizeLimit} MB
                            </p>
                          </div>
                          <p className="file_name">{values?.resume?.name}</p>
                          <div className="up_bot_txt_wrapper">
                            <p className="err">{formError.resume}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={addDoc}
                              alt="resume"
                              className="resume_icon add_icon"
                            />
                            <p className="drop_text">Add other DOC</p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile3"
                            className="input_field"
                            name="document"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile3" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                            <span className="upload_text">
                              Drag File to Upload OR
                            </span>
                          ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">
                              File Size: Less than {sizeLimit} MB
                            </p>
                          </div>
                          <p className="file_name">{values?.document?.name}</p>
                          <div className="up_bot_txt_wrapper">
                            <p className="err">{formError.document}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button className="career_cta">
                      {" "}
                      {loading ? "Loading.." : "SUBMIT"}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row form_main_row">
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Post Applied For*"
                              name="position"
                              value={values.position}
                              onChange={handleChange}
                              readOnly
                            />
                            <label for="name">Post Applied For*</label>
                            <p className="err">{formError.position}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Name*"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <label for="name">Name*</label>
                            <p className="err">{formError.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="country_drop_wrap">
                          <div className="input_wrapper">
                            <select
                              className="input_field select_field"
                              name="country_code"
                              value={values.country_code}
                              onChange={handleChange}
                            >
                               {codes.map((item, i) => {
                                return (
                                  <option
                                    value={`+${item}`}
                                  >{`+${item}`}</option>
                                );
                              })}
                            </select>
                            <p className="err"></p>
                          </div>
                          <div className="input_wrapper">
                            <div class="form-floating">
                              <input
                                type="tel"
                                className="form-control input_field"
                                placeholder="Phone Number*"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <label for="name">Phone Number*</label>
                              <p className="err">{formError.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="email"
                              className="form-control input_field"
                              placeholder="Email ID*"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <label for="name">Email ID*</label>
                            <p className="err">{formError.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Qualification*"
                              name="qualification"
                              value={values.qualification}
                              onChange={handleChange}
                            />
                            <label for="name">Qualification*</label>
                            <p className="err">{formError.qualification}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Experience*"
                              name="experience"
                              value={values.experience}
                              onChange={handleChange}
                            />
                            <label for="name">Experience*</label>
                            <p className="err">{formError.experience}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Minimum Expected Salary*"
                              name="expected_salary"
                              value={values.expected_salary}
                              onChange={handleChange}
                            />
                            <label for="name">Minimum Expected Salary*</label>
                            <p className="err">{formError.expected_salary}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={uploadResume}
                              alt="resume"
                              className="resume_icon"
                            />
                            <p className="drop_text">Drop Your CV Here*</p>
                            <p className="reply_text">
                              We will consider your profile for future jobs
                            </p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile2"
                            className="input_field"
                            name="resume"
                            hidden
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile2" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                            <span className="upload_text">
                              Drag File to Upload OR
                            </span>
                          ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">
                              File Size: Less than {sizeLimit} MB
                            </p>
                          </div>
                          <p className="file_name">{values?.resume?.name}</p>
                          <div className="up_bot_txt_wrapper">
                            <p className="err">{formError.resume}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={addDoc}
                              alt="resume"
                              className="resume_icon add_icon"
                            />
                            <p className="drop_text">Add other DOC</p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile3"
                            className="input_field"
                            name="document"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile3" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                            <span className="upload_text">
                              Drag File to Upload OR
                            </span>
                          ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">
                              File Size: Less than {sizeLimit} MB
                            </p>
                          </div>
                          <p className="file_name">{values?.document?.name}</p>
                          <div className="up_bot_txt_wrapper">
                            <p className="err">{formError.document}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button className="career_cta">
                      {" "}
                      {loading ? "Loading.." : "SUBMIT"}
                    </button>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ApplyModal;
