import React, { useState, useEffect } from "react";
import BannerCommon from "../BannerCommon";


const OurBrandsBanner = () => {
    return (
      <BannerCommon header='Brands' screenQuery='ourbrands'/>
    )
}

export default OurBrandsBanner
