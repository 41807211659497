import React, { useState } from "react";
import { Modal, Form, notification, Upload, Row, Col, Input } from "antd";
import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import http from "../../../../helpers/http";
import config from "../../../../config/config";
import _ from "lodash";
import helper from "../../../../helpers/helper";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const { TextArea } = Input;



  const validate = (value) => {
    let schemaObj = {
      title: Joi.string().required().label("Department Name"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "mobile_image"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.mobile_image) {
      formData.append("mobile_image", value.mobile_image.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      // width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
            required
              label="Department Name:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
         
        
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
