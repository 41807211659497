import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Drawer,
  Descriptions,
  Anchor,
  Tag,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../../../helpers/http";
import helper from "../../../../helpers/helper";
import config from "../../../../config/config";
import "./style.css";
import urldata from "../../../../urldata";
function CareerJobLead() {
  const [loading, setloading] = useState(false);
  const [drawerOpen, setdraweropen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const moduleNamePural = "Job Entries";

  const base_url = urldata + "admin/leads/career-jobs";

  const image_url = urldata + "public/uploads/careerJob/"; //with trailing slash

  const { Link } = Anchor;

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      setdraweropen(true);
    }
  };

  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => {
        return (record.country_code || "") + record.phone;
      },
    },
    {
      title: "Post Applied For",
      dataIndex: "position",
      key: "position",
    },

    {
      title: "Created At",
      render: (text, record) => {
        return helper.ISTDate(record.createdAt) + " IST";
      },
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              handleView(record._id);
            }}
          >
            View Detail
          </Button>
        </Space>
      ),
    },
  ];

  const fields: ITableExportFields = {
    name: "Name",
    email: "Email ID",
   
    phone: {
      header: "Phone Number",
      formatter: (_fieldValue, record) => {
        return (record.country_code || "") + record.phone;
      },
    },
    position: "Post Applied For",
    qualification: "Qualification",
    expected_salary: "Minimum Expected Salary",
    experience: "Experience",
    

    resume: {
      header: "Resume",
      formatter: (_fieldValue, record) => {
        return image_url + record.resume;
      },
    },
    document: {
      header: "Document",
      formatter: (_fieldValue, record) => {
        return (record.document)  ? image_url + record.document :  "NA" ;
      },
    },

    
    createdAt: {
      header: "Created At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.createdAt);
      },
    },
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={{
                  showColumnPicker: true,
                  fields,
                  fileName: moduleNamePural,
                }}
                searchable
              />
            </Card>

            <Drawer
              title="Lead Details"
              placement="right"
              onClose={handleDrawerClose}
              visible={drawerOpen}
              size="large"
            >
              {data && (
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                  <Descriptions.Item label="Name">
                    {data.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email ID">
                    {data.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone Number">
                  {(data.country_code || "") + data.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="Post Applied For">
                    {data.position}
                  </Descriptions.Item>
                  <Descriptions.Item label="Qualification">
                    {data.qualification}
                  </Descriptions.Item>
                  <Descriptions.Item label="Experience">
                    {data.experience}
                  </Descriptions.Item>
                  <Descriptions.Item label="Minimum Expected Salary">
                    {data.expected_salary}
                  </Descriptions.Item>

                  <Descriptions.Item label="Resume">
                    <a href={image_url + data.resume} target="_blank">
                      View Resume
                    </a>
                  </Descriptions.Item>
                  {data.document && (
                    <Descriptions.Item label="Document">
                      <a href={image_url + data.document} target="_blank">
                        View Document
                      </a>
                    </Descriptions.Item>
                  )}

                  <Descriptions.Item label="Created At">
                    {helper.ISTDate(data.createdAt)} IST
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CareerJobLead;
