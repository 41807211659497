import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi";
import _ from "lodash";

// import config from "../../config/config";
// import HtmlEditor from "../../components/HtmlEditor";
import http from "../../../../helpers/http";
import slugify from "react-slugify";
import urldata from "../../../../urldata";
import helper from "../../../../helpers/helper";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const base_url = urldata + "admin/products/brands"; //without trailing slash
  const image_url = urldata + "public/uploads/product/"; //with trailing slash
  const sizeLimit = 1;

  const { TextArea } = Input;
  const { Option } = Select;

  const schema = Joi.object({
    name: Joi.string().required(),
    slug: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = schema.validate(value, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "ProductBrand");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, []));

    // let filteredCategory2 = allCategory.filter(
    //   (data) => data.brand == dbData.brand
    // );
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
