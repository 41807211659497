import React, { useState, useEffect } from "react";
import {urldata2} from "../../urldata";
import salescatalog from "./salescatalog.pdf";
import './style.less'
import axios from 'axios'
import urldata from "../../urldata";
import Imageurldata from "../../Imageurldata";
const Content = () => {
  const [pdfData, setPdfData] = useState("");
  // let testUrl = "http://13.233.110.14:4000/api/" 
  let liveUrl = urldata 

  
  // let testUrlWithoutSlash = "http://13.233.110.14:4000/api"
  let liveUrlWithoutSlash = Imageurldata; 

  // console.log(pdfData)
  useEffect(() => {
    // let url = new URL('http://13.233.110.14:4000/api/' + "admin/getpdf");
    let url = new URL(liveUrl + "admin/getpdf");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        setPdfData(response.data.data[0])
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        // setLoading(false);
        // showToast("danger");
      });
  }, [])


  return (
    <div className='iframepdf'>
       <iframe
              className=""
              id="areYouRiskReadyVideo"
              width="100%"
              height="100vh"
              src={pdfData.pdfupload ? liveUrlWithoutSlash+pdfData.pdfupload: ''}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              ></iframe>

    </div>
  );
};

export default Content;

// src={pdfData.pdfupload ? 'http://13.233.110.14:4000/api'+pdfData.pdfupload: ''}