import React, { useState, useEffect } from "react";
import moment from "moment";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

let date = moment('21 May, 2021');
let dateComponent = date.utc().format("YYYY-MM-DD");

const TvAds = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [updateModel, setUpdateModel] = useState(false);

  const [tvAdId, setTvAdId] = useState("");

  const [show, setShow] = useState(false);

  const [tvadTitle, setTvadTitle] = useState("");
  const [tvadTitleErr, setTvadTitleErr] = useState("");

  const [dateVal, setDateVal] = useState("");
  const [dateErr, setDateErr] = useState("");

  const [dateInSentenceFormat, setDateInSentenceFormat] = useState('');

  const [dateIso, setDateIso] = useState("");

  const [youtubeId, setYoutubeId] = useState("");
  const [youtubeIdErr, setYoutubeIdErr] = useState("");

  const [bannerImg, setBannerImg] = useState("");
  const [bannerImgErr, setBannerImgErr] = useState("");

  const [imgNotPresent, setImgNotPresent] = useState('')

  const [showImg, setShowImg] = useState('')

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;
 
  useEffect(() => {
    let url = new URL(urldata + "admin/getTvAds");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        //showToast("danger");
      });
  }, []);

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Tv Ad ${updateModel? 'updated': 'saved'} Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Tv Ad deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShowImg(false)
    setBannerImg("");

    setUpdateModel(false);
    // setDateFormat("");
    setTvadTitle("");
    setYoutubeId("");
    setDateVal("");
    setDateInSentenceFormat('');
    setShow(true);
  };

  //getting proper date format for dateVal and dateIso state
  const dateArrangements = (e) => {
    setDateVal(e.target.value);
    const date = moment(e.target.value);
    const dateObj = date._d;
    const dateArr = dateObj.toString().split(" ");
    const newDateArr = dateArr.filter((item, index) => {
      if (index === 1 || index === 2 || index === 3) {
        return item;
      }
    });

    let finalDateVal = `${newDateArr[1]} ${newDateArr[0]}, ${newDateArr[2]}`;
    // console.log(finalDateVal);
    setDateInSentenceFormat(finalDateVal);
    setDateIso(moment(e.target.value).toISOString());
  };

  const setAllDates = (date) => {
    let properDateFormat = moment(date);
    let dateComponent = moment(date).format('L');
    const dateFormatForG = dateComponent.split("/")
    dateComponent = `${dateFormatForG[2]}-${dateFormatForG[0]}-${dateFormatForG[1]}`
    setDateVal(dateComponent);
    setDateInSentenceFormat(date);
  }
  const handleShowForUpdate = (rowData) => {
    setUpdateModel(true);
    setTvadTitle(rowData.title);
    setYoutubeId(rowData.youtubeId);
    setShow(true);
    setTvAdId(rowData._id);
    setDateIso(rowData.dateIso)
    setBannerImg(Imageurldata+rowData.imgUrl);
    setShowImg(false);

    setImgNotPresent(rowData.imgUrl ? false: true)
    setAllDates(rowData.date)
  };

  const updateTvAd = () => {
    let formData = new FormData();
    formData.append("date", dateInSentenceFormat);
    formData.append("dateIso", dateIso);
    formData.append("title", tvadTitle);
    formData.append("youtubeId", youtubeId);

    if(imgNotPresent){
      formData.append("banner", bannerImg);
      setImgNotPresent(false)
    }

    let url = new URL(urldata + "admin/updateTvAds");

    url.searchParams.append("id", tvAdId);
    var decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        // console.log(templist);
        setList(templist);
        setShow(false);
        setLoading(false);
        showToast("success");
        
      })
      .catch((error) => {
        // showToast("danger");
        // console.log("error", error);
        setLoading(false);
      });
  };

  const addTvAd = () => {
    let formData = new FormData();
    formData.append("date", dateInSentenceFormat);
    formData.append("dateIso", dateIso);
    formData.append("title", tvadTitle);
    formData.append("youtubeId", youtubeId);
    formData.append("banner", bannerImg);

    let url = new URL(urldata + "admin/addTvAds");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const tempList = response.data.data.reverse();
        setList(tempList);
        setShow(false);
        setLoading(false);
        showToast('success')
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
        // showToast('danger')
      });
  };

  const submit = () => {
    let formIsValid = true;

    if (!tvadTitle) {
      formIsValid = false;
      setTvadTitleErr("*Please add TV ad title");
    } else {
      setTvadTitleErr("");
    }

    if (!dateInSentenceFormat) {
      formIsValid = false;
      setDateErr("*Please add TV ad date");
    } else {
      setDateErr("");
    }

    if (!youtubeId) {
      formIsValid = false;
      setYoutubeIdErr("*Please add youtube ID");
    } else {
      setYoutubeIdErr();
    }

    if (!bannerImg) {
      formIsValid = false;
      setBannerImgErr("*Please add TV ad image");
    } else {
      setBannerImgErr("");
    }

    if (formIsValid) {
      if (updateModel) {
        updateTvAd();
      } else {
        addTvAd();
      }
    }
  };

  const deleteBtnClick = (id) => {
    let data = {};
    data.id = id;

    let url = new URL(urldata + "admin/deleteTvAds");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");
        console.error(error);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }


  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add TV ad" onClick={handleShow} />
      </div>
      <MaterialTable
        title="TV ads list"
        columns={[
          { title: "Title", field: "title" },
          { title: "Date", field: "date" },
          { title: "YouTube ID", field: "youtubeId" },
          {
            field: "imgUrl",
            title: "Tv Ad Image",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.imgUrl, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "Action",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model" style={{ position: "relative" }}>
          <div className="title">
            {updateModel ? "Update Tv Ad" : "Add Tv Ad"}
          </div>

          <Row>
            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Title</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={tvadTitle}
                onChange={(e) => setTvadTitle(e.target.value)}
                // maxLength="40"
                type="text"
              />
              <div className="err">{tvadTitleErr}</div>
              {/* <div className="count">{tvadTitle?.length}/40</div> */}
            </Col>

            {/* col 2 */}
            <Col lg={3} className="column">
              <div className="titleitem">Date</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={dateVal}
                onChange={dateArrangements}
                type="date"
                min="1972-01-01"
                max="2070-12-31"
              />
              <div className="err">{dateErr}</div>
            </Col>

            {/* col-3 */}
            <Col lg={3} className="column">
              <div className="titleitem">Youtube ID</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={youtubeId}
                onChange={(e) => setYoutubeId(e.target.value)}
                type="text"
              />

              <div className="err">{youtubeIdErr}</div>
            </Col>

            {/* col-4 */}
            <Col lg={3} className="column">
              <div className="titleitem">Banner Image</div>
              <div className="desc">Size :- 1646 × 922</div>
            </Col>
            <Col lg={9} className="column">
              <input
                id="bannerImage"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setBannerImg(e.target.files[0]);
                  setShowImg(true);
                  setImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {bannerImg ? bannerImg.name : ""}
                </div>
              </div>
              <div className="err">{bannerImgErr} </div>
            </Col>
          </Row>

          <div className="btnContainer btn_press mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
          <div className="text-right">
            {bannerImg !== "" && (
              <img
                src={showImg ? URL.createObjectURL(bannerImg) : bannerImg}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default TvAds;
