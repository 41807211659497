import React, { useState, useEffect } from "react";
import "./style.less";
import useWindowDimensions from "../../useWindowDimensions";

import blackBackArr from "../../images/about/blackBackArr.svg";
import blackNextArr from "../../images/about/blackNextArr.svg";
import Imageurldata from "../../Imageurldata";
import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

const OurTeamSlide = (props) => {
  const { height, width } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    var PersonDetailsSwiper = new Swiper(".aboutsec4 .PersonDetailsSwiper", {
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 5,
      slideToClickedSlide: true,
      loop: true,
      // freeMode: true,
      // watchSlidesVisibility: true,
      // watchSlidesProgress: true,
    });

    var mPersonDetailsSwiper = new Swiper(".aboutsec4 .mPersonDetailsSwiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: ".aboutsec4 .rightarrow",
        prevEl: ".aboutsec4 .leftarrow",
      },
    });

    PersonDetailsSwiper.on("transitionEnd", function () {
      //console.log("*** mySwiper.realIndex", PersonDetailsSwiper.realIndex);
      setIndex(PersonDetailsSwiper.realIndex);
    });

    mPersonDetailsSwiper.on("transitionEnd", function () {
      //console.log("*** mySwiper.realIndex", mPersonDetailsSwiper.realIndex);
      setIndex(mPersonDetailsSwiper.realIndex);
    });

    // var PersonDescSwiper = new Swiper(".aboutsec4 .PersonDescSwiper", {
    //   centeredSlides: true,
    //   spaceBetween: 10,
    //   slidesPerView: 1,
    //   loop: true,
    //   slideToClickedSlide: true,
    //   thumbs: {
    //     swiper: PersonDetailsSwiper,
    //   },
    // });
  }, []);

  const PersonDetailsSlider = props.aboutsection4.map((item, i = 1) => (
    <div key={i} className="swiper-slide">
      <div className="personImg">
        <img className="img-fluid c-p" src={Imageurldata + item.img} />
      </div>
      <div className="personName">{item.name}</div>
      <div className="personDesg">{item.designation}</div>
    </div>
  ));

  const PersonDescSlider = props.aboutsection4.map((item, i = 1) => (
    <div key={i} className="swiper-slide">
      <div className="PersonDesc">
        <p>{item.desc}</p>
      </div>
    </div>
  ));

  const mPersonDetailsSlider = props.aboutsection4.map((item, i = 1) => (
    <div key={i} className="swiper-slide">
      <div className="personImg">
        <img className="img-fluid c-p" src={Imageurldata + item.img} />
      </div>
      <div className="personName">{item.name}</div>
      <div className="personDesg">{item.designation}</div>
      <div className="PersonDesc">
        <p>{props.aboutsection4[index].desc}</p>
      </div>
    </div>
  ));
  return (
    <>
      <div className="ourTeamSlider">
        {width >= 1024 ? (
          <>
            <div className="PersonDetailsSwiper swiper-container">
              <div className="swiper-wrapper">{PersonDetailsSlider}</div>
            </div>
            {/* <div className="PersonDescSwiper swiper-container">
          <div className="swiper-wrapper">{PersonDescSlider}</div>
        </div> */}
            <div className="PersonDesc">
              <p>{props.aboutsection4[index].desc}</p>
            </div>
          </>
        ) : (
          <>
            <div className="mPersonDetailsSwiper swiper-container">
              <div className="swiper-wrapper">{mPersonDetailsSlider}</div>
              <div className="d-f j-c a-c">
                <div className="arrow d-f as-c">
                  <div className="leftarrow">
                    <img src={blackBackArr} className="fluid c-p"></img>
                  </div>
                  <div className="rightarrow">
                    <img src={blackNextArr} className="fluid c-p"></img>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OurTeamSlide;
