import React, { useEffect, useRef, useState } from "react";
import "./careersdetail.less";
import { Link } from "react-router-dom";
import {
  addDoc,
  backIcon,
  locationIcon,
  minusIcon,
  plusIcon,
  shareIcon,
  successIcon,
  uploadIcon,
  uploadResume,
  workIcon,
} from "../../images";
import useWindowDimensions from "../../useWindowDimensions";
import BannerItem from "../../component/BannerItem/BannerItem";
import { careerDetailBannerData, jobsData } from "../../data/careerDetailsData";
import ApplyModal from "../../component/ApplyModal/ApplyModal";
import urldata from "../../urldata";
import http from "../../helpers/http";
// import {toast} from "react-toastify";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import _ from "lodash";
import helper from "../../helpers/helper";
import Joi from "joi";
import config from "../../config/config";

const CareersDetail = (props) => {
  const { width } = useWindowDimensions();
  const [isShow, setIsShow] = useState();
  const [isThankyouVisible, setIsThankyouVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [countries, setCountries] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [locations, setLocations] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [filterError, setFilterError] = useState("");
  const [filters, setFilters] = useState({
    department: "all",
    country: "all",
    location: "all",
    experience: "all",
  });

  const [codes, setCodes] = useState([]);

  const [values, setValues] = useState({
    country_code: "+91",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const activeAccordionRef = useRef(null);

  const handleItemClick = (index) => {
    setIsShow((prevIndex) => (prevIndex === index ? null : index));
  };

  const scrollToActiveAccordion = () => {
    if (activeAccordionRef.current) {
      activeAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  function validateFilters(filters) {
    for (let key in filters) {
      if (filters[key] !== "all") {
        return true;
      }
    }
    return false;
  }

  const copy = (jobId) => {
    console.log("domain:", window.location.origin);
    const el = document.createElement("input");
    // el.value = window.lo cation.href + "/" + jobId;
    el.value = `${window.location.origin}/careers-details/${jobId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    enqueueSnackbar("URL Copied to your clipboard", { variant: "success" });
    // console.log("url copied:", el.value);
  };

  // const openJobList = () => {

  // }
  const base_url = new URL(urldata + "careers/");
  let sizeLimit = 2;

  const allowedExtensions = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const joiOptions = config.joiOptions;

  const fetchDepartments = async () => {
    const { data } = await http.get(base_url + "departments");

    setDepartments(data);
  };
  const fetchCountries = async () => {
    const { data } = await http.get(base_url + "countries");

    setCountries(data);
  };
  const fetchLocations = async (country_id) => {
    const { data } = await http.get(`${base_url}locations/${country_id}`);

    setLocations(data);
  };
  const fetchExperiences = async () => {
    const { data } = await http.get(base_url + "experiences");

    setExperiences(data);
  };

  const handleFilterChange = (e) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchJobs(filters);
  };

  const fetchJobs = async (filters = {}) => {
    const { data } = await http.get(`${base_url}jobs`, filters);

    setJobs(data);
    handleShareOpen(data);
  };
  const handleShareOpen = async (data) => {
    const jobId = props.match.params.jobId;
    console.log({ jobId });
    if (jobId) {
      const jobIndex = _.findIndex(data, { _id: jobId });

      setIsShow(jobIndex);
      handleScroll(jobId, -125);
    }
  };

  const fetchCodes = async () => {
    const data = await http.get(`${urldata}general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };

  const validate = async (value) => {
    console.log({ value });
    const schemaObj = {
      current_position: Joi.string().trim().required().label("Position"),
      phone: Joi.number().required().label("Phone Number"),
      desired_department: Joi.string().trim().required().label("Department"),
      highest_education: Joi.string().trim().required().label("Education"),
      message: Joi.string().trim().required().label("Message"),
      preferred_location: Joi.string().trim().required().label("Location"),

      resume: Joi.required().label("Resume"),

      // phone: Joi.string()
      //   .length(10)
      //   .pattern(/^[0-9]+$/)
      //   .messages({ "string.pattern.base": `Phone Number. must have 10 digits.` })
      //   .required()
      //   .label("Phone Number"),

      // message: Joi.string().trim().label("Message"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }
    if (value.resume) {
      helper.validateSize("resume", { file: value.resume }, sizeLimit, errors);
      helper.validateExt(
        "resume",
        { file: value.resume },
        allowedExtensions,
        errors
      );
    }

    if (value.document) {
      helper.validateSize(
        "document",
        { file: value.document },
        sizeLimit,
        errors
      );
      helper.validateExt(
        "document",
        { file: value.document },
        allowedExtensions,
        errors
      );
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    console.log({values});
    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(values, ["resume", "document"])
    )) {
      formData.append(key, data || "");
    }
    if (values.resume) {
      formData.append("resume", values.resume);
    }
    if (values.document) {
      formData.append("document", values.document);
    }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url + "genral-interview", formData);

    if (data) {
      setValues({
        current_position: "",
        phone: "",
        country_code: "+91",
        desired_department: "",
        highest_education: "",
        message: "",
        preferred_location: "",
      });
      setIsThankyouVisible(true);



      setTimeout(() => {
      setIsThankyouVisible(false);
        
      }, 3000);
      // navigate("/thank-you");
      // enqueueSnackbar("Thank you for Applying", { variant: "success" });
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    console.log(e);
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const handleScroll = (scrollid, offset) => {
    const id = scrollid;
    const yOffset = offset;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    fetchDepartments();
    fetchCountries();
    fetchExperiences();
    fetchJobs(filters);
    fetchCodes();
  }, []);

  const jobsList = jobs.length ? (
    jobs?.map((item, i) => (
      <div
        className={`job_sub_wrapper ${isShow === i ? "active" : ""}`}
        key={i}
        id={`accordion-${i}`}
        ref={isShow === i ? activeAccordionRef : null}
        // ref={mapRef}
        // ref={el => mapRef.current[_id] = el}
      >
        <div className="job_head_wrapper">
          <div className="title_wrapper">
            <p className="type_title">{item.title}</p>
          </div>
          <div className="title_wrapper">
            <img src={locationIcon} alt="location" className="loc_icon" />
            <p className="loc_title">{item.location?.title}</p>
          </div>
          <div className="title_wrapper">
            <img src={workIcon} alt="location" className="year_icon" />
            <p className="year_title">{item.experience?.title}</p>
          </div>
          <div className="cta_wrapper">
            {/* <Link to={() => false} className="career_cta">
            Apply
          </Link> */}
            <div>
              <ApplyModal
                btnText="APPLY"
                data={item}
                key={item._id}
                codes={codes}
              />
            </div>
          </div>
          {/* <Link to={item.sharLink} className="share_wrapper"> */}
          <Link to={() => false} className="share_wrapper">
            <img
              src={shareIcon}
              alt="share icon"
              className="share_icon"
              onClick={() => copy(item._id)}
            />
          </Link>
          <div className="show_more_wrapper">
            <p
              // className={`show_btn`}
              // onClick={() => toggle(i)}
              // onClick={() => toggle(i, item._id, -200)}
              // onClick={() => {toggle(i); handleScrollClick(item._id, 0)}}
              onClick={() => handleItemClick(i)}
            >
              {/* <span className="plus_sign">{isShow === i ? "-" : "+"}</span> */}
              {isShow === i ? (
                <img src={minusIcon} alt="minus icon" className="minus_icon" />
              ) : (
                <img src={plusIcon} alt="plus icon" className="plus_icon" />
              )}
            </p>
          </div>
        </div>
        <div className={`details_wrapper ${isShow === i ? "active" : ""}`}>
          {item.description && (
            <div className="desc_wrapper">
              <p
                className="description"
                key={i}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></p>
            </div>
          )}
          {/* {item.list && (
          <ul className="list_wrapper">
            {item?.list?.map((data, i) => (
              <li key={i} dangerouslySetInnerHTML={{ __html: data }}></li>
            ))}
          </ul>
        )}
        {item.skills && (
          <p className="description">
            <strong>Skills:</strong> {item.skills}
          </p>
        )} */}
        </div>
      </div>
    ))
  ) : (
    <p className="not_found">Not Found</p>
  );

  useEffect(() => {
    scrollToActiveAccordion();
  }, [isShow]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="career_detail_main">
        <section className="career_detail_sec1">
          <BannerItem
            className="bottom_align"
            img={
              width > 767
                ? careerDetailBannerData[0].banImg
                : careerDetailBannerData[0].mobImg
            }
            title="JOIN OUR TEAM"
            desc="Bring culture alive through the celebration of <br /> authentic ethnic food, flavours and families."
          />
        </section>

        {width > 767 ? (
          <section className="career_detail_sec2">
            <div className="my_container">
              <Link to="/careers" className="back_btn_wrapper">
                <img src={backIcon} alt="right arrow" className="right_arrow" />
                <p className="bck_text">Back</p>
              </Link>
            </div>
          </section>
        ) : null}

        <section className="career_detail_sec3">
          <div className="my_container">
            <form className="input_container" onSubmit={() => false}>
              <div className="input_wrapper">
                <label className="input_label">Department</label>
                <br />
                <select
                  className="select_input"
                  name="department"
                  value={filters.department}
                  onChange={handleFilterChange}
                >
                  <option value="all">All</option>
                  {departments?.map((item) => {
                    return <option value={item._id}>{item.title}</option>;
                  })}
                </select>
                <p className="err">{filterError}</p>
              </div>

              <div className="input_wrapper">
                <label className="input_label">Country</label>
                <br />
                <select
                  className="select_input"
                  name="country"
                  value={filters.country}
                  onChange={(e) => {
                    fetchLocations(e.target.value);
                    handleFilterChange(e);
                  }}
                >
                  <option value="all">All</option>
                  {countries?.map((item) => {
                    return <option value={item._id}>{item.title}</option>;
                  })}
                </select>
                <p className="err"></p>
              </div>

              <div className="input_wrapper">
                <label className="input_label">Location</label>
                <br />
                <select
                  className="select_input"
                  name="location"
                  value={filters.location}
                  onChange={handleFilterChange}
                >
                  <option value="all">All</option>
                  {locations?.map((item) => {
                    return <option value={item._id}>{item.title}</option>;
                  })}
                </select>
                <p className="err"></p>
              </div>

              <div className="input_wrapper">
                <label className="input_label">Experience</label>
                <br />
                <select
                  className="select_input"
                  name="experience"
                  value={filters.experience}
                  onChange={handleFilterChange}
                >
                  <option value="all">All</option>
                  {experiences?.map((item) => {
                    return <option value={item._id}>{item.title}</option>;
                  })}
                </select>
                <p className="err"></p>
              </div>

              <div className="btn_wrapper">
                <button
                  className="career_cta search_btn"
                  role="button"
                  onClick={handleFilter}
                >
                  SEARCH
                </button>
              </div>
            </form>
          </div>
        </section>

        <section className="career_detail_sec4">
          <div className="my_container">
            <div className="job_main_wrapper">
              {/* <h2 className="job_heading">(240 jobs available)</h2> */}
              <h2 className="job_heading">({jobs?.length} jobs available)</h2>
              {jobsList}
            </div>
            <p className="note">
              {/* Don't find a position that suits you? Don't worry you can apply on{" "}
              <a href="mailto:jobs@laxmi.com" rel="noreferrer" className="link">
                jobs@laxmi.com
              </a> */}
              Don’t find the position that suits you? Please fill out the form below, and we will back to you
            </p>
          </div>
        </section>

        <section className="career_details_sec5">
          <div className="my_container">
            <h2 className="heading">
              CRAFT YOUR CAREER LEAVE YOUR MARK AND WE’LL FIND THE PERFECT BLEND
            </h2>
            <form className="form_container" onSubmit={handleSubmit}>
              {width > 767 ? (
                <div className="row form_main_row">
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Current/last position*"
                              name="current_position"
                              value={values.current_position}
                              onChange={handleChange}
                            />
                            <label for="name">Current/last position*</label>
                            <p className="err">{formError.current_position}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="country_drop_wrap">
                          <div className="input_wrapper">
                            <select
                              className="input_field select_field"
                              name="country_code"
                              value={values.country_code}
                              onChange={handleChange}
                            >
                              {codes.map((item, i) => {
                                return (
                                  <option
                                    value={`+${item}`}
                                  >{`+${item}`}</option>
                                );
                              })}
                            </select>
                            <p className="err"></p>
                          </div>

                          <div className="input_wrapper">
                            <div class="form-floating">
                              <input
                                type="tel"
                                className="form-control input_field"
                                placeholder="Phone No.*"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <label for="name">Phone No.*</label>
                              <p className="err">{formError.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <select
                            className="input_field select_field"
                            name="desired_department"
                            value={values.desired_department}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Desired Department you would like to apply for*
                            </option>
                            <option value="3PL">3PL</option>
                            <option value="Admin">Admin</option>
                            <option value="Finance">Finance</option>
                            <option value="HR">HR</option>
                            <option value="Import">Import</option>
                            <option value="IT">IT</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Purchase">Purchase</option>
                            <option value="Quality Assurance ">
                              Quality Assurance{" "}
                            </option>
                            <option value="Sales">Sales</option>
                            <option value="Operations">Operations</option>
                            <option value="Supply Chain">Supply Chain</option>
                          </select>
                          <p className="err">{formError.desired_department}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Highest level of education*"
                              name="highest_education"
                              value={values.highest_education}
                              onChange={handleChange}
                            />
                            <label for="name">
                              Highest level of education*
                            </label>
                            <p className="err">{formError.highest_education}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Message*"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                            />
                            <label for="name">Message*</label>
                            <p className="err">{formError.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <select
                            className="input_field select_field"
                            name="preferred_location"
                            value={values.preferred_location}
                            onChange={(e)=>{
                              setValues((prevState) => {
                                return {
                                  ...prevState,
                                  admin_email: e.target[e.target.selectedIndex].getAttribute('data-email'),
                                };
                              });
                              handleChange(e);
                            }}
                          >
                            <option value="" disabled selected>
                              Preferred location*
                            </option>
                            {countries?.map((item) => {
                              return (
                                <option
                                  value={item.title}
                                  data-email={item.admin_email}
                                >
                                  {item.title}
                                </option>
                              );
                            })}
                            {/* <option value="India">India</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option> */}
                          </select>
                          <p className="err">{formError.preferred_location}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={uploadResume}
                              alt="resume"
                              className="resume_icon"
                            />
                            <p className="drop_text">Drop Your CV Here*</p>
                            <p className="reply_text">
                              We will consider your profile for future jobs
                            </p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile"
                            className="input_field"
                            name="resume"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                              <span className="upload_text">
                                Drag File to Upload OR
                              </span>
                            ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">File Size: Less than 2 MB</p>
                          </div>
                          <p className="file_name">{values?.resume?.name}</p>
                          <p className="err">{formError.resume}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={addDoc}
                              alt="resume"
                              className="resume_icon add_icon"
                            />
                            <p className="drop_text">Add other DOC</p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile4"
                            className="input_field"
                            name="document"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile4" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                              <span className="upload_text">
                                Drag File to Upload OR
                              </span>
                            ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC /PNG
                            </p>
                            <p className="format">File Size: Less than 2 MB</p>
                          </div>
                          <p className="file_name">{values?.document?.name}</p>
                          <p className="err">{formError.document}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button className="career_cta">
                      {loading ? "Loading.." : "SUBMIT"}
                    </button>
                  </div>
                  {isThankyouVisible && (
                    <div className="sucess_msg_wrapper">
                      <img
                        src={successIcon}
                        alt="success"
                        className="success_img"
                      />
                      <p className="success_text">
                        Your response has been submitted
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="row form_main_row">
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Current/last position*"
                              name="current_position"
                              value={values.current_position}
                              onChange={handleChange}
                            />
                            <label for="name">Current/last position*</label>
                            <p className="err">{formError.current_position}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <select
                            className="input_field select_field"
                            name="preferred_location"
                            value={values.preferred_location}
                            onChange={(e)=>{
                              setValues((prevState) => {
                                return {
                                  ...prevState,
                                  admin_email: e.target[e.target.selectedIndex].getAttribute('data-email'),
                                };
                              });
                              handleChange(e);
                            }}
                          >
                           <option value="" disabled selected>
                              Preferred location*
                            </option>
                            {countries?.map((item) => {
                              return (
                                <option
                                  value={item.title}
                                  data-email={item.admin_email}
                                >
                                  {item.title}
                                </option>
                              );
                            })}
                          </select>
                          <p className="err">{formError.preferred_location}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="country_drop_wrap">
                          <div className="input_wrapper">
                            <select
                              className="input_field select_field"
                              name="country_code"
                              value={values.country_code}
                              onChange={handleChange}
                            >
                             {codes.map((item, i) => {
                                return (
                                  <option
                                    value={`+${item}`}
                                  >{`+${item}`}</option>
                                );
                              })}
                            </select>
                            <p className="err"></p>
                          </div>

                          <div className="input_wrapper">
                            <div class="form-floating">
                              <input
                                type="tel"
                                className="form-control input_field"
                                placeholder="Phone No.*"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <label for="name">Phone No.*</label>
                              <p className="err">{formError.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <select
                            className="input_field select_field"
                            name="desired_department"
                            value={values.desired_department}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Desired Department you would like to apply for*
                            </option>
                            <option value="3PL">3PL</option>
                            <option value="Admin">Admin</option>
                            <option value="Finance">Finance</option>
                            <option value="HR">HR</option>
                            <option value="Import">Import</option>
                            <option value="IT">IT</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Purchase">Purchase</option>
                            <option value="Quality Assurance ">
                              Quality Assurance{" "}
                            </option>
                            <option value="Sales">Sales</option>
                            <option value="Operations">Operations</option>
                            <option value="Supply Chain">Supply Chain</option>
                          </select>
                          <p className="err">{formError.desired_department}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Highest level of education*"
                              name="highest_education"
                              value={values.highest_education}
                              onChange={handleChange}
                            />
                            <label for="name">
                              Highest level of education*
                            </label>
                            <p className="err">{formError.highest_education}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control input_field"
                              placeholder="Message*"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                            />
                            <label for="name">Message*</label>
                            <p className="err">{formError.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form_main_col">
                    <div className="row form_row">
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={uploadResume}
                              alt="resume"
                              className="resume_icon"
                            />
                            <p className="drop_text">Drop Your CV Here*</p>
                            <p className="reply_text">
                              We will consider your profile for future jobs
                            </p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile5"
                            className="input_field"
                            name="resume"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile5" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                              <span className="upload_text">
                                Drag File to Upload OR
                              </span>
                            ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC /PNG
                            </p>
                            <p className="format">File Size: Less than 2 MB</p>
                          </div>
                          <p className="file_name">{values?.resume?.name}</p>
                          <p className="err">{formError.resume}</p>
                        </div>
                      </div>
                      <div className="col-md-12 form_col">
                        <div className="input_wrapper">
                          <div className="upload_text_wrapper">
                            <img
                              src={addDoc}
                              alt="resume"
                              className="resume_icon add_icon"
                            />
                            <p className="drop_text">Add other DOC</p>
                          </div>
                          <input
                            type="file"
                            id="uploadFile6"
                            className="input_field"
                            name="document"
                            onChange={handleChange}
                          />
                          <label htmlFor="uploadFile6" className="upload_label">
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              className="upload_img"
                            />
                            {/* {width > 767 ? (
                              <span className="upload_text">
                                Drag File to Upload OR
                              </span>
                            ) : null} */}
                            <span className="file_text">Choose File</span>
                          </label>
                          <div className="up_bot_txt_wrapper">
                            <p className="format">
                              File Format : JPG / PDF / DOC / PNG
                            </p>
                            <p className="format">File Size: Less than 2 MB</p>
                          </div>
                          <p className="file_name">{values?.document?.name}</p>
                          <p className="err">{formError.document}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button className="career_cta">
                      {loading ? "Loading.." : "SUBMIT"}
                    </button>
                  </div>
                  {isThankyouVisible && (
                    <div className="sucess_msg_wrapper">
                      <img
                        src={successIcon}
                        alt="success"
                        className="success_img"
                      />
                      <p className="success_text">
                        Your response has been submitted
                      </p>
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </section>
      </main>
      {/* <ApplyStickyCta /> */}
    </>
  );
};

export default CareersDetail;
