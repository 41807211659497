import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const GetInTouch = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [sectwotitle, setsectwotitle] = useState("");
  const [sectwotitleerr, setsectwotitleerr] = useState("");

  const [sectwodesc, setsectwodesc] = useState("");
  const [sectwodescerr, setsectwodescerr] = useState("");

  const [getintouchtitle, setgetintouchtitle] = useState("");
  const [getintouchtitleerr, setgetintouchtitleerr] = useState("");

  const [address, setaddress] = useState("");
  const [addresserr, setaddresserr] = useState("");

  const [maplink, setmaplink] = useState("");
  const [maplinkerr, setmaplinkerr] = useState("");

  const [telephone, settelephone] = useState("");
  const [telephoneerr, settelephoneerr] = useState("");

  const [querydesc, setquerydesc] = useState("");
  const [querydescerr, setquerydescerr] = useState("");

  const [mail, setmail] = useState("");
  const [mailerr, setmailerr] = useState("");

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Contact details added Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Our Presence deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };
  useEffect(() => {
    setLoading(true);
    let url = new URL(urldata + "admin/getcontactus");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        const data = response.data.data[0];
        setsectwotitle(data.title ? data.title : "");
        setsectwodesc(data.description ? data.description : "");
        setmaplink(data.maplink ? data.maplink : "");
        settelephone(data.mobile ? data.mobile : "");
        setaddress(data.address ? data.address : "");
        setquerydesc(data.queries ? data.queries : "");
        setmail(data.email ? data.email : "");

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        // showToast("danger");
      });
  }, []);

  const addContactDetails = () => {
    let data = {};
    data.title = sectwotitle;
    data.description = sectwodesc;
    data.address = address;
    data.maplink = maplink;
    data.mobile = telephone;
    data.queries = querydesc;
    data.email = mail;

    let url = new URL(urldata + "admin/addcontactus");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        // const tempList = response.data.data.reverse();
        // setList(tempList);
        // setShow(false);
        setLoading(false);
        showToast("success");
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
        showToast("danger");
      });
  };

  const submit = () => {
    let formIsValid = true;

    if (!sectwotitle) {
      formIsValid = false;
      setsectwotitleerr("*Please add title");
    } else {
      setsectwotitleerr("");
    }
    if (!sectwodesc) {
      formIsValid = false;
      setsectwodescerr("*Please add Description");
    } else {
      setsectwodescerr("");
    }
    if (!address) {
      formIsValid = false;
      setaddresserr("*Please add address");
    } else {
      setaddresserr("");
    }
    if (!maplink) {
      formIsValid = false;
      setmaplinkerr("*Please add map link");
    } else {
      setmaplinkerr("");
    }
    if (!telephone) {
      formIsValid = false;
      settelephoneerr("*Please add Telephone number");
    } else {
      settelephoneerr("");
    }
    if (!querydesc) {
      formIsValid = false;
      setquerydescerr("*Please add queries description");
    } else {
      setquerydescerr("");
    }
    if (!mail) {
      formIsValid = false;
      setmailerr("*Please add Mail");
    } else {
      setmailerr("");
    }

    if (formIsValid) {
      addContactDetails();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Get In Touch Section</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        <Col className="column" lg={3}>
          <div className="title">Contact Us Section Two Title:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={sectwotitle}
            onChange={(e) => setsectwotitle(e.target.value)}
            type="text"
          />
          <h4 className="err">{sectwotitleerr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Contact Us Section Two Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          {/* <input
            value={sectwodesc}
            onChange={(e) => setsectwodesc(e.target.value)}
            type="text"
          /> */}
          <ReactQuill
            value={sectwodesc}
            modules={modules}
            onChange={(e) => {
              setsectwodesc(e);
            }}
          />
          <h4 className="err">{sectwodescerr}</h4>
        </Col>

        {/*         
        <Col className="column" lg={3}>
          <div className="title">Get in touch title:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={getintouchtitle}
            onChange={(e) => setgetintouchtitle(e.target.value)}
            type="text"
          />
          <h4 className="err">{getintouchtitleerr}</h4>
        </Col> */}

        <Col className="column" lg={3}>
          <div className="title">Company Address:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={address}
            onChange={(e) => setaddress(e.target.value)}
            type="text"
          />
          <h4 className="err">{addresserr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Map Link:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={maplink}
            onChange={(e) => setmaplink(e.target.value)}
            type="text"
          />
          <h4 className="err">{maplinkerr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Telephone No.:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={telephone}
            onChange={(e) => settelephone(e.target.value)}
            type="tel"
          />
          <h4 className="err">{telephoneerr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Query Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={querydesc}
            onChange={(e) => setquerydesc(e.target.value)}
            type="text"
          />
          <h4 className="err">{querydescerr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Email:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={mail}
            onChange={(e) => setmail(e.target.value)}
            type="email"
          />
          <h4 className="err">{mailerr}</h4>
        </Col>

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
      </Row>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default GetInTouch;
