import React, { useEffect } from "react";
import "./style.less";
import WOW from "wowjs";

const BannerItem = (props) => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className="BannerItemContainer">
      <img src={props.img} className="img-fluid banner_img" />
      <div className="my_container custom_container">
        <div
          className={`BannerContainer ${
            props.className !== "" && props.className
          }`}
        >
          <h1
            style={props.color ? { color: props.color } : { color: "white" }}
            className="title"
            dangerouslySetInnerHTML={{
              __html: props.title.replace(/(<? *script)/gi, "illegalscript"),
            }}
          />
          {props.desc ? (
            <div className="bannerdesc">
              <div
                // id='ppintro'
                dangerouslySetInnerHTML={{
                  __html: props.desc.replace(/(<? *script)/gi, "illegalscript"),
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerItem;
