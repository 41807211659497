import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import http from "../../../../helpers/http";

import urldata from "../../../../urldata";
import "./style.css";

function Index() {
  const { confirm } = Modal;
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);

  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(Math.random() * 10);
  const [editKey, seteditKey] = useState(Math.random() * 10);
  const [datas, setDatas] = useState([]);

  const moduleName = "Product Brand";
  const moduleNamePural = "Product Brands";
  const base_url = urldata + "admin/products/brands"; //without trailing slash
  const image_url = urldata + "public/uploads/product/"; //with trailing slash

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + Math.random() * 2;
      });
      setData(data);

      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });
    setCreateOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });

    setCreateOpen(false);
    fetchDatas();
  };

  // const handleStatus = async (value, id) => {
  //   const status = value ? 1 : 0;

  //   const result = await http.put(`${base_url}/status/${id}`, {
  //     status: status,
  //   });

  //   if (result) {
  //     notification["success"]({
  //       message: "Status Changed Successfully",
  //     });
  //   }
  // };

  // const handleFeatured = async (value, id) => {
  //   const is_featured = value ? 1 : 0;

  //   const result = await http.put(`${base_url}/featured/${id}`, {
  //     is_featured: is_featured,
  //   });

  //   if (result) {
  //     notification["success"]({
  //       message: "Featured Status Changed Successfully",
  //     });
  //   }
  // };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure you want to delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  // const fetchCategory = async () => {
  //   const data = await http.get(urldata + "admin/products/categories");

  //   if (data) {
  //     setCategories(data.data);
  //   }
  // };

  // const fetchBrand = async () => {
  //   const data = await http.get(urldata + "admin/products/brands");

  //   if (data) {
  //     setBrands(data.data);
  //   }
  // };

  useEffect(() => {
    fetchDatas();
    // fetchCategory();
    // fetchBrand();
  }, []);

  const columns = [
    // {
    //   title: "Image",

    //   key: "image",
    //   render: (text, record) => (
    //     <Image
    //       width={100}
    //       src={image_url + record.image}
    //       fallback="https://via.placeholder.com/100"
    //     />
    //   ),
    // },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Switch
    //         checkedChildren="On"
    //         unCheckedChildren="Off"
    //         onChange={(value) => {
    //           handleStatus(value, record._id);
    //         }}
    //         defaultChecked={record.status}
    //       />
    //     </Space>
    //   ),
    // },
    // {
    //   title: "Featured",
    //   key: "featured",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Switch
    //         checkedChildren="Yes"
    //         unCheckedChildren="No"
    //         onChange={(value) => {
    //           handleFeatured(value, record._id);
    //         }}
    //         defaultChecked={record.is_featured}
    //       />
    //     </Space>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="danger"
            onClick={() => {
              handleEdit(record._id);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={22}>
                  <Space>
                    <Button type="danger" onClick={handleCreate}>
                      Add {moduleName}
                    </Button>
                  </Space>
                </Col>
                {/* <Col span={2}>
                  <Space>
                    <Button type="danger" onClick={handleCreate}>
                      Export
                    </Button>
                  </Space>
                </Col> */}
              </Row>
              {/* <ExcelFile element={<button>Download Data</button>}>
                <ExcelSheet dataSet={multiDataSet} name="Organization" />
              </ExcelFile> */}
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
              />

              <Edit
                key={"edit" + editKey}
                isVisible={editOpen}
                handleClose={handleEditClose}
                dbData={data}
                moduleName={moduleName}
              />
              <Create
                key={"create" + createKey}
                isVisible={createOpen}
                handleClose={handleCreateClose}
                moduleName={moduleName}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
