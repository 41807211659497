import React, { useState, useEffect } from "react";
import BannerCommon from "../BannerCommon";

const CareerBanner = () => {
    return(
      <BannerCommon header='Career' screenQuery='careers'/>
    )
}

export default CareerBanner
