import axios from "axios";
import urldata from "../urldata";

const client = axios.create({ baseURL: urldata });

export const request = ({ ...options }) => {
  const onSuccess = (response) => response;
  const onError = (error) => {
    // optionaly catch errors and add additional logging here
    alert(`Something went wrong:- ${error.message}`);
    console.log("error==>", error);
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};
