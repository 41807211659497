import React, { createContext, useState, useRef } from "react";

export const CatalogueContext = createContext();

const CatalogueContextProvider = (props) => {
  const [isFooterInViewPort, setIsFooterInViewPort] = useState(false);

  return (
    <CatalogueContext.Provider
      value={{ isFooterInViewPort, setIsFooterInViewPort }}
    >
      {props.children}
    </CatalogueContext.Provider>
  );
};

export default CatalogueContextProvider;
