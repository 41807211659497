import React, { useState, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import { Markup } from "interweave";
import WOW from "wowjs";
import "react-quill/dist/quill.snow.css";
import { CSSTransition } from "react-transition-group";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useWindowDimensions from "../../useWindowDimensions";
import "swiper/swiper-bundle.css";
import uniqid from "uniqid";

import "./style.less";

import BannerItem from "../../component/BannerItem/BannerItem";
import Button from "../../component/Button/Button";
import OurInHousePartnerBrandsProductsSlider from "../../component/OurInHousePartnerBrandsProductsSlider/OurInHousePartnerBrandsProductsSlider";

import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
} from "swiper";

import banner1 from "../../images/ourbrands/brands-banner-2560.jpg";
import mbanner1 from "../../images/ourbrands/brandsbanner-640.jpg";
import redBackArrow from "../../images/ourbrands/redBackArrow.svg";
import redNextArrow from "../../images/ourbrands/redNextArrow.svg";
import ITCLogo from "../../images/ourbrands/ITCLogo.png";
import AashirvaadLogo from "../../images/ourbrands/AashirvaadLogo.png";
import ParleLogo from "../../images/ourbrands/ParleLogo.png";
//import ParleAgroLogo from "../../images/ourbrands/ParleAgroLogo.png";
import RoohAfzaLogo from "../../images/ourbrands/RoohAfzaLogo.png";
import laxmilogo from "../../images/ourbrands/laxmilogo.png";
import meharbanlogo from "../../images/ourbrands/meharbanlogo.png";
import maazalogo from "../../images/ourbrands/maazalogo.png";
import ammaslogo from "../../images/ourbrands/ammaslogo.png";
import garvigujaratlogo from "../../images/ourbrands/garvigujaratlogo.png";
import ourinhousebrandsproduct1 from "../../images/ourbrands/BlackPepperPacket.png";
import ourinhousebrandsproduct2 from "../../images/ourbrands/PlantainChipsGarlicPacket.png";
import ourinhousebrandsproduct3 from "../../images/ourbrands/AlmondsPacket.png";
import maggie from "../../images/homepage/maggie.png";
import mazza from "../../images/homepage/mazza.png";
import cofresh from "../../images/homepage/cofresh.png";
import colonelkababz from "../../images/homepage/colonelkababz.png";
import frooti from "../../images/homepage/frooti.png";
import dailydelight from "../../images/homepage/dailydelight.png";
import gits from "../../images/homepage/gits.png";
import hamdard from "../../images/homepage/hamdard.png";
import jalpur from "../../images/homepage/jalpur.png";
import kawan from "../../images/homepage/kawan.png";
import royalmahout from "../../images/homepage/royalmahout.png";
import sumeru from "../../images/homepage/sumeru.png";

import LaxmiProduct1 from "../../images/ourbrands/Productshots/Laxmi/CorianderPowder200g.png";
import LaxmiProduct2 from "../../images/ourbrands/Productshots/Laxmi/Extralongbasmatirice10LB.png";
import LaxmiProduct3 from "../../images/ourbrands/Productshots/Laxmi/GGP-8OZ.png";
import LaxmiProduct4 from "../../images/ourbrands/Productshots/Laxmi/LAXMIFRESHLYMILLEDBESAN16X2LB.png";
import LaxmiProduct5 from "../../images/ourbrands/Productshots/Laxmi/LAXMISHARBATICHAPATIFLOUR4X10LB.png";
import LaxmiProduct6 from "../../images/ourbrands/Productshots/Laxmi/LAXMISONAMASOORI4X10LB.png";
import LaxmiProduct7 from "../../images/ourbrands/Productshots/Laxmi/RedChilliPowder-200g.png";
import LaxmiProduct8 from "../../images/ourbrands/Productshots/Laxmi/TurmericPowder-200g.png";

import AmmaProduct1 from "../../images/ourbrands/Productshots/Amma/BananaChips-400g.png";
import AmmaProduct2 from "../../images/ourbrands/Productshots/Amma/Banana-chips-four-cut-400g.png";
import AmmaProduct3 from "../../images/ourbrands/Productshots/Amma/Banana-Chips-HOT-pepper-908g.png";
import AmmaProduct4 from "../../images/ourbrands/Productshots/Amma/Banana-chips-sweet-400g.png";
import AmmaProduct5 from "../../images/ourbrands/Productshots/Amma/Banana-with-Jagery-400g.png";
import AmmaProduct6 from "../../images/ourbrands/Productshots/Amma/Kerala-mixture-hot-400g.png";
import AmmaProduct7 from "../../images/ourbrands/Productshots/Amma/Madras-Murukku-200g.png";
import AmmaProduct8 from "../../images/ourbrands/Productshots/Amma/Tapioca-Chips-HOT-200g.png";

import MeharbanProduct1 from "../../images/ourbrands/Productshots/Meharban/AjwaDatesPack-400g.png";
import MeharbanProduct2 from "../../images/ourbrands/Productshots/Meharban/MeharbanBesan-4lb.png";
import MeharbanProduct3 from "../../images/ourbrands/Productshots/Meharban/Meharbandates-1.png";
import MeharbanProduct4 from "../../images/ourbrands/Productshots/Meharban/Meharban1Ltrbottle.png";

import maazaProduct1 from "../../images/ourbrands/Productshots/Maaza/MaazaGuava330ml.png";
import maazaProduct2 from "../../images/ourbrands/Productshots/Maaza/MaazaLychee330ml.png";
import maazaProduct3 from "../../images/ourbrands/Productshots/Maaza/MaazaMango1Ltr.png";
import maazaProduct4 from "../../images/ourbrands/Productshots/Maaza/MaazaMango330ml.png";

import GarviProduct1 from "../../images/ourbrands/Productshots/Garvi/PGGSB4Garvigujarat_BhelSev_285g.png";
import GarviProduct2 from "../../images/ourbrands/Productshots/Garvi/PGGSBLGarvigujarat_BhelMix_285g.png";
import GarviProduct3 from "../../images/ourbrands/Productshots/Garvi/PGGSBO1Garvigujarat_Boondi_285g.png";
import GarviProduct4 from "../../images/ourbrands/Productshots/Garvi/PGGSC1Garvigujarat_GujaratiChakri_285g.png";
import GarviProduct5 from "../../images/ourbrands/Productshots/Garvi/PGGSKGarvigujarat_DryKachori_285g.png";
import GarviProduct6 from "../../images/ourbrands/Productshots/Garvi/POGGSB1Garvigujarat_BhavnagriGathiya_285g.png";

import axios from "axios";
import urldata from "../../urldata";
import loadergif from "../../images/loader.gif";
import Imageurldata from "../../Imageurldata";

Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectFade]);

// const temp2 = [
//   "1",
//   "2",
//   "3",
//   "4",
//   "1",
//   "2",
//   "3",
//   "4",
//   "1",
//   "2",
//   "3",
//   "4",
//   "1",
//   "2",
//   "3",
//   "4",
// ];

const OurInHousePartnerBrandsArr333 = [
  {
    img: laxmilogo,
    brandname: "Laxmi",
    branddesc:
      "Laxmi, a trusted brand since 1972, offers a flavorful mix of authentic South Asian cooking ingredients including spices, rice, dals, flours, ghee and more.",
    brandlink: "http://laxmihos.com/",
    productArray: [
      {
        img: LaxmiProduct1,
      },
      {
        img: LaxmiProduct2,
      },
      {
        img: LaxmiProduct3,
      },
      {
        img: LaxmiProduct4,
      },
      {
        img: LaxmiProduct5,
      },
      {
        img: LaxmiProduct6,
      },
      {
        img: LaxmiProduct7,
      },
      {
        img: LaxmiProduct8,
      },
    ],
  },
  {
    img: maazalogo,
    brandname: "Maaza",
    branddesc:
      "Maaza, an authentic South Asian beverage brand, refreshes and quenches your inner thirst with its wide variety of juices and drinks.",
    brandlink: "http://maazahos.com/",
    productArray: [
      {
        img: maazaProduct1,
      },
      {
        img: maazaProduct2,
      },
      {
        img: maazaProduct3,
      },
      {
        img: maazaProduct4,
      },
    ],
  },
  {
    img: meharbanlogo,
    brandname: "Meharban",
    branddesc:
      "Meheraban, the latest addition to our brand portfolio, brings you cherished foods from the Middle East, Pakistan & Bangladesh.",
    brandlink: "http://meharbanhos.com/",
    productArray: [
      {
        img: MeharbanProduct1,
      },
      {
        img: MeharbanProduct2,
      },
      {
        img: MeharbanProduct3,
      },
      {
        img: MeharbanProduct4,
      },
    ],
  },
  {
    img: ammaslogo,
    brandname: "Amma's Kitchen",
    branddesc:
      "Amma’s Kitchen brings you the sacred taste of South Indian snacks and revives the memories from back home with its wide array of delicious traditional products.",
    brandlink: "http://ammaskitchenhos.com/",
    productArray: [
      {
        img: AmmaProduct1,
      },
      {
        img: AmmaProduct2,
      },
      {
        img: AmmaProduct3,
      },
      {
        img: AmmaProduct4,
      },
      {
        img: AmmaProduct5,
      },
      {
        img: AmmaProduct6,
      },
      {
        img: AmmaProduct7,
      },
      {
        img: AmmaProduct8,
      },
    ],
  },
  {
    img: garvigujaratlogo,
    brandname: "Garvi Gujarat",
    branddesc:
      "Straight from the heart of Gujarat, Garvi Gujarat brings you all-time snack favorites and frozen foods including Khakara, Chivda, Khaman Dhokla, Undhiyu and more.",
    brandlink: "http://garvigujarathos.com/",
    productArray: [
      {
        img: GarviProduct1,
      },
      {
        img: GarviProduct2,
      },
      {
        img: GarviProduct3,
      },
      {
        img: GarviProduct4,
      },
      {
        img: GarviProduct5,
      },
      {
        img: GarviProduct6,
      },
    ],
  },
];

// const OurPartnerBrandsArr = [
//   { img: ITCLogo },
//   { img: AashirvaadLogo },

//   { img: ParleLogo },
//   { img: maggie },
//   { img: RoohAfzaLogo },
//   // { img: mazza },
//   { img: cofresh },
//   { img: colonelkababz },
//   { img: frooti },
//   { img: dailydelight },
//   { img: gits },
//   { img: hamdard },
//   { img: jalpur },
//   { img: kawan },
//   { img: royalmahout },
//   { img: sumeru },
// ];

const OurBrands = (props) => {
  const { height, width } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const [temparr, setTemparr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ourbrandsBanner, setourbrandsBanner] = useState([]);
  const [ourinhousebrandsicons, setourinhousebrandsicons] = useState([]);
  const [ourinhousebrandscontent, setourinhousebrandscontent] = useState([]);
  const [ourpartnerbrandsicons, setourpartnerbrandsicons] = useState([]);
  const [inHouseBrand, setInHouseBrand] = useState("");
  const [ourPartnerBrand, setOurPartnerBrand] = useState("");

  // console.log("temparr---->", temparr);
  // console.log(inHouseBrand);
  // console.log(ourPartnerBrand);
  // const OurInHousePartnerBrandsList1 = OurInHousePartnerBrandsArr.map(
  //   (item, i = 1) => (
  //     <div className="BrandDetails" key={i}>
  //       <div className="BrandName">{item.brandname}</div>
  //       <div className="BrandDesc">{item.branddesc}</div>
  //     </div>
  //   )
  // );

  // const OurInHousePartnerBrandsList2 = OurInHousePartnerBrandsArr[
  //   index
  // ].productArray.map((item, i = 1) => (
  //   <div key={i} className="swiper-slide">
  //     <div className="OurInHousePartnerBrandsProductsImg">
  //       <img className="img-fluid" src={item.img} />
  //     </div>
  //   </div>
  // ));

  useEffect(() => {
    try {
      console.log([...ourinhousebrandscontent[index].data]);
      setTemparr([]);
      setTimeout(() => {
        setTemparr([...ourinhousebrandscontent[index].data]);
      }, 100);
    } catch (error) {}

    setTemparr([]);

    // console.log('index---->', index);
    // console.log('abcedf---->', ourinhousebrandscontent.length);
    // if (ourinhousebrandscontent.length >= 1) {
    //   console.log([...ourinhousebrandscontent[index].data]);
    //   setTemparr([...ourinhousebrandscontent[index].data]);
    // }
  }, [index]);

  useEffect(() => {
    window.scroll(0, 0);

    let url = new URL(urldata + "user/getOurBrandPageData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);

        const ourbrandsdata = response.data.data;
        setourbrandsBanner(ourbrandsdata.bannerData);
        setourinhousebrandsicons(ourbrandsdata.ourBrands);
        setourinhousebrandscontent(ourbrandsdata.ourBrands);
        setourpartnerbrandsicons(ourbrandsdata.ourPartnerBrandsImages);
        setIndex(0);
        setTemparr(ourbrandsdata.ourBrands[0].data);
        // console.log("=========", ourbrandsdata.ourBrands[0].data);
        setLoading(false);

        const swiper2 = new Swiper(".homesec5-swiper", {
          slidesPerView: 5,
          spaceBetween: 80,
          loop: true,
          navigation: {
            nextEl: ".ourbrandssec4 .swiper-button-next",
            prevEl: ".ourbrandssec4 .swiper-button-prev",
          },
        });

        const swiper3 = new Swiper(".mbhomesec5-swiper", {
          slidesPerView: 2.5,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
          navigation: {
            nextEl: ".ourbrandssec4 .swiper-button-next",
            prevEl: ".ourbrandssec4 .swiper-button-prev",
          },
        });
      })
      .catch((error) => {
        //alert("Something Went Wrong");
      });
    let urlInHouseBrand = new URL(urldata + "user/getBrandInhouseData");
    let ourPartnerBrandUrl = new URL(urldata + "user/getOurBrandDescData");

    axios({
      method: "get",
      url: urlInHouseBrand,
      withCredentials: true,
    })
      .then((response) => {
        // console.log('in house', response)
        setInHouseBrand(response.data.data.brandinhouse[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    axios({
      method: "get",
      url: ourPartnerBrandUrl,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(' our brands - our partner', response)
        setOurPartnerBrand(response.data.data.brandinhouse[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    document.title = "Our Brands - In-house Brands";
    document.getElementsByTagName("META")[3].content =
      "From Authentic cooking ingredients to regional snacks and drinks, we have a combination of premium quality brands";
    document.getElementsByTagName("META")[4].content =
      "HOS Global Foods Brands, Laxmi, Meheraban, Amma’s Kitchen, Garvi Gujarat";

    new WOW.WOW({
      live: false,
    }).init();

    // const swiper4 = new Swiper(".OurInHousePartnerBrandsProductsSwiper", {
    //   slidesPerView: 3,
    //   spaceBetween: 0,
    //   loop: true,
    //   navigation: {
    //     nextEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-next",
    //     prevEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-prev",
    //   },
    // });
  }, []);

  const ourPartnerBrandList = ourpartnerbrandsicons.map((item, i) => (
    <div key={i} className="swiper-slide">
      <div className="swiperSlideContainer">
        {/* <div>{item.title}</div> */}
        <img src={Imageurldata + item.imgUrl} className="img-fluid" />
      </div>
    </div>
  ));

  const OurInHousePartnerBrandsList = ourinhousebrandsicons.map((item, i) => (
    <div
      key={i}
      className="inHouseBrandsTab"
      style={index === i ? { opacity: "1" } : null}
    >
      <div key={i} onClick={() => setIndex(i)} className="inHouseBrandsTabImg">
        <img className="img-fluid" src={Imageurldata + item.logo} />
      </div>
    </div>
  ));

  if (loading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }

  return (
    <>
      <section className="ourbrandssec1 wow fadeIn">
        <BannerItem
          color="black"
          img={
            width > 767
              ? Imageurldata + ourbrandsBanner[0].imgUrl
              : Imageurldata + ourbrandsBanner[0].mbimgUrl
          }
          title={ourbrandsBanner[0].title}
          desc=""
        />
      </section>

      <section className="ourbrandssec2 wow fadeIn">
        <div className="title">IN-HOUSE BRANDS</div>
        <div className="desc">
          {inHouseBrand.desc ? (
            <div
              dangerouslySetInnerHTML={{
                __html: inHouseBrand.desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          ) : (
            ""
          )}
          {/* From Authentic cooking ingredients to regional snacks and drinks, we
          have a combination of premium quality brands to suit your taste for
          household products. */}
        </div>
        <div className="inHouseBrandsTabsDiv">
          <div className="inHouseBrandsTabs">
            <div className="inHouseBrandsTabsInner">
              {OurInHousePartnerBrandsList}
            </div>
          </div>

          <div className="inHouseBrandsTabsContents">
            <div className="inHouseBrandsTabsContent">
              <CSSTransition
                in={index}
                timeout={300}
                classNames="fade"
                // unmountOnExit
              >
                <Row className="row m-0 a-c">
                  <Col lg={5}>
                    <div className="BrandDetails">
                      <div className="BrandName">
                        {ourinhousebrandscontent[index].title}
                      </div>
                      <div className="BrandDesc">
                        {ourinhousebrandscontent[index].desc}
                      </div>
                      {ourinhousebrandscontent[index].link && (
                        <div className="BrandLink">
                          <a
                            className="c-p"
                            href={ourinhousebrandscontent[index].link}
                            target="_blank"
                          >
                            Know More
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={7}>
                    {/* <OurInHousePartnerBrandsProductsSlider
                    ourinhousebrandscontent={...ourinhousebrandscontent[index].productArray
                    }
                  /> */}

                    {/* <OurInHousePartnerBrandsProductsSlider
                      OurInHousePartnerBrandsArr={[
                        ...ourinhousebrandscontent[index].data,
                      ]}
                    /> */}

                    <OurInHousePartnerBrandsProductsSlider
                      OurInHousePartnerBrandsArr={temparr}
                    />
                  </Col>
                </Row>
              </CSSTransition>
            </div>
          </div>
        </div>
      </section>

      <section className="ourbrandssec3 wow fadeIn">
        <div className="title">OUR PARTNER BRANDS</div>
        <div className="desc">
          {/* ************CHANGE***************** */}
          {/* HOS is the largest distributor to an extensive range of brands from
          South Asian Regions that offer an array of specialty foods from back
          home. */}
          {ourPartnerBrand.desc ? (
            <div
              dangerouslySetInnerHTML={{
                __html: ourPartnerBrand.desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </section>

      <section className="ourbrandssec4 wow fadeIn">
        <Row className="ourBrandsRow">
          <Col md={1} xs={1} lg={1} className="d-f j-c a-c">
            <img src={redBackArrow} className="prev icon swiper-button-prev" />
          </Col>
          <Col md={10} xs={10} lg={10}>
            <div
              className={
                width >= 1024
                  ? "homesec5-swiper swiper-container"
                  : "mbhomesec5-swiper swiper-container"
              }
            >
              <div className="swiper-wrapper">{ourPartnerBrandList}</div>
            </div>
          </Col>
          <Col className="p-0 d-f j-c a-c" md={1} xs={1} lg={1}>
            <img src={redNextArrow} className="next icon swiper-button-next" />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OurBrands;
