import React from "react";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import "./style.less";

const CounterContainer = (props) => {
  return (
    <div className="d-f f-c j-c a-c counterContainer">
      <img src={props.img} />
      <CountUp end={parseInt(props.count)} duration="3">
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <>
              <div className="count">
                <span ref={countUpRef} />
                <span>{props.span}</span>
              </div>
            </>
          </VisibilitySensor>
        )}
      </CountUp>

      <div className="countertitle">{props.title}</div>
    </div>
  );
};

export default CounterContainer;
