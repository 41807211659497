import React, { useState, useEffect } from "react";
import BannerCommon from "../BannerCommon";

const CollaborateBanner = () => {
    return(
        <BannerCommon header='Collaborator' screenQuery='collaborate'/>
    )
}

export default CollaborateBanner
