import React, { useRef } from "react";
import "./applyStickyCta.less";
import { Link } from "react-router-dom";
import { applyIcon } from "../../images";
import { careerListingURL } from "../../helpers/paths";

const ApplyStickyCta = () => {
  return (
    <>
      <div className="sticky_wrapper">
        <Link to={careerListingURL} className="sticky_text">
          <img src={applyIcon} alt="apply" className="apply_icon" />
          <span className="title">APPLY FOR JOB</span>
        </Link>
      </div>
    </>
  );
};

export default ApplyStickyCta;
