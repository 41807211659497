import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const PolicyDetails = () => {
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [text1, setText1] = useState("");
  const [text1Err, setText1Err] = useState("");

  const [contactDesc, setContactDesc] = useState("");
  const [contactDescErr, setContactDescErr] = useState("");

  const [toastlist, setToastList] = useState([]);
  let toastProperties = null;

  // var toolbarOptions = {
  //   handlers: {
  //     // ...
  //     'hr': function(value) {
  //        this.quill.format('hr', true);
  //     }
  //   }
  // }


  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      // ["clean"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Policy Details Saved Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    let url = new URL(urldata + "admin/getprivacypolicy");
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response);
        let data = response.data.data[0];
        // console.log(data);
        setTitle(data.contacttitle ? data.contacttitle : "");
        setText1(data.introduction ? data.introduction : "");
        setContactDesc(data.contactdesc ? data.contactdesc : "");
        setLoading(false);
      })
      .catch((error) => {
        // console.log('error:--',error);
        setLoading(false);
        //showToast("danger");
      });
  }, []);

  const addBannerData = () => {
    //   let formData = new FormData();

    //   formData.append("title", title);
    //   formData.append("text1", text1);

    let data = {};
    data.introduction = text1;
    data.contacttitle = title;
    data.contactdesc = contactDesc;
    let url = new URL(urldata + "admin/addprivacypolicy");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   console.log(response);
        setLoading(false);
        showToast("success");
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title) {
      formValid = false;
      setTitleErr("*Please add Contact Title");
    } else {
      setTitleErr("");
    }

    if (!text1) {
      formValid = false;
      setText1Err("*Please add Policy Details");
    } else {
      setText1Err("");
    }
    if (!contactDesc) {
      formValid = false;
      setContactDescErr("*Please add Contact Description");
    } else {
      setContactDescErr("");
    }

    if (formValid) {
      addBannerData();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Privacy Policy Banner</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        <Col className="column" lg={3}>
          <div className="title">Policy Details:-</div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={text1}
            modules={modules}
            formats={formats}
            onChange={(e) => {
              setText1(e);
            }}
          />
          <h4 className="err">{text1Err}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Contact Title:- </div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          />
          <h4 className="err">{titleErr}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Contact Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={contactDesc}
            modules={modules}
            formats={formats}
            onChange={(e) => {
              setContactDesc(e);
            }}
          />
          <h4 className="err">{contactDescErr}</h4>
        </Col>

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
      </Row>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default PolicyDetails;
