import React, { useState, useEffect } from "react"
import "./style.less"
import PaginationComponent from "../PaginationComponent/PaginationComponent"
import itemImg from "../../images/tvads/thumb.png"
import { Col, Row } from "react-bootstrap"
import playIcon from "../../images/tvads/playIcon.png"

const TvAdsItem = (props) => {
  return (
    <Col className="p-0 col" md={12} xs={12} lg={4}>
      <div onClick={props.tvAdsClick} className="tvadsItem">
        <div>
          <div className="p-r">
            <img src={props.img} className="img-fluid" />{" "}
            <img src={playIcon} className="playicon" />
          </div>
          {/* <div className="date">{props.date}</div> */}
          <div className="date">{props.title}</div>
        </div>
      </div>
    </Col>
  )
}

export default TvAdsItem
