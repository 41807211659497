import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import WOW from "wowjs";
import "swiper/swiper-bundle.css";
import scrollIcon from "../../images/homepage/scrollIcon.svg";
import useWindowDimensions from "../../useWindowDimensions";
import prevarrow from "../../images/homepage/leftarrow.svg";
import nextarrow from "../../images/homepage/rightarrow.svg";
import redBackArrow from "../../images/homepage/redBackArrow.svg";
import redNextArrow from "../../images/homepage/redNextArrow.svg";
import blackBackArr from "../../images/homepage/blackBackArr.svg";
import blackNextArr from "../../images/homepage/blackNextArr.svg";
import uniqid from "uniqid";
import axios from "axios";
import urldata from "../../urldata";
import loadergif from "../../images/loader.gif";
import Imageurldata from "../../Imageurldata";
import "./style.less";
import Button from "../../component/Button/Button";
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
} from "swiper";
import PressReleaseItem from "../../component/PressReleaseItem/PressReleaseItem";
import CounterContainer from "../../component/CounterContainerItem/CounterContainer";
import deskbanneryears50 from "../../images/homepage/deskbanneryears50.jpg";
import mbbanneryears50 from "../../images/homepage/mbbanneryears50.png";
import {
  brandBannerDesktop,
  brandBannerMobile,
  greenBannerDesktop,
  greenBannerMobile,
  inhouseBannerDesktop,
  inhouseBannerMobile,
  waqtBannerDesktop,
  waqtBannerMobile,
  frozenBannerDesktop,
  frozenBannerMobile,
  exploreBanner,
  mbExploreBanner,
  cricketBannerDesktop,
  cricketBannerMobile,
} from "../../images";

import http from "../../helpers/http";
Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectFade]);

// Lorem ipsum dolor sit amet.
// Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, nesciunt.

// console.log(Imageurldata);
// const bannerArr = [banner1, banner2, banner3];
// const mbbannerArr = [mbbanner1, mbbanner2, mbbanner3];

// const ourPartnerBrandsArr = [
//   itc,
//   aashirwad,
//   parle,
//   maggie,
//   roohafza,
//   cofresh,
//   colonelkababz,
//   frooti,
//   dailydelight,
//   gits,
//   hamdard,
//   jalpur,
//   kawan,
//   royalmahout,
//   sumeru,
// ];
const temp2 = [
  "1",
  "2",
  "3",
  "4",
  "1",
  "2",
  "3",
  "4",
  "1",
  "2",
  "3",
  "4",
  "1",
  "2",
  "3",
  "4",
];
// const pressReleaseArr = [
//   {
//     img: newsindia,
//     desc: "One of the oldest South Asian food companies...",
//     date: "December 4, 2020",
//     link:
//       "https://www.newsindiatimes.com/one-of-the-oldest-south-asian-food-company-set-to-expand-in-the-u-s/",
//   },
//   {
//     img: indiawest,
//     desc: "Oldest South Asian Food Company, House...",
//     date: "December 4, 2020",
//     link: "",
//     modelImg: IndiaWestbanner,
//   },
//   {
//     img: desitalk,
//     desc: "One of the oldest South Asian food company set...",
//     date: "December 11, 2020",
//     link: "http://epaper.desitalk.com/2020_12_11/16/ ",
//   },

//   {
//     img: hiindia,
//     desc: "House Of Spices The Oldest South Asian Food...",
//     date: "December 7, 2020",
//     link:
//       "https://www.hiindia.com/blog/2020/12/07/house-of-spices-the-oldest-south-asian-food-company-set-to-expand-in-the-u-s-a/",
//   },
// ];
const HomePageV2 = (props) => {
  const { height, width } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bannerArr, setBannerArr] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [ourJourney, setOurJourney] = useState([]);
  const [ourBrands, setOurBrands] = useState([]);
  const [ourPartnerBrandsImages, setourPartnerBrandsImages] = useState([]);
  const [pressRelease, setpressRelease] = useState([]);
  const [ourBrandDesc, setOurBrandDesc] = useState("");
  const [ourpartnerBrandDesc, setOurpartnerBrandDesc] = useState("");
  const bannerUrl = new URL(urldata + "homepage-sliders");
  const banner_image_url = Imageurldata + "/public/uploads/homepageSlider/";
  //end of store locator states

  const fetchSliders = async () => {
    const datas = await http.get(bannerUrl);
    console.log({ datas });
    setBannerArr(datas?.data);
    // setLoading(false);
  };

  useEffect(() => {
    fetchSliders();
  }, []);

  useEffect(() => {
    const swiper = new Swiper(".homesec1-swiper", {
      slidesPerView: 1,
      loop: true,
      loopedSlides:2,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      spaceBetween: 0,
      navigation: {
        nextEl: ".homesec1 .swiper-button-next",
        prevEl: ".homesec1 .swiper-button-prev",
      },
      pagination: {
        el: ".homesec1 .swiper-pagination",
        clickable: true,
      },
    });
  }, [bannerArr]);

  useEffect(() => {
    let url = new URL(urldata + "user/getHomepageData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const homedata = response.data.data;
        // setBannerArr(homedata.bannerData);
        setAboutData(homedata.homeAboutSec);
        setOurJourney(homedata.ourJourneySec);
        setOurBrands(homedata.ourBrands);
        setourPartnerBrandsImages(homedata.ourPartnerBrandsImages);
        // setpressRelease(homedata.pressRelease);

        setLoading(false);

        window.scroll(0, 0);
        document.title =
          "HOS Global Foods - South Asian Food Distributor in North America";
        document.getElementsByTagName("META")[3].content =
          "HOS Global Foods is the most popular choice for quality South Asian food and the largest South Asian grocery importer and distributor in North America";
        document.getElementsByTagName("META")[4].content =
          "Spices, Beans & Dals, Flours, Oil & Ghee, Rice Indian Food, South Asian Food";
        new WOW.WOW({
          live: false,
        }).init();

        const swiper2 = new Swiper(".homesec5-swiper", {
          slidesPerView: 5,
          spaceBetween: 80,
          navigation: {
            nextEl: ".homesec6 .swiper-button-next",
            prevEl: ".homesec6 .swiper-button-prev",
          },
          loop: true,
        });
       
        const swiper3 = new Swiper(".homesec6 .mbhomesec5-swiper", {
          slidesPerView: 3,
          // spaceBetween: 40,
          spaceBetween: 20,
          navigation: {
            nextEl: ".homesec6 .swiper-button-next",
            prevEl: ".homesec6 .swiper-button-prev",
          },
        });

        // swiper3.on("transitionEnd", function () {
        //   //console.log("*** mySwiper.realIndex", swiper3.realIndex)
        //   setIndex(swiper3.realIndex)
        // })

        const swiper4 = new Swiper(".press-swiper", {
          slidesPerView: 1,
          loop: true,
          spaceBetween: 0,
          navigation: {
            nextEl: ".homesec7 .rightarrow",
            prevEl: ".homesec7 .leftarrow",
          },
        });

        var galleryThumbs = new Swiper(".homesec4 .gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });

        var galleryTop = new Swiper(".homesec4 .gallery-top", {
          spaceBetween: 10,
          thumbs: {
            swiper: galleryThumbs,
          },
        });

        var obds_bg_swiper = new Swiper(
          ".homesec4 .obds_bg_swiper.swiper-container",
          {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            effect: "fade",
            fadeEffect: {
              crossFade: true,
            },
            simulateTouch: false,
            // watchSlidesVisibility: true,
            // watchSlidesProgress: true,
          }
        );

        var obds_logo_swiper = new Swiper(".homesec4 .obds_logo_swiper", {
          spaceBetween: 0,
          slidesPerView: 3,
          slideToClickedSlide: true,
          navigation: {
            nextEl: ".homesec4 .next",
            prevEl: ".homesec4 .prev",
          },
          centeredSlides: true,
          loop: true,
          thumbs: {
            swiper: obds_bg_swiper,
          },
        });

        // var mySwiper = new Swiper(".obds_logo_swiper.swiper-container", settings);

        // function resetSwiper() {
        //   var swiperWrapper = $(".obds_logo_swiper .swiper-wrapper");
        //   var newSlides = $(".obds_logo_swiper .swiper-wrapper")
        //     .children(".swiper-slide")
        //     .clone(true);
        //   mySwiper.destroy();
        //   swiperWrapper.empty().append(newSlides);
        //   $(".obds_logo_swiper .swiper-wrapper").attr("style", "");
        //   // mySwiper = new Swiper(".obds_logo_swiper.swiper-container", settings);
        // }
        // resetSwiper()
      })
      .catch((error) => {
        //alert("Something Went Wrong");
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var galleryThumbs = new Swiper(".homesec4 .gallery-thumbs", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    var galleryTop = new Swiper(".homesec4 .gallery-top", {
      spaceBetween: 10,
      thumbs: {
        swiper: galleryThumbs,
      },
    });

    var obds_bg_swiper = new Swiper(
      ".homesec4 .obds_bg_swiper.swiper-container",
      {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        simulateTouch: false,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
      }
    );

    var obds_logo_swiper = new Swiper(".homesec4 .obds_logo_swiper", {
      spaceBetween: 0,
      slidesPerView: 3,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".homesec4 .next",
        prevEl: ".homesec4 .prev",
      },
      centeredSlides: true,
      loop: true,
      thumbs: {
        swiper: obds_bg_swiper,
      },
    });
  }, [ourBrands]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const handleScroll = () => {
    try {
      if (window.pageYOffset > 60) {
        var galleryThumbs = new Swiper(".homesec4 .gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });

        // var galleryTop = new Swiper(".homesec4 .gallery-top", {
        //   spaceBetween: 10,
        //   thumbs: {
        //     swiper: galleryThumbs,
        //   },
        // });
        // const swiper = new Swiper(".homesec1-swiper", {
        //   slidesPerView: 1,
        //   autoplay: {
        //     delay: 8000,
        //   },
        //   spaceBetween: 0,
        //   navigation: {
        //     nextEl: ".homesec1 .swiper-button-next",
        //     prevEl: ".homesec1 .swiper-button-prev",
        //   },
        //   pagination: {
        //     el: ".homesec1 .swiper-pagination",
        //     clickable: true,
        //   },
        //   loop: true,
        // });

        // const swiper2 = new Swiper(".homesec5-swiper", {
        //   slidesPerView: 5,
        //   spaceBetween: 80,
        //   navigation: {
        //     nextEl: ".homesec6 .swiper-button-next",
        //     prevEl: ".homesec6 .swiper-button-prev",
        //   },
        // });

        // const swiper3 = new Swiper(".homesec6 .mbhomesec5-swiper", {
        //   slidesPerView: 3,
        //   // spaceBetween: 40,
        //   spaceBetween: 20,
        //   navigation: {
        //     nextEl: ".homesec6 .swiper-button-next",
        //     prevEl: ".homesec6 .swiper-button-prev",
        //   },
        // });

        // // swiper3.on("transitionEnd", function () {
        // //   //console.log("*** mySwiper.realIndex", swiper3.realIndex)
        // //   setIndex(swiper3.realIndex)
        // // })

        // const swiper4 = new Swiper(".press-swiper", {
        //   slidesPerView: 1,
        //   loop: true,
        //   spaceBetween: 0,
        //   navigation: {
        //     nextEl: ".homesec7 .rightarrow",
        //     prevEl: ".homesec7 .leftarrow",
        //   },
        // });
      }
    } catch (error) {}
  };

  useEffect(() => {
    let url = new URL(urldata + "admin/getPressRelase");
    axios({
      method: "post",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        const newTempArr = templist.slice(0, 4);
        setpressRelease(newTempArr);
      })
      .catch((error) => {
        console.log(error);
        //showToast("danger");
      });
  }, []);

  useEffect(() => {
    let url = new URL(urldata + "user/getHomePageOurBrandData");
    let ourPartnerBrandUrl = new URL(urldata + "user/getHomePagePartnerData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        setOurBrandDesc(response.data.data.homeourbrand[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    axios({
      method: "get",
      url: ourPartnerBrandUrl,
      withCredentials: true,
    })
      .then((response) => {
        setOurpartnerBrandDesc(response.data.data.homepagepartner[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sliderBannerListOld = bannerArr.map((item, i) => (
    <div key={uniqid()} className="swiper-slide">
      <img className="bannerImg img-fluid" src={Imageurldata + item.imgUrl} />

      <>
        <div className="textContainer">
          <div className="title">
            {item.text1}
            <br></br>
            {item.text2} <br></br> {item.text3}
          </div>
          {item.link ? (
            <div className="btnContainer">
              <Button
                onClick={() => window.open(item.link, "_self")}
                title="EXPLORE"
              />
            </div>
          ) : null}
        </div>
      </>
    </div>
  ));
  const sliderBannerList = bannerArr?.map((item, i) => (
    <div className="swiper-slide" key={item._id}>
      <img
        className="bannerImg img-fluid"
        src={banner_image_url + item.image}
      />

      <>
        <div className="textContainer">
          {item.title && (
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            ></div>
          )}
          {item.btn_name && (
            <div className="btnContainer">
              <Button
                onClick={() => window.open(item.btn_url, "_self")}
                title={item.btn_name}
              />
            </div>
          )}
        </div>
      </>
    </div>
  ));

  const mobileSliderList = bannerArr?.map((item, i) => (
    <div className="swiper-slide" key={item._id}>
      <img className="bannerImg" src={banner_image_url + item.mobile_image} />

      <>
        <div className="textContainer">
          {item.title && (
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            ></div>
          )}
          {item.btn_name && (
            <div className="btnContainer">
              <Button
                onClick={() => window.open(item.btn_url, "_self")}
                title={item.btn_name}
              />
            </div>
          )}
        </div>
      </>
    </div>
  ));

  const mobOurBrandsSliderBannerList = ourBrands.map((item, i) => (
    <div key={uniqid()} className="swiper-slide">
      <img className="bannerImg img-fluid" src={Imageurldata + item.logo} />
    </div>
  ));

  const mobOurBrandsSliderContentList = ourBrands.map((item, i) => (
    <div key={uniqid()} className="swiper-slide">
      <>
        <div className="swiper-slide">
          <div className="productImgContainer">
            <img src={Imageurldata + item.productImg} className="img-fluid" />
          </div>
          <div className="desc">{item.desc}</div>
          <div className="productImgContainer">
            <Button
              onClick={() => window.open(item.link, "_self")}
              title="KNOW MORE"
            />
          </div>
        </div>
      </>
    </div>
  ));

  const deskOurBrandsSliderContentList = ourBrands.map((item, i) => (
    <div key={i} className="swiper-slide">
      <div className="swiper-slide">
        <div className="obds_bg_content_div">
          <div className="obds_bg_img">
            <img src={Imageurldata + item.bgimg} className="img-fluid" />
          </div>
          <div className="obds_bg_content">
            <div className="obds_bg_brandname">{item.title}</div>
            <div className="obds_bg_brandpacketshots">
              <img src={Imageurldata + item.productImg} className="img-fluid" />
            </div>
            <div className="desc">{item.desc}</div>
            <div className="productImgContainer">
              <a href={item.link} target="_blank">
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  const deskOurBrandsSliderBrandsList = ourBrands.map((item, i) => (
    <div key={i} className="swiper-slide">
      <div className="obds_logo_div c-p">
        <img src={Imageurldata + item.logo} className="img-fluid" />
      </div>
    </div>
  ));

  // const ourPartnerBrandList = ourPartnerBrandsArr.map((item, i) => (
  //   <div key={uniqid()} className="swiper-slide">
  //     <div className="swiperSlideContainer">
  //       <img src={item} className="img-fluid" />
  //     </div>
  //   </div>
  // ));

  const ourPartnerBrandsImagesList = ourPartnerBrandsImages.map((item, i) => (
    <div key={uniqid()} className="swiper-slide">
      <div className="swiperSlideContainer">
        <img src={Imageurldata + item.imgUrl} className="img-fluid" />
      </div>
    </div>
  ));

  const mbpressSliderList = pressRelease.map((item, i) => (
    <div key={uniqid()} className="swiper-slide">
      <PressReleaseItem
        img={Imageurldata + item.imgUrl}
        desc={item.desc}
        date={item.date}
        link={item.link}
        modelImg={item.modelImg}
      />
    </div>
  ));

  const pressSliderList = pressRelease.map((item, i) => (
    <PressReleaseItem
      key={i}
      img={Imageurldata + item.imgUrl}
      desc={item.desc}
      date={item.date}
      link={item.link}
      modelImg={item.modelImg}
    />
  ));

  const scrollToDiv = () => {
    const id = "aboutUs";
    const yOffset = -50;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  if (loading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }

  return (
    <>
      <section className="homesec1 wow fadeIn">
        {width >= 640 ? (
          <>
            <div className="homesec1-swiper swiper-container">
              <div className="swiper-wrapper">
                {/* <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={greenBannerDesktop}
                  />

                  <>
                    <div className="textContainer">
                      <div className="title">
                        connecting
                        <br></br>
                        North America with Authentic <br></br> Ethnic Foods and
                        Flavors since 1972
                      </div>

                      <div className="btnContainer">
                        <Button
                          onClick={() =>
                            window.open("https://hosindia.com/about", "_self")
                          }
                          title="EXPLORE"
                        />
                      </div>
                    </div>
                  </>
                </div>

              
                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={cricketBannerDesktop}
                  />
                </div>

                <div className="swiper-slide">
                  <img className="bannerImg img-fluid" src={exploreBanner} />
                </div>
                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={frozenBannerDesktop}
                  />
                </div>

                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={deskbanneryears50}
                  />
                </div>

                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={waqtBannerDesktop}
                  />
                </div>

                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={inhouseBannerDesktop}
                  />
                </div>

                <div className="swiper-slide">
                  <img
                    className="bannerImg img-fluid"
                    src={brandBannerDesktop}
                  />
                </div> */}

                {sliderBannerList}
              </div>
            </div>
            <img src={prevarrow} className="swiper-button-prev" />
            <img src={nextarrow} className="swiper-button-next" />
            <img
              onClick={scrollToDiv}
              src={scrollIcon}
              className="scrollicon"
            />
          </>
        ) : (
          <>
            <div className="homesec1-swiper swiper-container">
              <div className="swiper-wrapper">
                {/* <div className="swiper-slide">
                  <img className="bannerImg " src={greenBannerMobile} />

                  <>
                    <div className="textContainer">
                      <div className="title">
                        connecting
                        <br></br>
                        North America with Authentic <br></br> Ethnic Foods and
                        Flavors since 1972
                      </div>

                      <div className="btnContainer">
                        <Button
                          onClick={() =>
                            window.open("https://hosindia.com/about", "_self")
                          }
                          title="EXPLORE"
                        />
                      </div>
                    </div>
                  </>
                </div>
               
                <div className="swiper-slide">
                  <img className="bannerImg " src={cricketBannerMobile} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={mbExploreBanner} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={frozenBannerMobile} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={mbbanneryears50} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={waqtBannerMobile} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={inhouseBannerMobile} />
                </div>
                <div className="swiper-slide">
                  <img className="bannerImg " src={brandBannerMobile} />
                </div> */}

                {mobileSliderList}
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </>
        )}
      </section>
      <section id="aboutUs" className="homesec2  wow fadeIn">
        <Row className="row">
          <Col lg={6}>
            <div className="title">{aboutData["0"].title}</div>
            <div className="desc">
              <div
                dangerouslySetInnerHTML={{
                  __html: aboutData[0].desc.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            </div>
            {width >= 1024 ? (
              <div className="btnContainer ">
                <Button
                  onClick={() => window.open(aboutData["0"].link, "_self")}
                  title="KNOW MORE"
                />
              </div>
            ) : null}
          </Col>
          <Col lg={6}>
            {/* <div className='test'>{Imageurldata}</div> */}
            <img
              className="bannerImg img-fluid"
              src={Imageurldata + aboutData[0].imgUrl}
            />
            {width <= 1023 ? (
              <div className="btnContainer ">
                <Button
                  onClick={() => window.open(aboutData["0"].link, "_self")}
                  title="KNOW MORE"
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </section>
      <section className="homesec3  wow fadeIn">
        <div className="ourJourneyContainer">
          <div className="title">{ourJourney["0"].title}</div>
          <div className="desc">
            <div
              dangerouslySetInnerHTML={{
                __html: ourJourney[0].desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          </div>

          <Row className="counterRow m-0">
            <Col className="col" xs={4} md={4} lg={4}>
              <CounterContainer
                img={Imageurldata + ourJourney["0"].icon1}
                title={ourJourney["0"].title1}
                span={ourJourney["0"].plusSpan1}
                count={ourJourney["0"].count1}
              />
            </Col>
            <Col className="col" xs={4} md={4} lg={4}>
              <CounterContainer
                img={Imageurldata + ourJourney["0"].icon2}
                title={ourJourney["0"].title2}
                span={ourJourney["0"].plusSpan2}
                count={ourJourney["0"].count2}
              />
            </Col>
            <Col md={4} xs={4} lg={4}>
              <CounterContainer
                img={Imageurldata + ourJourney["0"].icon3}
                title={ourJourney["0"].title3}
                span={ourJourney["0"].plusSpan3}
                count={ourJourney["0"].count3}
              />
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundImage:
              width >= 1024
                ? `url(${Imageurldata + ourJourney["0"].parallaximgUrl})`
                : `url(${Imageurldata + ourJourney["0"].mbparallaximgUrl})`,
          }}
          className="parallaxContainer"
        >
          <div className="parallax"></div>
        </div>
      </section>
      <section className="homesec4  wow fadeIn">
        <div className="title">OUR BRANDS</div>
        {/************  change ******************/}
        <div className="desc">
          {ourBrandDesc.desc ? (
            <div
              dangerouslySetInnerHTML={{
                __html: ourBrandDesc.desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
        {width < 1024 ? (
          <>
            <div className="swiper-container gallery-thumbs">
              <div className="swiper-wrapper">
                {mobOurBrandsSliderBannerList}
              </div>
            </div>

            <div className="swiper-container gallery-top">
              <div className="swiper-wrapper">
                {mobOurBrandsSliderContentList}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="ourbrandsdesktopslider">
              <div className="obds_bg_slider">
                <div className="obds_bg_swiper swiper-container">
                  <div className="swiper-wrapper">
                    {deskOurBrandsSliderContentList}
                  </div>
                </div>
              </div>
              <div className="obds_logo_slider">
                <div className="obds_logo_swiper swiper-container">
                  <div className="swiper-wrapper">
                    {deskOurBrandsSliderBrandsList}
                  </div>
                  <div className="icons swiper-prev">
                    <img
                      src={redBackArrow}
                      className="prev icon swiper-button-prev"
                    />
                    homesec5
                  </div>
                  <div className="icons swiper-next">
                    <img
                      src={redNextArrow}
                      className="next icon swiper-button-next"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      <section className="homesec5  wow fadeIn">
        <div className="title">OUR PARTNER BRANDS</div>
        <div className="desc">
          {/* ******************change************************** */}
          {ourpartnerBrandDesc.desc ? (
            <div
              dangerouslySetInnerHTML={{
                __html: ourpartnerBrandDesc.desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          ) : (
            ""
          )}
          {/* Apart from in-house brands, House of Spices is also one of the largest
          distributors of South Asian brands in North America. */}
        </div>
      </section>
      <section className="homesec6">
        <Row className="ourBrandsRow">
          <Col md={1} xs={1} lg={1} className="p-0 d-f j-c a-c">
            <img src={redBackArrow} className="prev icon swiper-button-prev" />
          </Col>
          <Col className="p-0" md={10} xs={10} lg={10}>
            <div
              className={
                width >= 1024
                  ? "homesec5-swiper swiper-container"
                  : "mbhomesec5-swiper swiper-container"
              }
            >
              <div className="swiper-wrapper">{ourPartnerBrandsImagesList}</div>
            </div>
          </Col>
          <Col className="p-0 d-f j-c a-c" md={1} xs={1} lg={1}>
            <img src={redNextArrow} className="next icon swiper-button-next" />
          </Col>
        </Row>
      </section>
      <section className="homesec7  wow fadeIn">
        <div className="title">PRESS RELEASE</div>
        <div className="desc">
          Get the latest updates on some of our most recent activities.
        </div>
        {width >= 1024 ? (
          <Row className="m-0 pressReleaseRow">{pressSliderList}</Row>
        ) : (
          <div className="pressReleaseRow">
            <div className="press-swiper swiper-container">
              <div className="swiper-wrapper">{mbpressSliderList}</div>
            </div>
            <div className="d-f j-c a-c">
              <div className="arrow d-f as-c">
                <div className="leftarrow">
                  <img src={blackBackArr} className="fluid c-p"></img>
                </div>
                <div className="rightarrow">
                  <img src={blackNextArr} className="fluid c-p"></img>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="btnContainer d-f j-c a-c">
          <Button
            onClick={() => props.history.push("media/press-release")}
            title="View all"
          />
        </div>
      </section>

      {/* <BannerItem img={banner1} title="about us" /> */}
    </>
  );
};

export default HomePageV2;
