import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useWindowDimensions from "../../useWindowDimensions";
import WOW from "wowjs";

import banner3 from "../../images/contactus/contactUsBanner.png";
import mbanner from "../../images/contactus/contactus-banner-640.jpg";
import redBackArrow from "../../images/contactus/redBackArrow.svg";
import redNextArrow from "../../images/contactus/redNextArrow.svg";
import location from "../../images/contactus/location.svg";
import call from "../../images/contactus/call.svg";
import mail from "../../images/contactus/mail.svg";
import close from "../../images/close.svg";
import check from "../../images/check.svg";

import BannerItem from "../../component/BannerItem/BannerItem";
import Button from "../../component/Button/Button";
import InputText from "../../component/InputText/InputText";

import axios from "axios";
import urldata from "../../urldata";

import Toast from "../../component/Toast/Toast";

import "swiper/swiper-bundle.css";
import "./style.less";

import moment from "moment";

import loadergif from "../../images/loader.gif";
import Imageurldata from "../../Imageurldata";

import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from "swiper";

const OurPresenceAddressesArr = [
  {
    officelocation: "New York",
    officetype: "(Corporate Office)",
    officeaddress: "3000 Marcus Avenue, Suite 2W10, Lake Success, NY 11042",
    officetelno: "(718) 361-4248",
    officefaxno: "",
    officeviewonmaps: "https://goo.gl/maps/u4nL9BBLBFErEW23A",
  },
  {
    officelocation: "Chicago, IL",
    officetype: "",
    officeaddress: "2121 Touhy Avenue, Elk Grove Village, IL 60007",
    officetelno: "(847) 595-2929",
    officefaxno: "(847) 595-9595/8788",
    officeviewonmaps: "https://goo.gl/maps/ySb3a1UpoVfZgiJm8",
  },
  {
    officelocation: "Toronto, Canada",
    officetype: "",
    officeaddress: "62 Selby Road, Unit 3 & 4, Brampton ON L6W 3L4",
    officetelno: "(905) 595-3266",
    officefaxno: "(905) 595-3267",
    officeviewonmaps: "https://goo.gl/maps/kXMiYCD4DHRST6Bo6",
  },
  {
    officelocation: "Atlanta, GA",
    officetype: "",
    officeaddress: "4140 Blue Ridge Industrial Parkway, Norcross, GA 30071",
    officetelno: "(770) 263-0202",
    officefaxno: "(770) 797-9669",
    officeviewonmaps: "https://goo.gl/maps/4WH74mjFQr4uMoQKA",
  },
  {
    officelocation: "Houston, TX",
    officetype: "",
    officeaddress:
      "631 Buffalo Lakes Drive, Suite 200, Missouri City, TX 77489",
    officetelno: "(281) 313-5224",
    officefaxno: "(281) 494-5963",
    officeviewonmaps: "https://goo.gl/maps/v8AaYA42ij7P8Xgq6",
  },
  {
    officelocation: "New York, NY",
    officetype: "",
    officeaddress: "57-07 49th Place, Maspeth, NY 11378",
    officetelno: "(718) 628-8822",
    officefaxno: "(718) 628-8833",
    officeviewonmaps: "https://goo.gl/maps/D7pbiUdZj3KyRxw36",
  },
  {
    officelocation: "Washington, DC",
    officetype: "",
    officeaddress: "6745 Business Parkway, Suite 200, Elkridge, MD 21075",
    officetelno: "(301) 420-1088",
    officefaxno: "(301) 967-7001",
    officeviewonmaps: "https://goo.gl/maps/HCo7RZLo9XGKXxAx6",
  },
  {
    officelocation: "Boston, MA",
    officetype: "",
    officeaddress: "444 Whitney Street, Northborough, Massachusetts, 01532",
    officetelno: "(508) 757-6555",
    officefaxno: "(508) 757-6554",
    officeviewonmaps: "https://goo.gl/maps/RNijKHzFnf6aYMo69",
  },
  {
    officelocation: "Los Angeles, CA",
    officetype: "",
    officeaddress: "13821 Struikman Road, Cerritos, CA 90703",
    officetelno: "(562) 407-0711",
    officefaxno: "(562) 407-0712",
    officeviewonmaps: "https://goo.gl/maps/45XWofkyW4hbBK2d8",
  },
  {
    officelocation: "Edison, NJ",
    officetype: "",
    officeaddress: "40 Northfield Avenue, Edison, NJ 08837",
    officetelno: "(732)-515-7007",
    officefaxno: "",
    officeviewonmaps: "https://goo.gl/maps/4mQ7k9KzQMrqjebg9",
  },
  {
    officelocation: "Orlando, FL",
    officetype: "",
    officeaddress: "3445 Bartlett Blvd, Orlando, FL 32811",
    officetelno: "(407) 841-4608",
    officefaxno: "(407) 841-4611",
    officeviewonmaps: "https://goo.gl/maps/fn8a7pmqVRgqQD158",
  },
  {
    officelocation: "San Francisco, CA",
    officetype: "",
    officeaddress: "2741 McCone Avenue, Hayward, CA 94545",
    officetelno: "(510) 732-8014",
    officefaxno: "(510) 732-7829",
    officeviewonmaps: "https://goo.gl/maps/f8R5rqRoZYsHttXFA",
  },
];

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);
const Contactus = (props) => {
  const { height, width } = useWindowDimensions();
  const [fname, setFName] = useState("");
  const [fnameerr, setFNameerr] = useState("");
  const [lname, setLName] = useState("");
  const [lnameerr, setLNameerr] = useState("");
  const [subject, setSubject] = useState("");
  const [subjecterr, setSubjecterr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbererr, setPhoneNumbererr] = useState("");
  const [message, setMessage] = useState("");
  const [messageerr, setMessageerr] = useState("");
  const [list, setList] = useState([]);

  const [contactusData, setContactUsData] = useState([]);

  const [ourPresenceData, setourPresenceData] = useState([]);

  const [loading, setLoading] = useState(true);

  let toastProperties = null;

  console.log("ourPresenceData", ourPresenceData)

  useEffect(() => {
    let url = new URL(urldata + "admin/getOurPresence");
    axios({
      method: "post",
      url: url,
    }).then((res) => {
      setourPresenceData(res.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    setLoading(true);
    let url = new URL(urldata + "user/getContactusData");
    axios({
      method: "get",
      url: url,
    })
      .then((response) => {
        setContactUsData(response.data.data);
        // console.log(response);
        // console.log(response.data.data.ourPresenceData);
        // setourPresenceData(response.data.data.ourPresenceData);

        window.scroll(0, 0);

        document.title =
          "Contact Us - Connecting with people who share mutual love for food.";
        document.getElementsByTagName("META")[3].content =
          "Our food do most of the talk but when you’re hungry for more, we’ll serve you right! We have served foodies since 1972 & always happy to connect.";
        document.getElementsByTagName("META")[4].content =
          "HOS Global Foods Contact Us, HOS Global Foods Enquiry, HOS Global Foods Presence";

        new WOW.WOW({
          live: false,
        }).init();

        setLoading(false);

        const ourpresenceswiper = new Swiper(
          ".contactussec3 .ourpresenceswiper",
          {
            slidesPerView: 3,
            spaceBetween: 0,
            loop: true,
            navigation: {
              nextEl: ".ourpresenceswiper .swiper-button-next",
              prevEl: ".ourpresenceswiper .swiper-button-prev",
            },
          }
        );
        const mourpresenceswiper = new Swiper(
          ".contactussec3 .mourpresenceswiper",
          {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            navigation: {
              nextEl: ".mourpresenceswiper .swiper-button-next",
              prevEl: ".mourpresenceswiper .swiper-button-prev",
            },
          }
        );
      })
      .catch((error) => {
        // alert("Something Went Wrong")
        //console.log(error);
        setLoading(false);
        // showToast("danger");
        // showToast("success")
      });
  }, []);

  useEffect(() => {
    const ourpresenceswiper = new Swiper(".contactussec3 .ourpresenceswiper", {
      slidesPerView: 3,
      spaceBetween: 0,
      loop: true,
      navigation: {
        nextEl: ".ourpresenceswiper .swiper-button-next",
        prevEl: ".ourpresenceswiper .swiper-button-prev",
      },
    });
    const mourpresenceswiper = new Swiper(
      ".contactussec3 .mourpresenceswiper",
      {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
          nextEl: ".mourpresenceswiper .swiper-button-next",
          prevEl: ".mourpresenceswiper .swiper-button-prev",
        },
      }
    );
  }, [ourPresenceData]);

  const submit = () => {
    //console.log(fname, lname, subject, phoneNumber, message)
    var formIsValid = true;

    if (!fname) {
      formIsValid = false;
      setFNameerr("*Please enter your first name.");
    } else {
      setFNameerr("");
    }

    if (typeof fname !== "undefined") {
      if (!fname.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setFNameerr("*Please enter alphabet characters only.");
      }
    }

    if (!lname) {
      formIsValid = false;
      setLNameerr("*Please enter your last name.");
    } else {
      setLNameerr("");
    }

    if (typeof lname !== "undefined") {
      if (!lname.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setLNameerr("*Please enter alphabet characters only.");
      }
    }

    if (!subject) {
      formIsValid = false;
      setSubjecterr("*Please enter your subject.");
    } else {
      setSubjecterr("");
    }

    if (typeof phoneNumber !== "undefined") {
      if (!phoneNumber.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        setPhoneNumbererr("*Please enter valid mobile no.");
      } else {
        setPhoneNumbererr("");
      }
    }

    if (!message) {
      formIsValid = false;
      setMessageerr("*Please enter your message.");
    } else {
      setMessageerr("");
    }

    if (formIsValid) {
      // //console.log(props.id);

      let data = {};
      data.name = fname;
      data.lastname = lname;
      data.subject = subject;
      data.phoneNumber = phoneNumber;
      data.message = message;
      data.date = moment().format("DD-MM-YYYY");
      data.time = moment().format("hh:mm a");

      let url = new URL(urldata + "user/contactForm");

      axios({
        method: "post",
        url: url,
        data: data,
        withCredentials: true,
      })
        .then((response) => {
          setMessage("");
          setPhoneNumber("");
          setSubject("");
          setLName("");
          setFName("");
          showToast("success");
        })
        .catch((error) => {
          // alert("Something Went Wrong");
          showToast("danger");
        });
    }
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Your Form Sumbitted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "success2":
        toastProperties = {
          id,
          title: "Success",
          description: "Your Resume Uploaded Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setList([]);
    }

    setList([...list, toastProperties]);
  };

  const OurPresenceAddressesList = ourPresenceData.map((item, i = 1) => (
    <div key={i} className="swiper-slide">
      <div className="officedetails">
        <div className="officelocation">{item.cityName}</div>
            <div className="officetype">{item.citySubtitle !== '' ? (item.citySubtitle): ('')}</div>
        <div className="officeaddress">{item.desc}<br />{item.desctwo}</div>
        <div className="officecontactnumbers">
            <a href={`tel:${item.tel !== ''? (item.tel): ('')}`}>{
              item.tel !== "" ? (
                `Tel: ${item.tel}`
              ): ('')
            }</a>
          <br />
          {/* {item.fax !== "" ? ( */}
            <a href={`tel:${item.fax !== '' ? (item.fax): ('')}`}>{
              item.fax !== "" ? (
                `Fax: ${item.fax}`
              ): ('')
            }</a>
          {/* ) : null} */}
        </div>
        <div className="officeviewonmaps">
          <a href={item.maplink} target="_blank">
            view on maps
          </a>
        </div>
      </div>
    </div>
  ));
  const mOurPresenceAddressesList = ourPresenceData.map((item, i = 1) => (
    <div key={i} className="swiper-slide">
      <div className="officedetails">
        <div className="officelocation">{item.cityName}</div>
        <div className="officetype">{item.citySubtitle !== '' ? (item.citySubtitle): ('')}</div>
        <div className="officeaddress">{item.desc}<br />{item.desctwo}</div>
        <div className="officecontactnumbers">
            <a href={`tel:${item.tel !== ''? (item.tel): ('')}`}>{
              item.tel !== "" ? (
                `Tel: ${item.tel}`
              ): ('')
            }</a>
          <br />
          {/* {item.fax !== "" ? ( */}
            <a href={`tel:${item.fax !== '' ? (item.fax): ('')}`}>{
              item.fax !== "" ? (
                `Fax: ${item.fax}`
              ): ('')
            }</a>
          {/* ) : null} */}
        </div>
        <div className="officeviewonmaps">
          <a href={item.maplink} target="_blank">
            view on maps
          </a>
        </div>
      </div>
    </div>
  ));

  if (loading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }

  return (
    <>
      <section className="contactussec1 wow fadeIn">
        <BannerItem
          img={
            width > 767
              ? Imageurldata + contactusData.bannerData[0].imgUrl
              : Imageurldata + contactusData.bannerData[0].mbimgUrl
          }
          title={contactusData.bannerData[0].title}
          desc=""
        />
      </section>
      <section className="contactussec2 wow fadeIn">
        <div className="title">{contactusData.contactusData[0].title}</div>
        <div className="desc">
          <div
            dangerouslySetInnerHTML={{
              __html: contactusData.contactusData[0].description.replace(
                /(<? *script)/gi,
                "illegalscript"
              ),
            }}
          ></div>
        </div>
        <div className="contactformbox">
          <Row className="m-0 a-c">
            <Col lg={4}>
              <div className="getintouchbox">
                <div className="title">Get In Touch</div>
                <div className="addressbox">
                  <img className="img-fluid" src={location} />
                  <span>
                    {contactusData.contactusData[0].address}
                    {/* House of Spices – Headquarters
                    <br />
                    3000 Marcus Avenue
                    <br />
                    Suite 2W10
                    <br />
                    Lake Success, NY 11042
                    <br /> */}
                    <br />
                    <a
                      href={contactusData.contactusData[0].maplink}
                      target="_blank"
                    >
                      view on maps
                    </a>
                  </span>
                </div>
                <div className="telbox">
                  <img className="img-fluid" src={call} />
                  <a href={`tel:${contactusData.contactusData[0].mobile}`}>
                    {contactusData.contactusData[0].mobile}
                  </a>
                </div>
                {/* <div className="mailbox">
                  <img className="img-fluid" src={mail} />
                  <a href="mailto:orders@hosindia.com">orders@hosindia.com</a>
                </div> */}
                <div className="querylinebox">
                  <span>
                    {contactusData.contactusData[0].queries}
                    {/* In case of any other queries / complaints/ further
                    communication, please drop an e-mail at: */}
                  </span>
                </div>
                <div className="querymailidbox">
                  <a href={`mailto:${contactusData.contactusData[0].email}`}>
                    {contactusData.contactusData[0].email}
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="shareyourfeedbackformbox">
                <div className="title">SHARE YOUR FEEDBACK</div>
                <div className="desc">
                  We’ve been solving your food related queries since 1972, and
                  we’re always happy to connect.
                </div>
                <div className="shareyourfeedbackformdiv">
                  <Row>
                    <Col lg={6}>
                      <InputText
                        value={fname}
                        onChange={(e) => setFName(e.target.value)}
                        title="First Name*"
                      />
                      <div className="err">{fnameerr}</div>
                    </Col>
                    <Col lg={6}>
                      <InputText
                        value={lname}
                        onChange={(e) => setLName(e.target.value)}
                        title="Last Name*"
                      />
                      <div className="err">{lnameerr}</div>
                    </Col>
                    <Col lg={6}>
                      <InputText
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        title="Subject*"
                      />
                      <div className="err">{subjecterr}</div>
                    </Col>
                    <Col lg={6}>
                      <InputText
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        title="Phone Number*"
                      />
                      <div className="err">{phoneNumbererr}</div>
                    </Col>

                    <Col lg={12}>
                      <InputText
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        title="Message*"
                      />
                      <div className="err">{messageerr} </div>
                    </Col>
                  </Row>
                  <div className="btnContainer">
                    <Button onClick={submit} title="SUBMIT" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="contactussec3 wow fadeIn">
        <div className="title">OUR PRESENCE</div>
        <div className="ourpresenceslider">
          {width >= 1024 ? (
            <>
              <div className="ourpresenceswiper swiper-container">
                <div className="swiper-wrapper">{OurPresenceAddressesList}</div>
                <div className="d-f j-c a-c">
                  <div className="arrow d-f as-c">
                    <div className="icon swiper-button-prev leftarrow">
                      <img src={redBackArrow} className="fluid c-p"></img>
                    </div>
                    <div className="icon swiper-button-next rightarrow">
                      <img src={redNextArrow} className="fluid c-p"></img>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mourpresenceswiper swiper-container">
                <div className="swiper-wrapper">
                  {mOurPresenceAddressesList}
                </div>
                <div className="d-f j-c a-c">
                  <div className="arrow d-f as-c">
                    <div className="icon swiper-button-prev leftarrow">
                      <img src={redBackArrow} className="fluid c-p"></img>
                    </div>
                    <div className="icon swiper-button-next rightarrow">
                      <img src={redNextArrow} className="fluid c-p"></img>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <Toast toastList={list} position="top-right" />
    </>
  );
};

export default Contactus;
