import React, { useState, useEffect } from "react";
import moment from "moment";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

const OurPresence = (props) => {
    const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [updateModel, setUpdateModel] = useState(false);

  const [presenceId, setPresenceId] = useState("");

  const [show, setShow] = useState(false);

  const [cityName, setCityName] = useState("");
  const [cityNameErr, setCityNameErr] = useState("");
  
  const [citySubtitle, setCitySubtitle] = useState("");
  const [citySubtitleErr, setCitySubtitleErr] = useState("");
  
    const [desc, setDesc] = useState("");
    const [descErr, setDescErr] = useState("");
    
  
    const [desctwo, setDesctwo] = useState("");
    const [desctwoErr, setdesctwoErr] = useState("");
    
    const [tel, setTel] = useState("");
    const [telErr, setTelErr] = useState("");

    const [fax, setFax] = useState("");
    const [faxErr, setFaxErr] = useState("");

    const [mapLink, setMapLink] = useState("");
    const [mapLinkErr, setMapLinkErr] = useState("");

      const [toastlist, setToastList] = useState([]);

    let toastProperties = null;
 
  useEffect(() => {
    let url = new URL(urldata + "admin/getOurPresence");
    setLoading(true);
    axios({
      method: "post",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        // showToast("danger");
      });
  }, []);

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Office ${updateModel? 'updated': 'saved'} Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Office deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);
    setCityName("");
    setCitySubtitle("");
    setDesc("");
    setDesctwo("");
    setTel('');
    setFax('');
    setMapLink('');

    setShow(true)
  };


  const handleShowForUpdate  = (rowData) => {
    setUpdateModel(true)  
    setShow(true);
    setPresenceId(rowData._id);

    setCityName(rowData.cityName);
    setCitySubtitle(rowData.citySubtitle);
    setDesc(rowData.desc);
    setDesctwo(rowData.desctwo);
    setTel(rowData.tel)
    setFax(rowData.fax);
    setMapLink(rowData.maplink);
  }

  //*******************update our presence ***********/
  const updateOurPresence = () => {
    let data = {}
    data.id  = presenceId
    data.cityName = cityName
    data.citySubtitle = citySubtitle
    data.desc = desc
    data.desctwo = desctwo
    data.tel = tel
    data.fax = fax
    data.maplink = mapLink

    let url = new URL(urldata + "admin/updateOurPresence");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        // console.log(templist);
        setList(templist);
        setShow(false);
        setLoading(false);
        showToast("success");
        
      })
      .catch((error) => {
        // showToast("danger");
        // console.log("error", error);
        setLoading(false);
      });
  }



  //**************add our presence **********/
  const addOurPresence = () => {
    let data = {}
    data.cityName = cityName
    data.citySubtitle = citySubtitle
    data.desc = desc
    data.desctwo = desctwo
    data.tel = tel
    data.fax = fax
    data.maplink = mapLink

    let url = new URL(urldata + "admin/addOurPresence");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        const tempList = response.data.data.reverse();
        setList(tempList);
        setShow(false);
        setLoading(false);
        showToast('success')
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
        // showToast('danger')
      });
  };

  const submit = () => {
    let formIsValid = true;

    if (!cityName) {
      formIsValid = false;
      setCityNameErr("*Please add City Name");
    } else {
        setCityNameErr("");
    }
    // if (!citySubtitle) {
    //   formIsValid = false;
    //   setCitySubtitleErr("*Please add City Subtitle");
    // } else {
    //     setCitySubtitleErr("");
    // }

    if (!desc) {
      formIsValid = false;
      setDescErr("*Please add address line 1");
    } else {
        setDescErr("");
    }

    if (!desctwo) {
      formIsValid = false;
      setdesctwoErr("*Please add address line 2");
    } else {
      setdesctwoErr("");
    }

    // if (!tel) {
    //   formIsValid = false;
    //   setTelErr("*Please add Telephone Number");
    // } else {
    //   setTelErr("");
    // }
    // if (!fax) {
    //   formIsValid = false;
    //   setFaxErr("*Please add Fax Number");
    // } else {
    //   setFaxErr("");
    // }
    if (!mapLink) {
      formIsValid = false;
      setMapLinkErr("*Please add map link");
    } else {
      setMapLinkErr("");
    }

    if(formIsValid){
        if(updateModel){
            updateOurPresence()
        }else{
            addOurPresence()
        }
    }

}


const deleteBtnClick = (id) => {
    let data = {};
    data.id = id;

    let url = new URL(urldata + "admin/deleteOurPresence");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");
        // console.error(error);
        setLoading(false);
      });
  };



  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Office" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Our Presence List"
        columns={[
          { title: "Office Location", field: "cityName" },
          { title: "Office Type", field: "citySubtitle" },
          { title: "Address Line 1", field: "desc" },
          { title: "Address Line 2", field: "desctwo" },
          { title: "Office Telephone No.", field: "tel" },
          { title: "Office Fax No.", field: "fax" },
          { title: "Office Map Link", field: "maplink" },
          
          
          {
            field: "url",
            title: "Action",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                  ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: {csv:true},
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model" style={{ position: "relative" }}>
          <div className="title">
            {updateModel ? "Update Office Details" : "Add Office Detail"}
          </div>

          <Row>
            <Col lg={3} className="column">
              <div className="titleitem">Office Location</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                maxLength="30"
                type="text"
              />
              <div className="err">{cityNameErr}</div>
              {/* <div className="count">{cityName?.length}/30</div> */}
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Office Type</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={citySubtitle}
                onChange={(e) => setCitySubtitle(e.target.value)}
                maxLength="100"
                type="text"
              />
              <div className="err">{citySubtitleErr}</div>
              {/* <div className="count">{citySubtitle?.length}/100</div> */}
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Address Line 1</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                maxLength="300"
                type="text"
              />
              <div className="err">{descErr}</div>
              {/* <div className="count">{desc?.length}/300</div> */}
            </Col>
            <Col lg={3} className="column">
              <div className="titleitem">Address Line 2</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={desctwo}
                onChange={(e) => setDesctwo(e.target.value)}
                maxLength="300"
                type="text"
              />
              <div className="err">{desctwoErr}</div>
              {/* <div className="count">{desc?.length}/300</div> */}
            </Col>
            
            <Col lg={3} className="column">
              <div className="titleitem">Office Telephone No.</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                type="tel"
              />
              <div className="err">{telErr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Office Fax No.</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={fax}
                onChange={(e) => setFax(e.target.value)}
                type="tel"
              />
              <div className="err">{faxErr}</div>
            </Col>

            <Col lg={3} className="column">
              <div className="titleitem">Office Map Location</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={mapLink}
                onChange={(e) => setMapLink(e.target.value)}
                type="text"
              />
              <div className="err">{mapLinkErr}</div>
            </Col>
           
          </Row>

          <div className="btnContainer btn_press mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
          
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
}

export default OurPresence
