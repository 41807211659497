import React, { useState, useEffect } from "react"
import ReactQuill from "react-quill"
import { Markup } from "interweave"
import WOW from "wowjs"
import Button from "../../component/Button/Button";

import "react-quill/dist/quill.snow.css"

import useWindowDimensions from "../../useWindowDimensions"

import "./style.less"
import bg from "../../images/404/bg.jpg"

const PageNotFound = (props) => {
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    window.scroll(0, 0)
    document.title = "Hos - 404"

    new WOW.WOW({
      live: false,
    }).init()
  }, [])
  return (
    <>
      <section className="pagenotfoundsec1 wow fadeIn t-c" style={{ backgroundImage: `url(${bg})` }}>
        <p>The page you were looking for, does not exist!</p>
        <h1>
          404
        </h1>
        <Button
                onClick={() => props.history.push("/")}
                title="BACK TO HOMEPAGE"
              />
      </section>
      
    </>
  )
}

export default PageNotFound