import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../urldata";
import Imageurldata from "../../../Imageurldata";

import Button from "../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../component/InputText/InputText";

import upload from "../../../images/careers/upload.svg";
import Toast from "../../../component/Toast/Toast";
import close from "../../../images/close.svg";
import check from "../../../images/check.svg";

const BannerCommon = ({ header, screenQuery }) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [mobBannerImg, setMobBannerImg] = useState("");
  const [mobBannerImgErr, setMobBannerImgErr] = useState("");
  const [showMbImg, setShowMbImg] = useState("");
  const [checkMobileImgPresent, setCheckMobileImgPresent] = useState("");

  const [desktopBannerImg, setDesktopBannerImg] = useState("");
  const [desktopBannerImgErr, setDesktopBannerImgErr] = useState("");
  const [showDesktopImg, setShowDesktopImg] = useState("");
  const [checkDesktopImgPresent, setCheckDesktopImgPresent] = useState("");

  const [toastlist, setToastList] = useState([]);
  let toastProperties = null;

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Banner added Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    setShowMbImg(false);
    setShowDesktopImg(false);
    let url = new URL(urldata + "admin/getsingleImages");
    url.searchParams.append("screen", screenQuery);
    let decoded = decodeURIComponent(url);
    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      withCredentials: true,
    })
      .then((response) => {
        let data = response.data.data[0];
        setTitle(data.title ? data.title : "");
        setMobBannerImg(data.mbimgUrl ? Imageurldata + data.mbimgUrl : "");
        setDesktopBannerImg(data.imgUrl ? Imageurldata + data.imgUrl : "");
        setLoading(false);
        setCheckDesktopImgPresent(data.imgUrl ? false : true);
        setCheckMobileImgPresent(data.mbimgUrl ? false : true);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        //showToast("danger");
      });
  }, []);

  const addBannerData = () => {
    let formData = new FormData();

    formData.append("title", title);

    if (checkDesktopImgPresent) {
      formData.append("bannerImageWeb", desktopBannerImg);
      setCheckDesktopImgPresent(false);
    }
    if (checkMobileImgPresent) {
      formData.append("bannerImageMobile", mobBannerImg);
      setCheckMobileImgPresent(false);
    }

    let url = new URL(urldata + "admin/addSingleBannerImage");
    url.searchParams.append("screen", screenQuery);
    let decoded = decodeURIComponent(url);

    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        showToast("success");
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        // showToast('danger')
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title) {
      formValid = false;
      setTitleErr("*Please add Title");
    } else {
      setTitleErr("");
    }
    if (!mobBannerImg) {
      formValid = false;
      setMobBannerImgErr("*Please add mobile size image");
    } else {
      setMobBannerImgErr("");
    }
    
    if (!desktopBannerImg) {
      formValid = false;
      setDesktopBannerImgErr("*Please add desktop size image");
    } else {
      setDesktopBannerImgErr("");
    }
  

    if (formValid) {
      addBannerData();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>{header} Banner</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        {/*sub row 1*/}
        <Col className="column" lg={3}>
          <div className="title">Title:- </div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
          />
          <h4 className="err">{titleErr}</h4>
        </Col>

        {/*sub row 2*/}
        <Col className="column" lg={3}>
          <div className="title">Mobile Image:-</div>
          <div className="desc">Size :- 640 × 632</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage1"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setMobBannerImg(e.target.files[0]);
              setShowMbImg(true);
              setCheckMobileImgPresent(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage1" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{mobBannerImgErr} </div>
          <Col className="column" lg={3}>
            {mobBannerImg !== "" && (
              <img
                src={
                  showMbImg ? URL.createObjectURL(mobBannerImg) : mobBannerImg
                }
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>

        {/*sub row 3*/}
        <Col className="column" lg={3}>
          <div className="title">Desktop Image:-</div>
          <div className="desc">Size :- 2560 × 968</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setDesktopBannerImg(e.target.files[0]);
              setShowDesktopImg(true);
              setCheckDesktopImgPresent(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{desktopBannerImgErr} </div>
          <Col className="column" lg={3}>
            {desktopBannerImg !== "" && (
              <img
                src={
                  showDesktopImg
                    ? URL.createObjectURL(desktopBannerImg)
                    : desktopBannerImg
                }
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>
        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default BannerCommon;
