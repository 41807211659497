import React, { useState, useEffect, useMemo } from "react";
import "./style.less";
import useWindowDimensions from "../../useWindowDimensions";

import blackBackArr from "../../images/about/blackBackArr.svg";
import blackNextArr from "../../images/about/blackNextArr.svg";

import Imageurldata from "../../Imageurldata";

import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

const OurInHousePartnerBrandsProductsSlider = (props) => {
  const { height, width } = useWindowDimensions();

  // //console.log("-=====", props.OurInHousePartnerBrandsArr)

  // const OurInHousePartnerBrandsList2 = useMemo(() => {
  //   return props.OurInHousePartnerBrandsArr.map((item, i = 1) => (
  //     <div className="swiper-slide">
  //       <div className="OurInHousePartnerBrandsProductsImg">
  //         <img className="img-fluid" src={item.img} />
  //       </div>
  //     </div>
  //   ))
  // }, [props.OurInHousePartnerBrandsArr])

  useEffect(() => {
    // console.log('in sub comp---->');
    const swiper4 = new Swiper(".OurInHousePartnerBrandsProductsSwiper", {
      slidesPerView: 3,
      spaceBetween: 20,
      touchRatio: 0,
      // centeredSlides: true,
      //loop: true,
      navigation: {
        nextEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-next",
        prevEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-prev",
      },
    });
    const swiper5 = new Swiper(".mOurInHousePartnerBrandsProductsSwiper", {
      slidesPerView: 1,
      spaceBetween: 20,
      touchRatio: 1,
      // centeredSlides: true,
      simulateTouch: false,
      // loop: true,
      navigation: {
        nextEl: ".mOurInHousePartnerBrandsProductsSwiper .swiper-button-next",
        prevEl: ".mOurInHousePartnerBrandsProductsSwiper .swiper-button-prev",
      },
    });
  }, [props.OurInHousePartnerBrandsArr]);

  
  // console.log('subcomp---->', props.OurInHousePartnerBrandsArr);
  const OurInHousePartnerBrandsList2 = props.OurInHousePartnerBrandsArr.map(
    (item, i) => (
      <div key={i} className="swiper-slide">
        <div className="OurInHousePartnerBrandsProductsImg">
        {console.log(Imageurldata + item.imgUrl)}
          <img className="img-fluid" src={Imageurldata + item.imgUrl} />
        </div>
      </div>
    )
  );
  // const swiper4 = new Swiper(".OurInHousePartnerBrandsProductsSwiper", {
  //   slidesPerView: 3,
  //   spaceBetween: 20,
  //   centeredSlides: true,
  //   loop: true,
  //   navigation: {
  //     nextEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-next",
  //     prevEl: ".OurInHousePartnerBrandsProductsSwiper .swiper-button-prev",
  //   },
  // })

  return (
    <>
      <div className="OurInHousePartnerBrandsProductsSlider">
        {width >= 1024 ? (
          <>
            <div className="OurInHousePartnerBrandsProductsSwiper swiper-container">
              <div className="swiper-wrapper">
                {OurInHousePartnerBrandsList2}
              </div>
              <div className="d-f j-c a-c">
                <div className="arrow d-f as-c">
                  <div className="swiper-button-prev leftarrow">
                    <img src={blackBackArr} className="fluid c-p"></img>
                  </div>
                  <div className="swiper-button-next rightarrow">
                    <img src={blackNextArr} className="fluid c-p"></img>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mOurInHousePartnerBrandsProductsSwiper swiper-container">
              <div className="swiper-wrapper">
                {OurInHousePartnerBrandsList2}
              </div>
              <div className="d-f j-c a-c">
                <div className="arrow d-f as-c">
                  <div className="swiper-button-prev leftarrow">
                    <img src={blackBackArr} className="fluid c-p"></img>
                  </div>
                  <div className="swiper-button-next rightarrow">
                    <img src={blackNextArr} className="fluid c-p"></img>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OurInHousePartnerBrandsProductsSlider;
