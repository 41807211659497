import React, { useState, useEffect } from "react";
import "./style.less";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../images/header/hos_logo.png";
import useWindowDimensions from "../../useWindowDimensions";
import uniqid from "uniqid";

const Header = (props) => {
  let location = useLocation();
  const { height, width } = useWindowDimensions();
  const [isSticky, setSticky] = useState(false);
  const [isList, setisList] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const handleScroll = () => {
    try {
      if (window.pageYOffset > 60) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    } catch (error) {}
  };
  // console.log(window.location.pathname);
  // console.log(window.location.href);
  const handleReload = () => {
    // console.log(window.location.pathname)
    if (location.pathname == "/") {
      console.log("-------------------in reload-----------");
      window.location.reload();
    }
  };
  return (
    <>
      {width >= 1024 ? (
        <div className={`header d-f jc-sb a-c ${isSticky ? "sticky" : null}`}>
          <Link to="/">
            <img src={logo} />
          </Link>
          <div className="d-f">
            <NavLink
              to="/about"
              activeStyle={{ color: "#ed1c24" }}
              activeClassName="selected"
              className="title c-p"
            >
              ABOUT US
            </NavLink>
            <NavLink
              to="/our-brands"
              activeStyle={{ color: "#ed1c24" }}
              className="title c-p"
            >
              OUR BRANDS
            </NavLink>
            <NavLink
              to="/catalogue"
              activeStyle={{ color: "#ed1c24" }}
              className="title c-p"
            >
              CATALOGUE
            </NavLink>
            <NavLink
              to="/media"
              activeStyle={{ color: "#ed1c24" }}
              className="title c-p"
            >
              MEDIA
            </NavLink>

            <NavLink
              activeStyle={{ color: "#ed1c24" }}
              to="/collaborate"
              className="title c-p"
            >
              COLLABORATE
            </NavLink>
            <NavLink
              activeStyle={{ color: "#ed1c24" }}
              to="/careers"
              className="title c-p"
            >
              CAREERS
            </NavLink>
            <NavLink
              to="/contactus"
              activeStyle={{ color: "#ed1c24" }}
              className="title c-p"
            >
              CONTACT US
            </NavLink>
          </div>
        </div>
      ) : (
        <div className="header">
          <div className="d-f jc-sb a-c h-f">
            <Link to="/">
              <img src={logo} onClick={handleReload} className="fluid mblogo" />
            </Link>

            <div>
              <button
                onClick={() => setisList(!isList)}
                className={isList ? "opened menu" : "menu"}
              >
                <svg width="70" height="50" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </div>
          {isList ? (
            <div className="headerMobileView">
              <NavLink
                onClick={() => setisList(false)}
                to="/about"
                className="title c-p"
                activeStyle={{ color: "#ed1c24" }}
              >
                ABOUT US
              </NavLink>
              <NavLink
                onClick={() => setisList(false)}
                activeStyle={{ color: "#ed1c24" }}
                to="/our-brands"
                className="title c-p"
              >
                OUR BRANDS
              </NavLink>
              <NavLink
                onClick={() => setisList(false)}
                to="/catalogue"
                activeStyle={{ color: "#ed1c24" }}
                className="title c-p"
              >
                CATALOGUE
              </NavLink>

              <NavLink
                onClick={() => setisList(false)}
                to="/media"
                activeStyle={{ color: "#ed1c24" }}
                className="title c-p"
              >
                MEDIA
              </NavLink>

              <NavLink
                onClick={() => setisList(false)}
                to="/collaborate"
                activeStyle={{ color: "#ed1c24" }}
                className="title c-p"
              >
                COLLABORATE
              </NavLink>
              <NavLink
                onClick={() => setisList(false)}
                to="/careers"
                className="title c-p"
                activeStyle={{ color: "#ed1c24" }}
              >
                CAREERS
              </NavLink>
              <NavLink
                to="/contactus"
                onClick={() => setisList(false)}
                activeStyle={{ color: "#ed1c24" }}
                className="title c-p"
              >
                CONTACT US
              </NavLink>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Header;
