import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const OurPartBrandDesc = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
  
    const [title, setTitle] = useState("");
    const [titleErr, setTitleErr] = useState("");
  
      
    const [toastlist, setToastList] = useState([]);
    let toastProperties = null;
  
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        [{ align: [] }],
      ],
    };
  
    
    const showToast = (type) => {
      const id = Math.floor(Math.random() * 101 + 1);
      switch (type) {
        case "success":
          toastProperties = {
            id,
            title: "Success",
            description: "Our Partner brands Introduction added successfully",
            backgroundColor: "#329F33",
            icon: check,
          };
          break;
        case "danger":
          toastProperties = {
            id,
            title: "Something Went Wrong",
            description: "",
            backgroundColor: "red",
            icon: close,
          };
          break;
        case "delete":
          toastProperties = {
            id,
            title: "Success",
            description: "deleted Successfully",
            backgroundColor: "#329F33",
            icon: check,
          };
          break;
  
        default:
          setToastList([]);
      }
  
      setToastList([...toastlist, toastProperties]);
    };
  
    
    useEffect(() => {
      let url = new URL(urldata + "admin/getourbrandpagedesc");
      setLoading(true);
      axios({
        method: "get",
        url: url,
        withCredentials: true,
      })
        .then((response) => {
          let data = response.data.data[0];
          setTitle(data.desc ? data.desc : "");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, []);
  
    const addBannerData = () => {
      // let formData = new FormData();
  
      // formData.append("desc", title);
  
      let data = {}
    data.desc = title
      let url = new URL(urldata + "admin/addourbrandpagedesc");
      // url.searchParams.append("screen", screenQuery);
      // let decoded = decodeURIComponent(url);
  
      setLoading(true);
      axios({
        method: "post",
        url: url,
        data: data,
        withCredentials: true,
      })
        .then((response) => {
          showToast("success");
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
          // showToast('danger')
        });
    };
  
    
  
    const submit = () => {
      let formValid = true;
      // console.log('clicked');
      // console.log(title)
      if (!title) {
        formValid = false;
        setTitleErr("*Please add our partner brands Introduction");
      } else {
        setTitleErr("");
      }
  
      
      if (formValid) {
          // console.log('in submit');
        addBannerData();
      }
    };
  
    return (
      <>
        <h1>Our Partner Brands Introduction:- </h1>
        <br></br>
        <Row className="adminhomeaboutSec1">
          {/*sub row 1*/}
          {/* <Col className="column" lg={3}>
            <div className="title">Our Partner Brands Introduction:- </div>
          </Col> */}
          <Col className="column" lg={12}>
            {/* <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
            /> */}
  
            <ReactQuill
              value={title}
              modules={modules}
              onChange={(e) => {
                setTitle(e);
              }}
            />
            <h4 className="err">{titleErr}</h4>
          </Col>
  
          {/*sub row 2*/}
        
          <Col className="column d-f j-c a-c">
            <Button onClick={submit} title="SUBMIT" />
          </Col>
          <Toast toastList={toastlist} position="top-right" />
        </Row>
      </>
    );
}

export default OurPartBrandDesc
