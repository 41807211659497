// *****************
// Component Created by - Vijay Navale
// Date - 05/10/2022
// Component use case:- To show products with it's categories
// *****************

import React, { memo, useEffect, useRef, useState } from "react";
import { useWindowSize, useSetState } from "react-use";
import { productImg } from "../../helpers/utils";
import "./Catalogue.less";
import BannerItem from "../../component/BannerItem/BannerItem";
import catbanner from "../../images/catalogue/catbanner.png";
import loadergif from "../../images/loader.gif";
import mbcatbanner from "../../images/catalogue/mbcatbanner.jpg";
import bannerimg from "../../images/catalogue/catalogue_banner.jpg";
import mbbannerimg from "../../images/catalogue/mb_catalogue_banner_2.jpg";
import { ReactComponent as Downloadicon } from "../../images/catalogue/download-icon.svg";
import { ReactComponent as Downarrow } from "../../images/catalogue/down-arrow.svg";
import Button from "../../component/Button/Button";
import {
  useGetCategories,
  useGetFeaturedProducts,
  useGetProducts,
  useGetProductsByMultipleIds,
} from "./useGetCatalogueData";
import useOnScreen from "../../hooks/useOnScreen";
import { CatalogueContext } from "../../contexts/CatalogueContext";

const ProductBox = memo(({ barcode, image, item_code, mcc, name }) => {
  return (
    <div className="product_box">
      {image && (
        <img
          src={productImg + image}
          alt="Product image"
          width={160}
          height={199}
          className="productimg"
        />
      )}
      <h6 className="product_title">{name}</h6>
      <div className="product_item_code">{item_code}</div>
      <div className="product_mcc_code">{mcc}</div>
      {barcode && (
        <img
          src={productImg + barcode}
          alt="Product image"
          width={197}
          height={71}
          className="qrimg"
        />
      )}
    </div>
  );
});

const Catalogue = () => {
  // const { isFooterInViewPort } = useContext(CatalogueContext);
  // const isFooterRef = useRef(isFooterInViewPort);
  // const [makeFixed, setMakeFixed] = useState(false);
  // const filterByCol = useRef();
  const mainCatIdRef = useRef("");
  const { width } = useWindowSize();
  const isCatalogueLoaded = useRef(true);
  let [
    {
      catActiveIndex,
      categoryNameId,
      offsetVal,
      isCatExpand,
      catgoryId,
      categoryIdsArr,
      showLoadMore,
      isProdLoading,
      catalogueFile,
    },
    setState,
  ] = useSetState({
    catActiveIndex: null,
    categoryNameId: "",
    offsetVal: 0,
    isCatExpand: false,
    catgoryId: null,
    categoryIdsArr: [],
    showLoadMore: true,
    isProdLoading: true,
    catalogueFile: "",
  });

  const [productsData, setProductsData] = useState({});

  const onProductsSuccess = (data) => {
    const proData = data.data.data;
    if (proData) {
      if (offsetVal) {
        setProductsData((prevState) => {
          return {
            ...prevState,
            products: [...prevState.products, ...proData.products],
          };
        });
      } else {
        setProductsData(proData);
        setState({ isProdLoading: false });
      }
      if (proData.totalCount > 21) {
        setState({ showLoadMore: true });
      } else {
        setState({ showLoadMore: false });
      }
      if (isCatalogueLoaded.current) {
        setState({ catalogueFile: proData.catalog });
        isCatalogueLoaded.current = false;
      }
    }
  };

  const { categoriesData, isCategoriesLoading } = useGetCategories();
  // const { isProductsLoading, refetchProducts } = useGetProducts(
  //   catgoryId,
  //   offsetVal,
  //   onProductsSuccess
  // );
  const { isFeaturedProductsLoading, refetchFeaturedProducts } =
    useGetFeaturedProducts(offsetVal, onProductsSuccess);

  const { mutate, isLoading: isProductsLoading } = useGetProductsByMultipleIds(
    categoryIdsArr,
    offsetVal,
    onProductsSuccess
  );
  // const handleScroll = () => {
  //   if (
  //     filterByCol.current.offsetTop - 10 < window.pageYOffset.toFixed() &&
  //     window.pageYOffset.toFixed() < 1100
  //   ) {
  //     setMakeFixed(true);
  //   } else {
  //     setMakeFixed(false);
  //   }
  // };
  // useEffect(() => {
  // if (isFooterInViewPort) {
  //   setMakeFixed(false);
  // }
  // isFooterRef.current = isFooterInViewPort;
  // }, [isFooterInViewPort]);
  useEffect(() => {
    window.scroll(0, 0);
    document.title =
      "Explore the full catalogue of Laxmi (HOS) - North America's #1 South Asian Food Brand";
    document.getElementsByTagName("META")[3].content =
      "Checkout the full catalogue of Laxmi (HOS).The Laxmi Promise is to provide Purity, Quality and Value. Explore Laxmi's endless range of products now!";
    document.getElementsByTagName("META")[4].content =
      "Checkout the full catalogue of Laxmi (HOS).The Laxmi Promise is to provide Purity, Quality and Value. Explore Laxmi's endless range of products now!";
  }, []);
  useEffect(() => {
    if (categoryIdsArr.length > 0) {
      if (width >= 768) {
        applyScroll("productsColHeading", -150);
      } else {
        // applyScroll("mobProdCol", -150);
        setState({ isCatExpand: false });
      }
      // refetchProducts(catgoryId);
      mutate();
    } else {
      refetchFeaturedProducts();
    }
  }, [categoryIdsArr]);
  useEffect(() => {
    if (offsetVal) {
      if (categoryIdsArr.length > 0) {
        // refetchProducts(catgoryId);
        mutate();
      } else {
        refetchFeaturedProducts();
      }
    }
  }, [offsetVal]);
  useEffect(() => {
    if (categoryNameId) {
      applyScroll(categoryNameId, -150);
    }
  }, [categoryNameId]);

  useEffect(() => {
    if (productsData?.products?.length == productsData?.totalCount) {
      setState({ showLoadMore: false });
    }
  }, [productsData?.products?.length]);

  const applyScroll = (selectedEle, yOffsetVal) => {
    const yOffset = yOffsetVal;

    const element = document.getElementById(selectedEle);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleChangeIndex = (index, id) => {
    if (catActiveIndex === index) {
      //if clicked question is already active, then close it
      return setState({ catActiveIndex: null });
    }
    setState({ catActiveIndex: index });
    setState({ categoryNameId: id });
  };
  const handleShowMore = () => {
    setState((prevState) => {
      return {
        offsetVal: prevState.offsetVal + 21,
      };
    });
  };
  const handleCategoryChange = (subCatId, mainCatId, subCatName) => {
    // if (categoryIdsArr.includes(id)) {
    //   categoryIdsArr.splice(categoryIdsArr.indexOf(id), 1);
    //   setState({
    //     categoryIdsArr: categoryIdsArr,
    //   });
    // } else {
    //   setState({ categoryIdsArr: [...categoryIdsArr, id] });
    // }
    mainCatIdRef.current = mainCatId;

    if (categoryIdsArr.length > 0) {
      if (
        categoryIdsArr.findIndex((element) => element.subCatId == subCatId) ==
        -1
      ) {
        categoryIdsArr = [
          ...categoryIdsArr,
          {
            mainCatId,
            subCatId,
            subCatName,
          },
        ];
        categoryIdsArr = categoryIdsArr.filter(
          (ids) => ids.mainCatId === mainCatId
        );
        setState({ categoryIdsArr: categoryIdsArr });
      } else {
        categoryIdsArr.splice(
          categoryIdsArr.findIndex((element) => element.subCatId == subCatId),
          1
        );
        setState({
          categoryIdsArr: [...categoryIdsArr],
        });
      }
    } else {
      setState({
        categoryIdsArr: [
          ...categoryIdsArr,
          {
            mainCatId,
            subCatId,
            subCatName,
          },
        ],
      });
    }
    // setState({ catgoryId: id });
    setState({ showLoadMore: true });
    setState({
      offsetVal: 0,
    });
    setState({ isProdLoading: true });
  };
  const categoryList = categoriesData
    ? categoriesData.data.data.map(
        ({ name: mainCatName, categories, _id: mainCatId }, i) => (
          <div className="product_category_wrapper" key={mainCatId}>
            <div
              className="category_name_flex"
              id={`cat-${i}`}
              onClick={() => handleChangeIndex(i, `cat-${i}`)}
            >
              <span className="category_name">{mainCatName}</span>
              <Downarrow className="down_arrow" />
            </div>
            <div
              className={`subcate_wrapper ${
                catActiveIndex === i ? "active" : ""
              }`}
            >
              {categories.map(({ _id, name: subCatName }) => (
                <div className="sub_cat_label" key={_id}>
                  <input
                    type="checkbox"
                    id={_id}
                    name="category_filter"
                    value={subCatName}
                    checked={
                      mainCatIdRef.current == mainCatId &&
                      categoryIdsArr.findIndex(
                        (element) => element.subCatId == _id
                      ) != -1
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleCategoryChange(_id, mainCatId, subCatName)
                    }
                    className="radio_box"
                  />
                  <label htmlFor={_id} className="label_text">
                    {subCatName}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )
      )
    : null;

  const productsList = productsData?.products?.map(
    ({ _id, barcode, image, item_code, mcc, name }) => (
      <ProductBox
        key={_id}
        barcode={barcode}
        image={image}
        item_code={item_code}
        mcc={mcc}
        name={name}
      />
    )
  );

  const renderCategoryName = () => {
    if (categoryIdsArr.length === 1) {
      return categoryIdsArr[0]["subCatName"];
    } else if (categoryIdsArr.length > 1) {
      return;
    } else {
      return "Featured Products";
    }
  };

  if (isCategoriesLoading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }
  return (
    <main className="main_cata">
      <BannerItem
        color="black"
        img={width > 767 ? bannerimg : mbbannerimg}
        title="cAtalogUE"
      />

      <section className="catasec2">
        <div className="catasec2_heading" id="productsColHeading">
          {width >= 768 ? (
            <h2>
              {renderCategoryName()} ( {productsData?.totalCount} Products )
            </h2>
          ) : null}
          <a
            href={productImg + "/content/" + catalogueFile}
            target="_blank"
            className="download_cta"
          >
            <span className="downloadtext">downLOAD CATALOgue</span>
            <Downloadicon className="downloadicon" />
          </a>
        </div>
        <div className="products_wrapper">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-4 col-lg-3 p-0 filter_col"
                // id="filterColId"
                // ref={filterByCol}
              >
                <div
                  className="products_categories_wrapper"
                  // className={`products_categories_wrapper ${
                  //   makeFixed ? "add_fixed" : ""
                  // }`}
                >
                  <h5
                    className="filter_text"
                    onClick={() => setState({ isCatExpand: !isCatExpand })}
                  >
                    FILTER BY{" "}
                    {width >= 768 ? null : <Downarrow className="down_arrow" />}{" "}
                  </h5>
                  <div
                    className={`products_categories_content ${
                      isCatExpand ? "active" : ""
                    }`}
                  >
                    {categoryList}
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-9 p-0" id="mobProdCol">
                {isProdLoading ? (
                  <div className="d-f j-c a-c">
                    <img src={loadergif} alt="" />
                  </div>
                ) : (
                  <>
                    {width >= 768 ? null : (
                      <h2 className="category_name_heading">
                        {renderCategoryName()}( {productsData?.totalCount}{" "}
                        Products )
                      </h2>
                    )}

                    <div className="products_flex">{productsList}</div>

                    <div
                      className={`show_more_btn_wrapper ${
                        isFeaturedProductsLoading || isProductsLoading
                          ? "active"
                          : ""
                      }`}
                    >
                      {showLoadMore && (
                        <Button
                          onClick={handleShowMore}
                          title={`${
                            isFeaturedProductsLoading || isProductsLoading
                              ? "Loading..."
                              : "show more"
                          } `}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Catalogue;
