import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Input,
  Radio,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi";
import _ from "lodash";
import http from "../../../../helpers/http";
import config from "../../../../config/config";
import helper from "../../../../helpers/helper";

function Edit({
  isVisible,
  handleClose,
  dbData,
  moduleName,
  image_url,
  base_url,
}) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const { TextArea } = Input;

  const validate = (value) => {
    let schemaObj = {
      type: Joi.required(),
    };

    if (value.type === "image" && dbData.type != "image" && !dbData.image) {
      schemaObj.image = Joi.required();
    }
    if (value.type === "image" && dbData.type != "image" && !dbData.mobile_image) {
      schemaObj.mobile_image = Joi.required().label("mobile image");
    }
    if (value.type === "video" && dbData.type != "video" && !dbData.thumbnail) {
      schemaObj.thumbnail = Joi.required();
    }
    if (value.type === "video") {
      schemaObj.video_url = Joi.string()
        .uri()
        .required()
        .label("youtube embed url");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image","mobile_image", "thumbnail"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.mobile_image) {
      formData.append("mobile_image", value.mobile_image.file);
    }
    if (value.thumbnail) {
      formData.append("thumbnail", value.thumbnail.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "mobile_image","thumbnail"]));
    if (dbData.type === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
      <Row>
          <Col xs={24} md={12}>
            <Form.Item
              label="Banner Type"
              required
              name="type"
              {...(formError.type && {
                help: formError.type,
                validateStatus: "error",
              })}
            >
              <Radio.Group onChange={handleTypeChange}>
                <Radio value="image" checked>
                  Image
                </Radio>
                <Radio value="video"> Video </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          
        </Row>
        <Row style={{ display: !isVideoVisible ? "block" : "none" }}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Image: (1920 X 1080)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.image
                      ? image_url + dbData.image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Mobile Image: (1920 X 1080)"
              name="mobile_image"
              {...(formError.mobile_image && {
                help: formError.mobile_image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.mobile_image
                      ? image_url + dbData.mobile_image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: isVideoVisible ? "block" : "none" }}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Thumbnail: (1920 X 1080) "
              name="thumbnail"
              {...(formError.thumbnail && {
                help: formError.thumbnail,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.thumbnail
                      ? image_url + dbData.thumbnail
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Video URL:"
              name="video_url"
              {...(formError.video_url && {
                help: formError.video_url,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
