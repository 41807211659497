import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

const AddOurPartners = (props) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  const [partnerId, setPartnerId] = useState(false);

  const [show, setShow] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);

  const [previousImgUrl, setPreviousImgUrl] = useState("");

  const [logoImg, setLogoImg] = useState("");
  const [logoImgErr, setLogoImgErr] = useState("");

  const [showImg, setShowImg] = useState("");
  const [imgNotPresent, setImgNotPresent] = useState('')


  // const [isChanged, setIsChanged] = useState(false);

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  
  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Partner ${updateModel ? 'updated':'added'} Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Partner deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);
    setTitle("");
    setLogoImg("");
    setShowImg(false);
    setShow(true);
    setTitleErr('')
    setLogoImgErr('')
  };

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getPartnerBrands");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response)
        const tempPartnerList = response.data.data.reverse();
        // console.log(tempPartnerList);
        setList(tempPartnerList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // showToast("danger");
      });
  }, []);

  const handleShowForUpdate = (rowData) => {
    setUpdateModel(true);
    setPartnerId(rowData._id);
    setTitle(rowData.title);
    setPreviousImgUrl(rowData.imgUrl);
    setLogoImg('');
    setShowImg(false);
    setImgNotPresent(rowData.imgUrl ? false: true)
    setShow(true);
  };

  //*************update partner
  const updatePartner = () => {
    let data = {};
    data.id = partnerId;
    data.title = title;
    data.imgurl = previousImgUrl;

    let formData = new FormData();
    formData.append("logo", logoImg);

    // console.log(formData.has('logo'));

    let url = new URL(urldata + "admin/updateOurPartnerBrands");

    Object.keys(data).forEach((key) => url.searchParams.append(key, data[key]));
    var decoded = decodeURIComponent(url);

    // setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
        showToast("success");

        // setLoading(false);
      })
      .catch((error) => {
        // showToast("danger");
        // setLoading(false);
        setShow(false);
        // console.log("error", error);
      });
  };

  //**************adding partner
  const createPartner = () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("logo", logoImg);

    let url = new URL(urldata + "admin/addOurPartnerBrands");

    setLoading(true);

    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        showToast("success");
        const tempPartnerList = response.data.data.reverse();
        setList(tempPartnerList);
        setShow(false);
        setLoading(false);
      })
      .catch((error) => {
        // showToast("danger");
        console.log("error", error);
        setLoading(false);
      });
  };

  //submitting details
  const submit = () => {
    let formIsValid = true;

    if (!logoImg) {
      formIsValid = false;
      setLogoImgErr("*Please upload brand logo image.");
    } else {
      setLogoImgErr("");
    }
    // if(logoImg){
    //   if(logoImg.size / 1024 > 300){
    //     formIsValid = false;
    //     setLogoImgErr('*File Size should be less than 100kb')
    //   }
    // }
    if (!title) {
      formIsValid = false;
      setTitleErr("*Please add Partner name");
    } else {
      setTitleErr("");
    }
    if (formIsValid) {
      if (updateModel) {
        updatePartner();
      } else {
        createPartner();
      }
    }
  };

  //deleting partner
  const deleteBtnClick = (id) => {
    let data = {};
    data.id = id;

    let url = new URL(urldata + "admin/deleteOurPartnerBrands");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");
        console.error(error);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Partner Brand" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Partner's List"
        columns={[
          { title: "Title", field: "title" },
          {
            field: "url",
            title: "Brand logo",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.imgUrl, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "Action  ",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model">
          <div className="title">
            {updateModel ? "Update Partner" : "Add Partner"}
          </div>

          <Row>
            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Add title</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                // maxLength="31"
                type="text"
              />

              {/* <div className="count">{title.length}/31</div> */}
              <div className="err">{titleErr}</div>
            </Col>

            {/* col 2 */}
            <Col lg={3} className="column">
              <div className="titleitem">Add Logo</div>
              <div className="desc">Size :- 270 × 150</div>
            </Col>
            <Col lg={9} className="column">
              <input
                id="bannerImage"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setLogoImg(e.target.files[0]);
                  setShowImg(true);
                  setImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {logoImg ? logoImg.name : ""}
                </div>
              </div>
              <div className="err">{logoImgErr} </div>
            </Col>
          </Row>

          <div className="btnContainer mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>
          <div className="text-right">
            {logoImg !== "" && (
              <img
                src={showImg ? URL.createObjectURL(logoImg) : ""}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default AddOurPartners;
