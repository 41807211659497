import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AboutUsSecThree = () => {
  const [loading, setLoading] = useState(true);

  const [title1, setTitle1] = useState("");
  const [title1Err, setTitle1Err] = useState("");

  const [title2, setTitle2] = useState("");
  const [title2Err, setTitle2Err] = useState("");

  const [desc1, setdesc1] = useState("");
  const [desc1Err, setdesc1Err] = useState("");

  const [desc2, setdesc2] = useState("");
  const [desc2Err, setdesc2Err] = useState("");

  const [mobBannerImg, setMobBannerImg] = useState("");
  const [mobBannerImgErr, setMobBannerImgErr] = useState("");
  const [showMbImg, setShowMbImg] = useState("");
  const [checkMobileImgPresent, setCheckMobileImgPresent] = useState("");

  const [desktopBannerImg, setDesktopBannerImg] = useState("");
  const [desktopBannerImgErr, setDesktopBannerImgErr] = useState("");
  const [showDesktopImg, setShowDesktopImg] = useState("");
  const [checkDesktopImgPresent, setCheckDesktopImgPresent] = useState("");

  const [img3, setimg3] = useState("");
  const [img3err, setimg3err] = useState("");
  const [showimg3, setshowimg3] = useState("");
  const [checkimg3present, setcheckimg3present] = useState("");

  const [toastlist, setToastList] = useState([]);
  let toastProperties = null;

    
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Data saved Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    setShowMbImg(false);
    setShowDesktopImg(false);
    setshowimg3(false);
    let url = new URL(urldata + "admin/getaboutussec3");
    // url.searchParams.append("screen", "aboutus");
    // let decoded = decodeURIComponent(url);
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response);
        let data = response.data.data[0];
        // console.log(data);
        setTitle1(data.imgtitle2 ? data.imgtitle2 : "");
        setTitle2(data.imgtitle3 ? data.imgtitle3 : "");

        setdesc1(data.descimg2 ? data.descimg2: '')
        setdesc2(data.descimg3 ? data.descimg3: '')

        setMobBannerImg(data.img2 ? Imageurldata + data.img2 : "");
        setDesktopBannerImg(data.img3 ? Imageurldata + data.img3 : "");
        setimg3(data.img1 ? Imageurldata + data.img1 : "");

        setCheckMobileImgPresent(data.img2 ? false : true);
        setCheckDesktopImgPresent(data.img3 ? false : true);
        setcheckimg3present(data.img1 ? false : true);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("error:--", error);
        setLoading(false);
        //showToast("danger");
      });
  }, []);

  const addBannerData = () => {
    let formData = new FormData();
    formData.append("imgtitle2", title1);
    formData.append("imgtitle3", title2);
    formData.append("descimg2", desc1);
    formData.append("descimg3", desc2);

    if (checkMobileImgPresent) {
      formData.append("img2", mobBannerImg);
      setCheckMobileImgPresent(false);
    }
    if (checkDesktopImgPresent) {
      formData.append("img3", desktopBannerImg);
      setCheckDesktopImgPresent(false);
    }

    if (checkimg3present) {
      formData.append("img1", img3);
      setcheckimg3present(false);
    }

    let url = new URL(urldata + "admin/addaboutussec3");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response);
        setLoading(false);
        showToast("success");
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  const submit = () => {
    let formValid = true;

    if (!title1) {
      formValid = false;
      setTitle1Err("*Please add Box One Title");
    } else {
      setTitle1Err("");
    }
    if (!title2) {
      formValid = false;
      setTitle2Err("*Please add Box Two Title");
    } else {
      setTitle2Err("");
    }
    if (!desc1) {
      formValid = false;
      setdesc1Err("*Please add Box One Description");
    } else {
      setdesc1Err("");
    }
    if (!desc2) {
      formValid = false;
      setdesc2Err("*Please add Box Two Description");
    } else {
      setdesc2Err("");
    }

    if (!mobBannerImg) {
      formValid = false;
      setMobBannerImgErr("*Please add Box One Image");
    } else {
      setMobBannerImgErr("");
    }
    // if(mobBannerImg){
    //   if(mobBannerImg.size / 1024 > 300){
    //     formValid = false;
    //     setMobBannerImgErr('*File Size should be less than 300kb')
    //   }
    // }

    if (!desktopBannerImg) {
      formValid = false;
      setDesktopBannerImgErr("*Please add Box two image");
    } else {
      setDesktopBannerImgErr("");
    }
    // if(desktopBannerImg){
    //   if(desktopBannerImg.size / 1024 > 300){
    //     formValid = false;
    //     setDesktopBannerImgErr('*File Size should be less than 300kb')
    //   }
    // }

    if (!img3) {
      formValid = false;
      setimg3err("*Please add background image");
    } else {
      setimg3err("");
    }

    if (formValid) {
      addBannerData();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>About us Section Three</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        <Col className="column" lg={3}>
          <div className="title">Vision Title:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title1}
            onChange={(e) => setTitle1(e.target.value)}
            type="text"
          />
          <h4 className="err">{title1Err}</h4>
        </Col>
        <Col className="column" lg={3}>
          <div className="title">Vision Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={desc1}
            modules={modules}
            onChange={(e) => {
              setdesc1(e);
            }}
          />
          <h4 className="err">{desc1Err}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Vision Image :-</div>
          <div className="desc">Size :- 88 × 88</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage1"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setMobBannerImg(e.target.files[0]);
              setShowMbImg(true);
              setCheckMobileImgPresent(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage1" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{mobBannerImgErr} </div>
          <Col className="column" lg={3}>
            {mobBannerImg !== "" && (
              <img
                src={
                  showMbImg ? URL.createObjectURL(mobBannerImg) : mobBannerImg
                }
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Mission Title:-</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            value={title2}
            onChange={(e) => setTitle2(e.target.value)}
            type="text"
          />
          <h4 className="err">{title2Err}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Mission Description:-</div>
        </Col>
        <Col className="column" lg={9}>
          {/* <input
            value={title2}
            onChange={(e) => setTitle2(e.target.value)}
            type="text"
          /> */}
          <ReactQuill
            value={desc2}
            modules={modules}
            onChange={(e) => {
              setdesc2(e);
            }}
          />
          <h4 className="err">{desc2Err}</h4>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Mission Image:-</div>
          <div className="desc">Size :- 88 × 88</div>

        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setDesktopBannerImg(e.target.files[0]);
              setShowDesktopImg(true);
              setCheckDesktopImgPresent(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{desktopBannerImgErr} </div>
          <Col className="column" lg={3}>
            {desktopBannerImg !== "" && (
              <img
                src={
                  showDesktopImg
                    ? URL.createObjectURL(desktopBannerImg)
                    : desktopBannerImg
                }
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>

        <Col className="column" lg={3}>
          <div className="title">Background Image:-</div>
          <div className="desc">Size :- 2560 × 690</div>
        </Col>
        <Col className="column" lg={9}>
          <input
            id="bannerImage3"
            accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
            type="file"
            onChange={(e) => {
              setimg3(e.target.files[0]);
              setshowimg3(true);
              setcheckimg3present(true);
            }}
            name="passport_proof_upload"
          />

          <label htmlFor="bannerImage3" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : JPG / png</div>
          </div>
          <div className="err">{img3err} </div>
          <Col className="column" lg={3}>
            {img3 !== "" && (
              <img
                src={showimg3 ? URL.createObjectURL(img3) : img3}
                className="img-fluid"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Col>
        </Col>

        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
      </Row>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );

};

export default AboutUsSecThree;
