import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

const getRandomNum = () => {
  return Math.random() * 5;
};

const AddPdf = () => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(getRandomNum());
  const [pdfData, setPdfData] = useState("");
  const [pdfDataErr, setPdfDataErr] = useState("");

  const [prevPdf, setPrevPdf] = useState("");

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "PDF added Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getpdf");
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        //   console.log(response)
        // setPdfData(response.data.data[0])
        setPrevPdf(response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // showToast("danger");
      });
  }, []);

  const submit = () => {
    var formData = new FormData();
    formData.append("pdfupload", pdfData);

    let url = new URL(urldata + "admin/addpdf");

    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        //setShow(false);
        console.log(response);
        setPrevPdf(response.data.data[0]);
        showToast("success");
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch((error) => {
        console.log(error);
        // showToast("danger");
      });
  };

  const checkSubmit = () => {
    let formIsValid = true;

    if (!pdfData) {
      formIsValid = false;
      setPdfDataErr("*Please add pdf");
    } else {
      setPdfDataErr("");
    }

    if (formIsValid) {
      submit();
    }
  };

  const getPdfName = (pdfname) => {
    const pdfNameArr = pdfname.split("/");
    return pdfNameArr[3];
  };

  const downloadPdf = () => {
    // let testUrl = "http://13.233.110.14:4000/api";
    let liveUrl = "https://hosindia.com/api" 

    window.open(liveUrl + prevPdf.pdfupload, "_blank");
  };
  

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col className="column" lg={3}>
          <h3 className="title" style={{ color: "#000" }}>
            Add PDF:-{" "}
          </h3>
        </Col>
        <Col className="column" lg={9}>
          <input
            key={uploading}
            id="bannerImage"
            accept=".pdf"
            type="file"
            onChange={(e) => {
              console.log("in onchange");
              setPdfData(e.target.files[0]);
              setUploading(getRandomNum());
            }}
            name="passport_proof_upload"
          />
          <label for="bannerImage" className="d-f" id="file-drag">
            <img src={upload} className="img-fluid" />
            <div className="chooseTxt">Choose File</div>
          </label>

          <div className="d-f jc-sb">
            <div className="fileTxt">File Format : pdf</div>
            <div className="fileTxt text-primary">
              {pdfData.name ? pdfData.name : ""}
              {/* {pdfData.pdfupload? getPdfName(pdfData.pdfupload): ''} */}
            </div>
          </div>
          <div className="err">{pdfDataErr} </div>
        </Col>

        {/* <Col className='column' lg={3}></Col> */}
        <Col className="column d-f j-c a-c">
          <Button onClick={downloadPdf} title="DOWNLOAD BACKUP" />
          <Button onClick={checkSubmit} title="SUBMIT" />
        </Col>

        <Toast toastList={toastlist} position="top-right" />
      </Row>
    </>
  );
};

export default AddPdf;
