import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
} from "antd";
import Joi from "joi";
// import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import slugify from "react-slugify";

import http from "../../../../helpers/http";
// import config from "../../config/config";
// import HtmlEditor from "../../components/HtmlEditor";
import axios from "axios";
import _ from "lodash";
import urldata from "../../../../urldata";
import helper from "../../../../helpers/helper";

const Create = ({ isVisible, handleClose, moduleName, brands }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const base_url = urldata + "admin/products/categories"; //without trailing slash
  const image_url = urldata + "uploads/product/"; //with trailing slash
  const sizeLimit = 1;

  const { TextArea } = Input;
  const { Option } = Select;

  //validation schema

  const schema = Joi.object({
    name: Joi.string().required(),
    slug: Joi.string().required(),
    brand: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // validating unique slug
    if (data.slug) {
      const result = await helper.validateSlug(data.slug, "ProductCategory");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.post(base_url, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      // form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Brand:"
              name="brand"
              {...(formError.brand && {
                help: formError.brand,
                validateStatus: "error",
              })}
            >
              <Select>
                {brands &&
                  brands.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
