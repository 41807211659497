import React, { useEffect, useRef, useState } from "react";
import BannerItem from "../../component/BannerItem/BannerItem";
import "./careers-v2.less";
import WOW from "wowjs";
import {
  bannerData,
  hosLifeData,
  teamData,
  valuesData,
} from "../../data/careersData";
import loadergif from "../../images/loader.gif";
import useWindowDimensions from "../../useWindowDimensions";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import {
  locationPin,
  mapBG,
  caDevBenfits,
  caDevBenfitsIcon,
  flexWorkBenfits,
  flexWorkBenfitsIcon,
  healthBenfits,
  healthBenfitsIcon,
  ptoBenfits,
  ptoBenfitsIcon,
  retirementBenfits,
  retirementBenfitsIcon,
  leftArrowCV2,
  rightArrowCV2,
  backIcon,
  becomeoneofus,
  mbbecomeoneofus,
  mbhealthBenfits,
  mbretirementBenfits,
  mbptoBenfits,
  mbflexWorkBenfits,
  mbcaDevBenfits,
} from "../../images";
import ApplyStickyCta from "../../component/ApplyStickyCta/ApplyStickyCta";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import $ from "jquery";
import { CustomAccordion } from "../../component/Accordion/Accordion";
import { Link } from "react-router-dom";
import { careerListingURL } from "../../helpers/paths";

Swiper.use([Navigation, Pagination, Autoplay]);

export const indiaArr = [
  {
    title: "Mumbai",
    coordinates: [72.8954382, 19.0821438],
    content:
      "<strong>Address :</strong> Office 612, 6th Floor, Neelkanth Corporate IT Park, Kirol Road, Vidyavihar West Mumbai MH 400086",
  },
];

export const usaArr = [
  {
    title: "Washington, DC",
    coordinates: [-76.7699253, 39.1855466],
    content:
      "<strong>Address :</strong> 6745 Business Parkway, Suite 200, Elkridge, MD 21075",
  },
  {
    title: "New York, NY",
    coordinates: [-73.9191731, 41.7204641],
    content:
      "<strong>Address :</strong> 57-07 49<sup>th</sup> Place, Maspeth, NY 11378",
  },
  {
    title: "Boston, MA",
    coordinates: [-71.6315505, 42.3507323],
    content:
      "<strong>Address :</strong> 444 Whitney Street, Northborough, MA 01532",
  },
  {
    title: "Los Angeles, CA",
    coordinates: [-118.0366785, 33.8756281],
    content:
      "<strong>Address :</strong> 13821 Struikman Road, Cerritos, CA 90703",
  },
  {
    title: "New York",
    coordinates: [-72.6958291, 40.7589353],
    content:
      "<strong>Address :</strong> 3000 Marcus Avenue, Suite 2W10, Lake Success, NY 11042",
  },
  {
    title: "San Francisco, CA",
    coordinates: [-122.1325149, 37.6448326],
    content: "<strong>Address :</strong> 2741 McCone Avenue, Hayward, CA 94545",
  },
  {
    title: "Chicago, IL",
    coordinates: [-87.9512506, 42.0075996],
    content:
      "<strong>Address :</strong> 2121 Touhy Avenue, Elk Grove Village, IL 60007",
  },
  {
    title: "Orlando, FL",
    coordinates: [-81.4334781, 28.506502],
    content: "<strong>Address :</strong> 3445 Bartlett Blvd, Orlando, FL 32811",
  },
  {
    title: "Atlanta, GA",
    coordinates: [-84.1693021, 33.9773574],
    content:
      "<strong>Address :</strong> 4140 Blue Ridge Industrial Parkway, Norcross, GA 30071",
  },
  {
    title: "Houston, TX",
    coordinates: [-95.5189688, 29.6213856],
    content:
      "<strong>Address :</strong> 631 Buffalo Lakes Drive, Suite 140, Missouri City, TX 77489",
  },
  {
    title: "Edison, NJ",
    coordinates: [-74.3479777, 40.5088053],
    content:
      "<strong>Address :</strong> 40 Northfield Avenue, Edison, NJ 08837",
  },
];

export const canadaArr = [
  {
    title: "Toronto, Canada",
    coordinates: [-79.7283298, 43.6937347],
    content:
      "<strong>Address :</strong> 62 Selby Road, Unit 3 & 4, Brampton ON L6W 3L4",
  },
];

const CareersV4 = () => {
  const { width } = useWindowDimensions();
  const [leftMargin, setLeftMargin] = useState(0);
  const containerRef = useRef(null);
  const [countryActive, setCountryActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef();

  const valuesList = valuesData?.map((data, i) => (
    <React.Fragment key={i}>
      <div className="swiper-slide">
        <div
          className="value_box wow fadeInUp"
          data-wow-delay={`${i * 0.025}s`}
        >
          <div className="letter_wrap">
            <img
              width={310}
              height={396}
              src={width > 600 ? data.bgImg : data.mbBgImg}
              alt="value"
              className="bg_img"
              loading="lazy"
            />
            <h3 className="initial_letter">{data.initial}</h3>
          </div>
          <div className="text_container">
            <div className="mobile_text_wrap">
              <img
                width={70}
                height={70}
                src={data.icon}
                alt="value icon"
                className="value_icon"
                loading="lazy"
              />
              <h3 className="value_title">{data.title}</h3>
            </div>
            <p className="value_desc">{data.desc}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  const voicesList = teamData?.map((data, i) => (
    <React.Fragment key={i}>
      <div className="swiper-slide">
        <div
          className="voice_box wow fadeInUp"
          data-wow-delay={`${i * 0.025}s`}
        >
          <p className="tagline">{data.tagline}</p>
          <p className="voice_message">{data.msg}</p>
          <p className="voice_location">{data.location}</p>
          <p className="voice_desg">{data.desg}</p>
        </div>
      </div>
    </React.Fragment>
  ));

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    const voiceSliderContainer = containerRef.current;
    if (!voiceSliderContainer) return;

    const containerStyles = window.getComputedStyle(voiceSliderContainer);
    const marginLeft = containerStyles.getPropertyValue("margin-left");

    setLeftMargin(marginLeft);

    console.log(marginLeft);

    const valuesSwiper = new Swiper(".ca_sec2 .valuesSwiper", {
      slidesPerView: 5,
      spaceBetween: 6,
      allowTouchMove: false,
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        600: {
          loop: true,
          slidesPerView: 2.5,
          allowTouchMove: true,
        },
        992: {
          slidesPerView: 5,
          allowTouchMove: false,
        },
      },
    });

    const voiceSwiper = new Swiper(".ca_sec5 .voiceSlider", {
      slidesPerView: 2.5,
      spaceBetween: 50,
      loop: true,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".voiceSlider .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2.5,
        },
      },
    });

    const sliderThumbnail = new Swiper(
      ".hos_life_slider_container .life_slider_thumbnail",
      {
        slidesPerView: 8,
        spaceBetween: 20,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
          0: {
            slidesPerView: 4.1,
            spaceBetween: 9,
          },
          600: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 8,
          },
        },
      }
    );

    const slider = new Swiper(".hos_life_slider_container .life_slider", {
      spaceBetween: 20,
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".life-swiper-next",
        prevEl: ".life-swiper-prev",
      },
      thumbs: {
        swiper: sliderThumbnail,
      },
    });
  }, []);

  useEffect(() => {
    var root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);
    root._logo.dispose();

    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "rotateY",
        wheelX: "none",
        wheelY: "none",
        pinchZoom: false,
        projection: am5map.geoOrthographic(),
      })
    );

    var backgroundSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {})
    );
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("#94B6EC"),
      fillOpacity: 0,
      strokeOpacity: 0,
    });

    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });

    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
      })
    );

    chart.series.each((series) => {
      series.mapPolygons.template.set("fill", am5.color("#94B6EC")); // Change to your desired color
    });

    chart.chartContainer.get("background").events.on("click", function () {
      goHome();
    });

    var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: root.interfaceColors.get("alternativeBackground"),
      strokeOpacity: 0.3,
    });

    var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

    pointSeries.bullets.push(function () {
      var circle = am5.Circle.new(root, {
        radius: 7,
        // tooltipText: "{title}",
        cursorOverStyle: "pointer",
        tooltipY: 0,
        fill: am5.color(0xffba00),
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,
        draggable: false,
      });

      circle.events.on("click", function (event) {
        var dataItem = event.target.dataItem;
        zoomToCountry(dataItem);
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    pointSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p0,
          centerY: am5.p0,
          text: "{title}",
          populateText: true,
        }),
      });
    });

    var CitypointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {})
    );

    CitypointSeries.bullets.push(function () {
      var IndiaCircle = am5.Circle.new(root, {
        radius: 7,
        tooltipText: "{geometry.title}",
        cursorOverStyle: "pointer",
        tooltipY: 0,
        fill: am5.color(0xff0000),
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,
        draggable: false,
      });

      return am5.Bullet.new(root, {
        sprite: IndiaCircle,
      });
    });

    var indiaCitiesData = [
      {
        geometry: {
          title: indiaArr[0].title,
          type: "Point",
          coordinates: indiaArr[0].coordinates,
        },
      },
    ];
    var usaCitiesData = [
      {
        geometry: {
          title: usaArr[0].title,
          type: "Point",
          coordinates: usaArr[0].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[1].title,
          type: "Point",
          coordinates: usaArr[1].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[2].title,
          type: "Point",
          coordinates: usaArr[2].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[3].title,
          type: "Point",
          coordinates: usaArr[3].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[4].title,
          type: "Point",
          coordinates: usaArr[4].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[5].title,
          type: "Point",
          coordinates: usaArr[5].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[6].title,
          type: "Point",
          coordinates: usaArr[6].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[7].title,
          type: "Point",
          coordinates: usaArr[7].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[8].title,
          type: "Point",
          coordinates: usaArr[8].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[9].title,
          type: "Point",
          coordinates: usaArr[9].coordinates,
        },
      },
      {
        geometry: {
          title: usaArr[10].title,
          type: "Point",
          coordinates: usaArr[10].coordinates,
        },
      },
    ];
    var canadaCitiesData = [
      {
        geometry: {
          title: canadaArr[0].title,
          type: "Point",
          coordinates: canadaArr[0].coordinates,
        },
      },
    ];

    CitypointSeries.hide();

    var india = addCity({ latitude: 19.07609, longitude: 72.877426 }, "India");
    var usa = addCity({ latitude: 43.8163, longitude: -79.4287 }, "USA");
    var canada = addCity(
      { latitude: 52.321945, longitude: -106.584167 },
      "Canada"
    );

    var lineDataItem = lineSeries.pushDataItem({
      pointsToConnect: [india, usa, canada, india],
    });

    var planeSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

    var plane = am5.Graphics.new(root, {
      svgPath:
        "M551.3,730.8c37.7-2.5,64.3-12.4,65.7-26.5c0.9-9.8-10.5-19.8-29.7-28.2l18.3-27.9c35-3.7,59.1-14.2,60.8-29.1c1.1-9.7-7.5-19.7-22.8-28.6l48.2-73.3c164.4-13.3,269.8-56,270-95s-105-82.5-269.2-97.1l-47.6-73.7c15.3-8.9,24-18.8,23-28.5c-1.6-14.9-25.5-25.6-60.5-29.6l-18.1-28c19.3-8.2,30.8-18.1,30-27.9c-1.2-14.1-27.7-24.1-65.4-27L482.5,0.4L385.1,0L425,126.9c-23.1,8.7-37.2,19.6-36.3,30.4c1.1,12.6,22.5,22,54,25.9l9.7,31c-24.2,10.5-38.9,23.3-37.6,35.7c1.5,14.5,24.4,25,58,29.3l13.7,43.6c-1.8,0.1-3.6,0.2-5.4,0.3c-115.1,7.1-220.9,15.8-303.8,27.9L92,242.3L19,242.1l36.6,134.7C20.2,388.4,0.1,402.1,0,418.5c-0.1,16.4,20,30.3,55.2,42.2L17.6,595.1l73.1,0.3l86.3-107.9c82.8,12.7,188.6,22.4,303.6,30.3c1.8,0.1,3.6,0.2,5.4,0.3l-14.1,43.5c-33.6,4-56.6,14.3-58.2,28.8c-1.4,12.4,13.1,25.3,37.3,36l-10,30.9c-31.6,3.6-53,12.8-54.2,25.5c-1,10.8,13.1,21.8,36,30.7L381.8,840l97.4,0.4L551.3,730.8z",
      scale: 0.05,
      rotation: 180,
      centerY: am5.p50,
      centerX: am5.p50,
      fill: am5.color(0x2D1271),
    });

    planeSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});
      container.children.push(plane);
      return am5.Bullet.new(root, { sprite: container });
    });

    var planeDataItem = planeSeries.pushDataItem({
      lineDataItem: lineDataItem,
      positionOnLine: 0,
      autoRotate: true,
    });
    planeDataItem.dataContext = {};

    planeDataItem.animate({
      key: "positionOnLine",
      to: 1,
      duration: 30000,
      loops: Infinity,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    planeDataItem.on("positionOnLine", (value) => {
      if (planeDataItem.dataContext.prevPosition < value) {
        plane.set("rotation", 0);
      }

      if (planeDataItem.dataContext.prevPosition > value) {
        plane.set("rotation", -180);
      }
      planeDataItem.dataContext.prevPosition = value;
    });

    function addCity(coords, title) {
      return pointSeries.pushDataItem({
        latitude: coords.latitude,
        longitude: coords.longitude,
        title: title,
        id: title,
      });
    }

    function zoomToCountry(dataItem) {
      var country = dataItem._settings.title;

      pointSeries.zoomToDataItem(dataItem, 2.5, true);
      pointSeries.hide();
      planeSeries.hide();
      lineSeries.hide();
      setCountryActive(false);

      switch (country) {
        case "India":
          CitypointSeries.data.setAll(indiaCitiesData);
          $("#indiaCities").show();
          $("#canadaCities").hide();
          $("#usaCities").hide();
          break;

        case "Canada":
          CitypointSeries.data.setAll(canadaCitiesData);
          $("#indiaCities").hide();
          $("#canadaCities").show();
          $("#usaCities").hide();
          break;
        case "USA":
          CitypointSeries.data.setAll(usaCitiesData);
          $("#indiaCities").hide();
          $("#canadaCities").hide();
          $("#usaCities").show();
          break;
      }

      CitypointSeries.show();
      $("#countryList").hide();

      $("#returnToGlobe").show();

      mapRef.current.scrollIntoView({
        behavior: "smooth",
        block: width > 992 ? "center" : "start",
      });
    }

    function rotateTheGlobe() {
      chart.animate({
        key: "rotationX",
        from: 0,
        to: 360,
        duration: 25000,
        loops: Infinity,
      });

      chart.animate({
        key: "rotationY",
        from: 0,
        to: -50,
        duration: 2000,
      });
    }

    function goHome() {
      pointSeries.show();
      planeSeries.show();
      lineSeries.show();
      CitypointSeries.hide();
      chart.goHome();
      $("#returnToGlobe").hide();

      $("#countryList").show();
      $("#indiaCities").hide();
      $("#usaCities").hide();
      $("#canadaCities").hide();
      setCountryActive(true);
      rotateTheGlobe();

      chart.animate({
        key: "rotationX",
        from: 0,
        to: -80,
        duration: 2000,
      });
      chart.animate({
        key: "rotationY",
        from: 0,
        to: -70,
        duration: 2000,
      });
    }

    $("#returnToGlobe").click(function () {
      goHome();
      mapRef.current.scrollIntoView({
        behavior: "smooth",
        block: width > 992 ? "center" : "start",
      });
    });

    $(".country").click(function () {
      let countryId = $(this).data("country-id");

      var CountrydataItem = pointSeries.getDataItemById(countryId);
      zoomToCountry(CountrydataItem);
    });
    rotateTheGlobe();

    chart.appear(1000, 100);
  }, []);

  useEffect(() => {
    setLoading(false); // Set loading to false when the page content has loaded
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <img src={loadergif} className="img-fluid" />
        </div>
      )}
      <section className="ca_sec1">
        <BannerItem
          className="bottom_align"
          img={width > 767 ? bannerData[0].banImg : bannerData[0].mobImg}
          title="TAKE YOUR LOVE <br />FOR FOOD TO THE NEXT LEVEL"
        />
      </section>

      <div ref={containerRef} className="my_container">
        <section className="ca_sec2">
          <h2 className="values_title wow fadeInUp">WE BELIEVE IN</h2>
          <div className="values_container">
            {width > 600 ? (
              <div className="valuesSwiper swiper-container">
                <div className="swiper-wrapper">{valuesList}</div>
              </div>
            ) : (
              <div className="valuesSwiper">{valuesList}</div>
            )}
          </div>
        </section>

        <section className="ca_sec3">
          <div className="row country_row">
            <img
              width={1200}
              height={525}
              src={mapBG}
              alt="map bg"
              className="map_bg wow fadeInUp"
              loading="lazy"
            />
            <div className="col-md-6">
              <h2 className="wo_title wow fadeInUp">
                GROW WITH <br />
                OUR INTERNATIONAL <br />
                REACH
              </h2>
            </div>
            <div className="col-md-6">
              <p className="wo_desc wow fadeInUp">
                At HOS, it's more than just a job - you're joining a dynamic
                team dedicated to growth and creating solutions to drive
                meaningful change. You'll have opportunities for continuous
                learning, engaging projects that push boundaries, and a culture
                that prioritizes both your professional and personal well-being.
              </p>
            </div>
            <div className="col-12">
              <div className="country_list">
                <div className="country_box wow fadeInUp">
                  <img
                    width={38}
                    height={62}
                    src={locationPin}
                    alt="location pin"
                    className="c_icon"
                    loading="lazy"
                  />
                  <div className="c_name">
                    UNITED STATES <br />
                    OF AMERICA
                  </div>
                </div>
                <div className="country_box wow fadeInUp">
                  <img
                    width={38}
                    height={62}
                    src={locationPin}
                    alt="location pin"
                    className="c_icon"
                    loading="lazy"
                  />
                  <div className="c_name">CANADA</div>
                </div>
                <div className="country_box wow fadeInUp">
                  <img
                    width={38}
                    height={62}
                    src={locationPin}
                    alt="location pin"
                    className="c_icon"
                    loading="lazy"
                  />
                  <div className="c_name">INDIA</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row globe_row">
            <div className="col-md-7">
              <div ref={mapRef} id="chartdiv"></div>
            </div>
            <div className="col-md-5 globe_details">
              {countryActive && (
                <div className="text_container">
                  {/* <h2 className="title">WORLD MAP</h2> */}
                  <p className="desc">
                    Click on the country text/map & explore our international
                    distribution centers
                  </p>
                </div>
              )}
              <div className="country_accordions">
                <div id="countryList">
                  <div className="country" data-country-id="India">
                    <span>India</span>
                    <img
                      src={backIcon}
                      alt="right"
                      className="right_arrow"
                      loading="lazy"
                    />
                  </div>
                  <div className="country" data-country-id="USA">
                    <span>USA</span>
                    <img
                      src={backIcon}
                      alt="right"
                      className="right_arrow"
                      loading="lazy"
                    />
                  </div>
                  <div className="country" data-country-id="Canada">
                    <span>Canada</span>
                    <img
                      src={backIcon}
                      alt="right"
                      className="right_arrow"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div id="indiaCities" style={{ display: "none" }}>
                  {!countryActive && (
                    <div className="text_container inner_container">
                      <h2 className="title">India</h2>
                    </div>
                  )}
                  <CustomAccordion firstActive={true} items={indiaArr} />
                </div>

                <div id="usaCities" style={{ display: "none" }}>
                  {!countryActive && (
                    <div className="text_container inner_container">
                      <h2 className="title">USA</h2>
                    </div>
                  )}
                  <CustomAccordion firstActive={true} items={usaArr} />
                </div>

                <div id="canadaCities" style={{ display: "none" }}>
                  {!countryActive && (
                    <div className="text_container inner_container">
                      <h2 className="title">Canada</h2>
                    </div>
                  )}
                  <CustomAccordion firstActive={true} items={canadaArr} />
                </div>

                <div
                  id="returnToGlobe"
                  className="return_cta"
                  style={{ display: "none" }}
                >
                  Return to globe
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="ca_sec4">
        <img
          width={1920}
          height={719}
          src={width > 600 ? becomeoneofus : mbbecomeoneofus}
          alt="becomeoneofus"
          className="bg_img wow fadeIn"
          loading="lazy"
        />
        <div className="sec4_content wow fadeInUp">
          <div className="my_container">
            <h2 className="join_title">BECOME ONE OF US</h2>
            <p className="join_desc">
              Join us and become part of our global team as <br />
              we shape the future of ethnic foods.
            </p>
            <div className="cta_wrapper">
              <Link to={careerListingURL} className="button career_cta">
                Apply
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="ca_sec5">
        <div className="my_container">
          <div className="sec5_content">
            <h2 className="team_title wow fadeInUp">VOICES OF OUR TEAM</h2>
          </div>
        </div>
        <div
          className="my_container full_width_container no_padding_container"
          // style={{ paddingLeft: leftMargin, marginRight: leftMargin }}
        >
          <div className="voiceSlider swiper-container">
            <div className="swiper-wrapper">{voicesList}</div>
            <div className="swiper-pagination wow fadeInUp"></div>
          </div>
        </div>
      </section>

      <div className="ca_sec6">
        <div className="my_container">
          <h2 className="benefit_title wow fadeInUp">
            UNLOCK YOUR POTENTIAL &<br /> ENJOY A WORLD OF BENEFITS
          </h2>
          <div className="benefit_row">
            <div className="benefit_col wow fadeInUp health_wrapper">
              <img
                src={width > 600 ? healthBenfits : mbhealthBenfits}
                alt="health"
                className="benefit_img"
              />
              <div className="content_wrapper">
                <img
                  src={healthBenfitsIcon}
                  alt="health"
                  className="benefit_icon"
                />
                <h4 className="benefit_heading">HEALTH COVERAGE</h4>
              </div>
            </div>
            <div className="benefit_col wow fadeInUp retirement_wrapper">
              <img
                src={width > 600 ? retirementBenfits : mbretirementBenfits}
                alt="Retirement"
                className="benefit_img"
              />
              <div className="content_wrapper">
                <img
                  src={retirementBenfitsIcon}
                  alt="Retirement"
                  className="benefit_icon"
                />
                <h4 className="benefit_heading">RETIREMENT BENEFITS</h4>
              </div>
            </div>
            <div className="benefit_col wow fadeInUp pto_wrapper">
              <img
                src={width > 600 ? ptoBenfits : mbptoBenfits}
                alt="PTO"
                className="benefit_img"
              />
              <div className="content_wrapper">
                <img src={ptoBenfitsIcon} alt="PTO" className="benefit_icon" />
                <h4 className="benefit_heading">PAID TIME OFF (PTO)</h4>
              </div>
            </div>
            <div className="benefit_col wow fadeInUp work_wrapper">
              <img
                src={width > 600 ? flexWorkBenfits : mbflexWorkBenfits}
                alt="work"
                className="benefit_img"
              />
              <div className="content_wrapper">
                <img
                  src={flexWorkBenfitsIcon}
                  alt="work"
                  className="benefit_icon"
                />
                <h4 className="benefit_heading">FLEX WORK SCHEDULE</h4>
              </div>
            </div>
            <div className="benefit_col wow fadeInUp dev_wrapper">
              <img
                src={width > 600 ? caDevBenfits : mbcaDevBenfits}
                alt="career development"
                className="benefit_img"
              />
              <div className="content_wrapper">
                <img
                  src={caDevBenfitsIcon}
                  alt="career development"
                  className="benefit_icon"
                />
                <h4 className="benefit_heading">
                  CAREER DEVELOPMENT OPPORTUNITIES
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="ca_sec7">
        <div className="my_container">
          <h2 className="section_title wow fadeInUp">
            BUILDING LIFETIME MEMORIES AT EVERY STAGE
          </h2>
        </div>
        <div className="hos_life_slider_container">
          <div className="swiper-container life_slider">
            <div className="swiper-wrapper">
              {hosLifeData.map((item, i) => (
                <div className="swiper-slide" key={i}>
                  <div className="my_container custom_container">
                    <img
                      width={1540}
                      height={687}
                      className="hero_img"
                      src={width > 600 ? item.mainImg : item.mbMainImg}
                      alt="life at HOS gallery wow fadeInUp"
                      loading="lazy"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="arrows_wrapper wow fadeInUp">
              <div className="life-swiper-prev">
                <img
                  width={74}
                  height={74}
                  className="nav_arrow left_arrow"
                  src={leftArrowCV2}
                  alt="swlider navigation"
                  loading="lazy"
                />
              </div>
              <div className="life-swiper-next">
                <img
                  width={74}
                  height={74}
                  className="nav_arrow right_arrow"
                  src={rightArrowCV2}
                  alt="swlider navigation"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="my_container custom_container">
            <div className="swiper-container life_slider_thumbnail wow fadeInUp">
              <div className="swiper-wrapper">
                {hosLifeData.map((item, i) => (
                  <div className="swiper-slide" key={i}>
                    <img
                      width={169}
                      height={123}
                      className="thumb_img"
                      src={item.thumb}
                      alt="life at HOS gallery"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ApplyStickyCta />
    </>
  );
};

export default CareersV4;
