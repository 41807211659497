import { SmileOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

const Index = () => {
  return (
    <Result
    status="success"
    title="Welcome Back!"
    
  />
  )
}

export default Index