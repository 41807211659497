import axios from "axios";

import { notification } from "antd";

import moment from "moment";
import urldata from "../urldata";
import config from "../config/config";

import jwtDecode from "jwt-decode";
const helper = {
  validateSlug: async (slug, modelName) => {
    try {
      const { data: res } = await axios.post(`${urldata}general/validation`, {
        slug,
        modelName,
      });

      if (res.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      notification["error"]({
        message: "Internal Server Error",
      });
      console.log("error", error.response);
      //   return false;
    }
  },

  ISTDate: (date, format = "YYYY-MM-DD HH:mm:ss") => {
    return moment(date).utc().utcOffset("+05:30").format(format);
  },
  getUser: () => {
    const token = localStorage.getItem(config.jwt_store_key);
    return jwtDecode(token);
  },
  validateSize: (field, file, size, errors) => {
    console.log({helperFile:file})
    if (file) {
      if (file.file.size > size * 1024 * 1024) {
        errors[field] = `File needs to be under ${size}MB`;
      }
      return false;
    }
    return false;
  },

  validateExt: (field, file, extensions, errors) => {
    if (file) {
      if (!extensions.includes(file.file.type)) {
        errors[field] = "File does not have a valid file extension.";
      }
      return false;
    }
    return false;
  },
};
export default helper;
