import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Markup } from "interweave";
import WOW from "wowjs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import parallax from "../../images/about/vision-mission-2560.jpg";

import "react-quill/dist/quill.snow.css";
import banner1 from "../../images/about/about-us-banner-2560.jpg";
import mbanner1 from "../../images/about/about-us-banner-640.jpg";
import aboutSecimg from "../../images/about/aboutSec2.png";
import visionIcon from "../../images/about/visionIcon.svg";
import missionIcon from "../../images/about/missionIcon.svg";
import teamperson1 from "../../images/about/neil-soni.png";
import teamperson2 from "../../images/about/amrapali-soni.png";
import teamperson3 from "../../images/about/mitesh-jain.png";
import teamperson4 from "../../images/about/sundeep-lamba.png";
import teamperson5 from "../../images/about/shashikant-jina.png";
import teamperson6 from "../../images/about/suhasinee-patil.png";
import teamperson7 from "../../images/about/rohina-ahmadi.png";

import BannerItem from "../../component/BannerItem/BannerItem";
import OurTeamSlide from "../../component/OurTeamSlide/OurTeamSlide";
import useWindowDimensions from "../../useWindowDimensions";

import axios from "axios";
import urldata from "../../urldata";
import loadergif from "../../images/loader.gif";
import Imageurldata from "../../Imageurldata";

import "./style.less";

// const ourTeamArr = [
//   {
//     img: teamperson1,
//     teampersonname: "Neil G. Soni",
//     teampersondesg: "President",
//     teampersondesc:
//       "Neil G. Soni is President and Chairman of the Board of House of Spices (India). In this role, he leverages the company’s resources to achieve the highest level of consumer satisfaction for a range of portfolio food products. Neil spearheads strategies to drive HoS growth.",
//   },
//   {
//     img: teamperson2,
//     teampersonname: "Amrapali Soni",
//     teampersondesg: "Chief Operation Officer",
//     teampersondesc:
//       "Amrapali Soni is active in product and business development, technical innovation, minimizing overhead, rebranding and marketing initiatives. She keeps up with cutting-edge food and packaging trends by attending leading industry trade shows.",
//   },
//   {
//     img: teamperson3,
//     teampersonname: "Mitesh Jain",
//     teampersondesg: "Chief Financial Officer",
//     teampersondesc:
//       "Mitesh is responsible for the overall financial strategy and direction at HoS, ensuring a balanced portfolio of growth initiatives and maintaining high levels of integrity and transparency.",
//   },
//   {
//     img: teamperson4,
//     teampersonname: "Sundeep Lamba",
//     teampersondesg: "Chief Sales & Marketing Officer",
//     teampersondesc:
//       "Sundeep Singh Lamba is a successful leader with 22+ years of experience. His achievements span Sales and Marketing functions, the development of Distribution Networks and Sales Teams, as well as achieving high market share in a competitive space. He specializes in Brand Building.",
//   },
//   // {
//   //   img: teamperson5,
//   //   teampersonname: "Shashikant Jina",
//   //   teampersondesg: "Chief Information Officer",
//   //   teampersondesc:
//   //     "Shashi is a Senior IT Business Transformation professional with over 20 years of global progressive experience across multiple sectors, including Manufacturing, Retail, Healthcare, Distribution, and Logistics.",
//   // },
//   {
//     img: teamperson6,
//     teampersonname: "Suhasinee Patil",
//     teampersondesg: "VP, Marketing",
//     teampersondesc:
//       "Suhasinee is a Marketing professional with over 18+ years’ experience in International Business. She has worked closely with various leading Indian food brands and handled markets like North America, UK and Middle East. Over the last decade she has specialized in Multicultural Marketing in North America specific to South Asian and Middle Eastern diaspora.",
//   },
//   {
//     img: teamperson7,
//     teampersonname: "Rohina Ahmadi",
//     teampersondesg: "VP, HR",
//     teampersondesc:
//       "Certified business professional with a concentration in HR. Internal consultant who promotes employee-oriented and high-performing culture while emphasizing diversity, goal attainment, and superior workforce optimization. Credible data driven leader resulting in the alignment of HR initiatives with organizational strategy as well as monitoring and measuring results.",
//   },
// ];

const About = (props) => {
  const { height, width } = useWindowDimensions();

  const [loading, setLoading] = useState(true);
  const [aboutBanner, setaboutBanner] = useState([]);
  const [aboutsection2, setaboutsection2] = useState([]);
  const [aboutsection3, setaboutsection3] = useState([]);
  const [aboutsection4, setaboutsection4] = useState([]);

  // getAboutusData

  useEffect(() => {
    window.scroll(0, 0);

    let url = new URL(urldata + "user/getAboutusData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);

        const aboutdata = response.data.data;
        setaboutBanner(aboutdata.bannerData);
        setaboutsection2(aboutdata.aboutussecTwo);
        setaboutsection3(aboutdata.aboutussecThree);
        setaboutsection4(aboutdata.aboutussecFour);
        setLoading(false);
      })
      .catch((error) => {
        alert("Something Went Wrong");
      });

    document.title =
      "About Us - Largest South Asian food distributors with 11 warehouses";
    document.getElementsByTagName("META")[3].content =
      "With over 48 years of experience in the market, HOS Global Foods is the most popular choice for quality South Asian flavours in the USA.";
    document.getElementsByTagName("META")[4].content =
      "South Asian flavours, ethnic food, Indian Spices";

    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  if (loading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }

  return (
    <>
      <section className="aboutsec1 wow fadeIn">
        <BannerItem
          img={
            width > 767
              ? Imageurldata + aboutBanner[0].imgUrl
              : Imageurldata + aboutBanner[0].mbimgUrl
          }
          title={aboutBanner[0].title}
          desc={aboutBanner[0].text1}
        />
      </section>
      <section className="aboutsec2 wow fadeIn">
        <Row className="row m-0 a-c">
          <Col lg={5}>
            <img
              className="img-fluid"
              src={Imageurldata + aboutsection2[0].img}
            />
          </Col>
          <Col lg={7} style={{ paddingBottom: "20px" }}>
            {/* <p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: aboutsection2[0].desc.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
            {/* </p> */}
          </Col>
        </Row>
      </section>
      <section className="aboutsec3 wow fadeIn">
        <div className="aboutsec3bg">
          <div
            style={{
              backgroundImage: `url(${Imageurldata + aboutsection3[0].img1})`,
            }}
            className="parallaxContainer"
          >
            <div className="visionMissionCards">
              <div className="visionMissionCard wow slideInLeft">
                <div className="icon">
                  <img
                    className="img-fluid"
                    src={Imageurldata + aboutsection3[0].img2}
                  />
                </div>
                <div className="title">{aboutsection3[0].imgtitle2}</div>
                <div className="desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutsection3[0].descimg2.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                </div>
              </div>
              <div className="visionMissionCard wow slideInRight">
                <div className="icon">
                  <img
                    className="img-fluid"
                    src={Imageurldata + aboutsection3[0].img3}
                  />
                </div>
                <div className="title">{aboutsection3[0].imgtitle3}</div>
                <div className="desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutsection3[0].descimg3.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutsec4 wow fadeIn">
        <div className="title">OUR TEAM</div>
        <OurTeamSlide aboutsection4={aboutsection4} />
      </section>
    </>
  );
};

export default About;
