import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Form,
  Input,
  notification,
  Upload,
  Button,
  Spin,
} from "antd";

import Edit from "./Edit";
import _ from "lodash";
import http from "../../../../helpers/http";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";

import urldata from "../../../../urldata";
import "./style.css";
import Joi from "joi";
function Index() {
  const [editOpen, setEditOpen] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [btnloading, setbtnloading] = useState(false);

  //createKey and editKey is used to reset modal data on close

  const [editKey, seteditKey] = useState(Math.random() * 10);

  const moduleName = "Partner Product Content";
  const moduleNamePural = "Partner Page Content";
  const base_url = urldata + "admin/partner-products/content"; //without trailing slash
  const image_url = urldata + "public/uploads/product/content/"; //with trailing slash
  const sizeLimit = 10;

  const schema = Joi.object({}).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = schema.validate(value, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.catalog) {
      if (value.catalog.file.size > sizeLimit * 1024 * 1024) {
        errors["catalog"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = ["application/pdf"];
      if (!allowedExt.includes(value.catalog.file.type)) {
        errors["catalog"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setbtnloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["catalog"]))) {
      formData.append(key, data || "");
    }

    if (value.catalog) {
      formData.append("catalog", value.catalog.file);
    }

    const { data } = await http.put(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setbtnloading(false);
      form.resetFields();
      fetchData();
    }
  };

  const fetchData = async (id) => {
    setloading(true);
    const { data } = await http.get(base_url);

    if (data) {
      setData(data);
    }
    setloading(false);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [form] = Form.useForm();

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Partner Catalogue</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card
              title={moduleNamePural}
              bordered={true}
              style={{ boxShadow: "10px 0px 15px rgba(0,0,0,0.1)" }}
            >
              <Row style={{ marginBottom: "20px" }}></Row>

              <Form
                form={form}
                onFinish={handleSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="Catalogue:"
                      name="catalog"
                      required
                      {...(formError.catalog && {
                        help: formError.catalog,
                        validateStatus: "error",
                      })}
                    >
                      <Upload
                        beforeUpload={(file) => {
                          return false;
                        }}
                        maxCount={1}
                        listType="text"
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: false,
                          showDownloadIcon: true,
                          downloadIcon: <DownloadOutlined />,
                        }}
                        defaultFileList={[
                          {
                            status: "done",
                            url: image_url + data.catalog,
                            name: data.catalog,
                          },
                        ]}
                      >
                        <Button icon={<EditOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button
                        type="danger"
                        htmlType="submit"
                        loading={btnloading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
