import React, { useState, useEffect } from "react";
import moment from "moment";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

const FooterSocialMedia = (props) => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [updateModel, setUpdateModel] = useState(false);
  
    const [socialid, setsocialid] = useState("");
  
    const [show, setShow] = useState(false);
  
    const [sociallink, setsociallink] = useState("");
    const [sociallinkErr, setsociallinkErr] = useState("");
  
    const [bannerImg, setBannerImg] = useState("");
    const [bannerImgErr, setBannerImgErr] = useState("");
  
    const [imgNotPresent, setImgNotPresent] = useState('')
  
    const [showImg, setShowImg] = useState('')
  
    const [toastlist, setToastList] = useState([]);
  
    let toastProperties = null;
   
    useEffect(() => {
      let url = new URL(urldata + "admin/getsocial");
      setLoading(true);
      axios({
        method: "get",
        url: url,
        withCredentials: true,
      })
        .then((response) => {
          const templist = response.data.data.reverse();
          setList(templist);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, []);
  
    const showToast = (type) => {
      const id = Math.floor(Math.random() * 101 + 1);
      switch (type) {
        case "success":
          toastProperties = {
            id,
            title: "Success",
            description: `Social Media data ${updateModel? 'updated': 'saved'} Successfully`,
            backgroundColor: "#329F33",
            icon: check,
          };
          break;
        case "danger":
          toastProperties = {
            id,
            title: "Something Went Wrong",
            description: "",
            backgroundColor: "red",
            icon: close,
          };
          break;
        case "delete":
          toastProperties = {
            id,
            title: "Success",
            description: "Social Media data deleted Successfully",
            backgroundColor: "#329F33",
            icon: check,
          };
          break;
  
        default:
          setToastList([]);
      }
  
      setToastList([...toastlist, toastProperties]);
    };
  
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShowImg(false)
      setBannerImg("");
  
      setUpdateModel(false);
      setsociallink("");
      
      setShow(true);
    };

    const handleShowForUpdate = (rowData) => {
      setShow(true);
      setUpdateModel(true);
      setBannerImg(Imageurldata+rowData.imgUrl);
      setShowImg(false);
      setsociallink(rowData.sociallink);
      setsocialid(rowData._id);
      setImgNotPresent(rowData.imgUrl ? false: true)
  
    };
  
    const updateTvAd = () => {
      let formData = new FormData();
      formData.append("sociallink", sociallink);
  
      if(imgNotPresent){
        formData.append("banner", bannerImg);
        setImgNotPresent(false)
      }
  
      let url = new URL(urldata + "admin/updatesocial");
  
      url.searchParams.append("id", socialid);
      var decoded = decodeURIComponent(url);
  
      setLoading(true);
      axios({
        method: "post",
        url: decoded,
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          const templist = response.data.data.reverse();
          setList(templist);
          setShow(false);
          setLoading(false);
          showToast("success");
          
        })
        .catch((error) => {
          setLoading(false);
        });
    };
  
    const addTvAd = () => {
      let formData = new FormData();
      formData.append("sociallink", sociallink);
      formData.append("banner", bannerImg);
  
      let url = new URL(urldata + "admin/addsocial");
  
      setLoading(true);
      axios({
        method: "post",
        url: url,
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          const tempList = response.data.data.reverse();
          setList(tempList);
          setShow(false);
          setLoading(false);
          showToast('success')
        })
        .catch((error) => {
          setLoading(false);
        });
    };
  
    const submit = () => {
      let formIsValid = true;
  
      if (!sociallink) {
        formIsValid = false;
        setsociallinkErr("*Please add social media link");
      } else {
        setsociallinkErr("");
      }
  
      if (!bannerImg) {
        formIsValid = false;
        setBannerImgErr("*Please social media image");
      } else {
        setBannerImgErr("");
      }
  
      if (formIsValid) {
        if (updateModel) {
          updateTvAd();
        } else {
          addTvAd();
        }
      }
    };
  
    const deleteBtnClick = (id) => {
    //   let data = {};
    //   data.id = id;
  
      let url = new URL(urldata + "admin/deletesocial");
  
      url.searchParams.append("id", id);
      var decoded = decodeURIComponent(url);


      setLoading(true);
      axios({
        method: "post",
        url: decoded,
        // data: data,
        withCredentials: true,
      })
        .then((response) => {
          const templist = response.data.data.reverse();
          setList(templist);
          setLoading(false);
          showToast("delete");
        })
        .catch((error) => {
          // showToast("danger");
          console.error(error);
          setLoading(false);
        });
    };
  
    if (loading) {
      return (
        <div id="wrapper">
          <div className="profile-main-loader">
            <div className="loader">
              <svg className="circular-loader" viewBox="25 25 50 50">
                <circle
                  className="loader-path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke="#ed1c24"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
      );
    }
  
  
    return (
      <>
        <div className="addjobinlist text-right mb-4">
          <Button title="Add Social Media" onClick={handleShow} />
        </div>
        <MaterialTable
          title="Social Media list"
          columns={[
            { title: "Social Link", field: "sociallink" },
            {
              field: "imgUrl",
              title: "Social Media Image",
              render: (rowData) => (
                <div className="d-f">
                  <Button
                    title="View"
                    onClick={() => {
                      window.open(Imageurldata + rowData.imgUrl, "_blank");
                    }}
                  ></Button>
                </div>
              ),
            },
            {
              field: "url",
              title: "Action",
              render: (rowData) => (
                <div className="d-f">
                  <Button
                    title=" Update"
                    onClick={() => {
                      handleShowForUpdate(rowData);
                    }}
                  ></Button>
                  <Button
                    title=" Delete"
                    onClick={() => deleteBtnClick(rowData._id)}
                  ></Button>
                </div>
              ),
            },
          ]}
          data={list}
          options={{
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
  
        <Modal
          {...props}
          dialogClassName="addbannerModel"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="model" style={{ position: "relative" }}>
            <div className="title">
              {updateModel ? "Update Social Media" : "Add Social Media"}
            </div>
  
            <Row>
              {/* col 1 */}
              <Col lg={3} className="column">
                <div className="titleitem">Social Media Link</div>
              </Col>
              <Col lg={9} className="column">
                <input
                  value={sociallink}
                  onChange={(e) => setsociallink(e.target.value)}
                  type="text"
                />
                <div className="err">{sociallinkErr}</div>
              </Col>
  
              {/* col-4 */}
              <Col lg={3} className="column">
                <div className="titleitem">Social Media Image</div>
                <div className="desc">Size :- 42 × 30</div>
              </Col>
              <Col lg={9} className="column">
                <input
                  id="bannerImage"
                  accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                  type="file"
                  onChange={(e) => {
                    setBannerImg(e.target.files[0]);
                    setShowImg(true);
                    setImgNotPresent(true);
                  }}
                  name="passport_proof_upload"
                />
  
                <label htmlFor="bannerImage" className="d-f" id="file-drag">
                  <img src={upload} className="img-fluid" />
                  <div className="chooseTxt">Choose File</div>
                </label>
  
                <div className="d-f jc-sb">
                  <div className="fileTxt">File Format : JPG / png</div>
                  <div className="fileTxt text-primary">
                    {bannerImg ? bannerImg.name : ""}
                  </div>
                </div>
                <div className="err">{bannerImgErr} </div>
              </Col>
            </Row>
  
            <div className="btnContainer btn_press mt-4">
              <Button onClick={submit} title="SUBMIT" />
            </div>
            <div className="text-right">
              {bannerImg !== "" && (
                <img
                  src={showImg ? URL.createObjectURL(bannerImg) : bannerImg}
                  alt=""
                  style={{ width: "100px", height: "100px", backgroundColor: 'gray' }}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Toast toastList={toastlist} position="top-right" />
      </>
    );
}

export default FooterSocialMedia
