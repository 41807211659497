import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { Markup } from "interweave";
import WOW from "wowjs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "react-quill/dist/quill.snow.css";

import BannerItem from "../../component/BannerItem/BannerItem";
import useWindowDimensions from "../../useWindowDimensions";

import "./style.less";

import banner from "../../images/privacypolicy/banner.jpg";
import mbanner from "../../images/privacypolicy/mbanner.jpg";
import urldata from "../../urldata";
import Imageurldata from "../../Imageurldata";
import axios from "axios";

const PrivacyPolicy = (props) => {
  const policyRef = useRef();
  const { height, width } = useWindowDimensions();
  const [bannerData, setBannerData] = useState("");
  const [policyDetails, setPolicyDetails] = useState("");
  // console.log(bannerData)
  // console.log(policyDetails.introduction)
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Hos - Privacy Policy";

    new WOW.WOW({
      live: false,
    }).init();

    let url = new URL(urldata + "admin/getsingleImages");
    url.searchParams.append("screen", "privacypolicy");
    let decoded = decodeURIComponent(url);
    axios({
      method: "post",
      url: decoded,
      withCredentials: true,
    })
      .then((response) => {
        setBannerData(response.data.data[0]);
        // console.log(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    let policyDetailsUrl = new URL(urldata + "user/getPrivacyPolicyData");
    axios({
      method: "get",
      url: policyDetailsUrl,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response)
        setPolicyDetails(response.data.data.privacypolicy[0]);
        // console.log(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
  //   const h2Ele = document.querySelectorAll("#ppintro h2");
  //   console.log(h2Ele);
  //   const h2EleArr = Array.from(h2Ele);

  // h2EleArr.forEach((ele) => {
  //   ele.className = "title";
  // });
  //   const pEle = document.querySelectorAll("#ppintro p");
  //   console.log(pEle);
  //   const pEleArr = Array.from(pEle);

  // pEleArr.forEach((ele) => {
  //   ele.className = "desc";
  // });
    }, []);
   
  return (
    <>
      <section className="ppsec1 wow fadeIn">
        <BannerItem
          img={
            width > 767
              ? Imageurldata + bannerData.imgUrl
              : Imageurldata + bannerData.mbimgUrl
          }
          title={bannerData.title}
          desc={bannerData.text1}
        />
      </section>
      <section className="ppsec2 wow fadeIn">
        {policyDetails.introduction ? (
          <div
            ref={policyRef}
            id="ppintro"
            dangerouslySetInnerHTML={{
              __html: policyDetails.introduction.replace(
                /(<? *script)/gi,
                "illegalscript"
              ),
            }}
          ></div>
        ) : (
          ""
        )}
        {/* <div className="content_box">
          <div className="title">PERSONAL INFORMATION WE COLLECT</div>
          <div className="desc">
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information.”
          </div>
        </div>
        <div className="content_box">
          <div className="title">
            We collect Device Information using the following technologies:
          </div>
          <div className="desc">
            <ul>
              <li>
                “Cookies” are data files that are placed on your device or
                computer and often include an anonymous unique identifier. For
                more information about cookies, and how to disable cookies,
                visit{" "}
                <a href="http://www.allaboutcookies.org" target="_blank">
                  http://www.allaboutcookies.org
                </a>
                .
                <br />
                <br />
              </li>
              <li>
                “Log files” track actions occurring on the Site, and collect
                data including your IP address, browser type, Internet service
                provider, referring/exit pages, and date/time stamps.
                <br />
                <br />
              </li>
              <li>
                “Web beacons,” “tags,” and “pixels” are electronic files used to
                record information about how you browse the Site.
                <br />
                <br />
              </li>
            </ul>
            Additionally, when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information
            (including credit card numbers, email address, and phone number. We
            refer to this information as “Order Information.”
            <br />
            <br />
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Order Information.
          </div>
        </div>
        <div className="content_box">
          <div className="title">HOW DO WE USE YOUR PERSONAL INFORMATION?</div>
          <div className="desc">
            We use the Order Information that we collect generally to fulfill
            any orders placed through the Site (including processing your
            payment information, arranging for shipping, and providing you with
            invoices and/or order confirmations). Additionally, we use this
            Order Information to:
            <br />
            <br />
            Communicate with you;
            <br />
            <br />
            Screen our orders for potential risk or fraud; and
            <br />
            <br />
            When in line with the preferences you have shared with us, provide
            you with information or advertising relating to our products or
            services.
            <br />
            <br />
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (your IP address), and more generally to
            improve and optimize our Site (for example, by generating analytics
            about how our customers browse and interact with the Site, and to
            assess the success of our marketing and advertising campaigns).
          </div>
        </div>
        <div className="content_box">
          <div className="title">SHARING YOUR PERSONAL INFORMATION</div>
          <div className="desc">
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above. For example, we use
            Shopify to power our online store–you can read more about how
            Shopify uses your Personal Information here:&nbsp;
            <a target="_blank" href="https://www.shopify.com/legal/privacy">
              https://www.shopify.com/legal/privacy
            </a>
            . We also use Google Analytics to help us understand how our
            customers use the Site –you can read more about how Google uses your
            Personal Information here:&nbsp;
            <a
              target="_blank"
              href="https://www.google.com/intl/en/policies/privacy/"
            >
              https://www.google.com/intl/en/policies/privacy/
            </a>
            . You can also opt-out of Google Analytics here:&nbsp;
            <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .<br />
            <br /> Finally, we may also share your Personal Information to
            comply with applicable laws and regulations, to respond to a
            subpoena, search warrant or other lawful request for information we
            receive, or to otherwise protect our rights.
          </div>
        </div>
        <div className="content_box">
          <div className="title">BEHAVIOURAL ADVERTISING</div>
          <div className="desc">
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at&nbsp;
            <a
              target="_blank"
              href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
            >
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
            </a>
            .
          </div>
        </div>
        <div className="content_box">
          <div className="desc">
            <b>You can opt out of targeted advertising by:</b>
            <br />
            <br />
            COMMON LINKS INCLUDE: <br />
            <br />
            <ul>
              <li>
                <b>FACEBOOK</b> –
                <a
                  href="https://www.facebook.com/settings/?tab=ads"
                  target="_blank"
                >
                  https://www.facebook.com/settings/?tab=ads
                </a>
                <br />
                <br />
              </li>
              <li>
                <b>GOOGLE</b> –
                <a
                  href="https://www.google.com/settings/ads/anonymous"
                  target="_blank"
                >
                  https://www.google.com/settings/ads/anonymous
                </a>
                <br />
                <br />
              </li>
              <li>
                <b>BING</b> –
                <a
                  href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                  target="_blank"
                >
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </a>
                <br />
                <br />
              </li>
            </ul>
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance’s opt-out portal at:&nbsp;
            <a href="http://optout.aboutads.info/" target="_blank">
              http://optout.aboutads.info/
            </a>
            .
          </div>
        </div>
        <div className="content_box">
          <div className="title">DO NOT TRACK</div>
          <div className="desc">
            Please note that we do not alter our Site’s data collection and use
            practices when we see a Do Not Track signal from your browser.
          </div>
        </div>
        <div className="content_box">
          <div className="title">YOUR RIGHTS</div>
          <div className="desc">
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
            <br></br>
            <br></br>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </div>
        </div>
        <div className="content_box">
          <div className="title">DATA RETENTION</div>
          <div className="desc">
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </div>
        </div>
        <div className="content_box">
          <div className="title">CHANGES</div>
          <div className="desc">
            We may update this privacy policy from time to time to reflect, for
            example, changes to our practices or for other operational, legal or
            regulatory reasons.
          </div>
        </div>
        
         */}

        <div className="content_box">
          <div className="title">{policyDetails.contacttitle}</div>
          <div className="desc">
            {policyDetails.contactdesc ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: policyDetails.contactdesc.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            ) : (
              ""
            )}
            {/* For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at{" "} */}
            {/* <a href="mailto:orders@hosindia.com">orders@hosindia.com</a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
