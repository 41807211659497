import React, { useEffect, useState } from "react";
import { Modal, Form, Input, notification, Row, Col, Select } from "antd";

import Joi from "joi";

import _ from "lodash";
import http from "../../../../helpers/http";
import config from "../../../../config/config";
import urldata from "../../../../urldata";


function Edit({ isVisible, handleClose, dbData, moduleName, categories }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();

  const base_url = urldata + "admin/users"; //without trailing slash
  const image_url = urldata + "uploads/user/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;
  const { TextArea } = Input;

  const schema = Joi.object({
    role: Joi.string().trim().required().label("Role"),

    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string().trim().required().label("Password"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "description"]));

    setDescription(() => {
      return dbData.description;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
      <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Role:"
              name="role"
              {...(formError.role && {
                help: formError.role,
                validateStatus: "error",
              })}
            >
              <Select>
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Email:"
              name="email"
              {...(formError.email && {
                help: formError.email,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Password:"
              name="password"
              {...(formError.password && {
                help: formError.password,
                validateStatus: "error",
              })}
            >
              <Input.Password visibilityToggle={false} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
