import React, { useState, useEffect } from "react";
import BannerCommon from "../BannerCommon";

const MediaBanner = () => {
    return(
        <BannerCommon header='Media' screenQuery='media'/>
    )
}

export default MediaBanner
