import React, { useState, useEffect } from "react";
import "./style.less";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import thumb from "../../images/tvads/thumb.png";
import playIcon from "../../images/tvads/playIcon.png";
import axios from "axios";
import urldata from "../../urldata";
import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from "swiper";
import sliderImg from "../../images/events/sliderImg.png";
import useWindowDimensions from "../../useWindowDimensions";

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

const EventsModelSlider = (props) => {
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    var swiper;
    if (width > 1023) {
      swiper = new Swiper(".eventsModel .model-swiper", {
        slidesPerView: 2,
        spaceBetween: 32,
        // observer: true,
        centeredSlides: true,
        loop: true,
        // observeParents: true,
        // freeMode: true,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
        pagination: {
          el: ".eventsModel .swiper-pagination",
          clickable: true,
        },
      });
    } else {
      swiper = new Swiper(".eventsModel .model-swiper", {
        slidesPerView: 1,
        spaceBetween: 32,
        // observer: true,
        centeredSlides: true,
        loop: true,
        // observeParents: true,
        // freeMode: true,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
        pagination: {
          el: ".eventsModel .swiper-pagination",
          clickable: true,
        },
      });
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      dialogClassName="eventsDialog"
      //   size="lg"
      //   aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="model eventsModel">
        <div className="modelTitle">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr
        </div>
        <div className="modelDesc">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy lorem ipsum dolor sit amet, consetetur sadipscing
        </div>
        <div className="model-swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
            <div className="swiper-slide">
              <img src={sliderImg} className="img-fluid" />
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EventsModelSlider;
