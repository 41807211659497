import React from 'react'
import BannerCommon from '../BannerCommon'

const ContactBanner = () => {
    return (
        <BannerCommon header='Contact' screenQuery='contact'/>
    )
}

export default ContactBanner
