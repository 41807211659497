import React, { useState, useEffect } from "react";
import "./style.less";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import thumb from "../../images/tvads/thumb.png";
import playIcon from "../../images/tvads/playIcon.png";
import axios from "axios";
import urldata from "../../urldata";

const TvAdsModel = (props) => {
  const [name, setName] = useState("");
  const [nameerr, setNameerr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbererr, setPhoneNumbererr] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailIderr, setEmailIderr] = useState("");
  const [qualification, setQualification] = useState("");
  const [qualificationerr, setQualificationErr] = useState("");
  const [experience, setExperience] = useState("");
  const [experienceerr, setExperienceErr] = useState("");
  const [messageerr, setMessageErr] = useState("");
  const [resume, setResume] = useState("");
  const [hideImg, setHideImg] = useState(false);

  return (
    <Modal
      {...props}
      dialogClassName="tvAdsModel"
      //   size="lg"
      //   aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="model tvads">
        {/* <img src={thumb} className="img-fluid" />
        <img src={playIcon} className="playicon" /> */}
        <div className="videoContainer">
          {props.playVideo ? (
            <iframe
              className="iframes"
              id="areYouRiskReadyVideo"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${props.youtubeId}?rel=0&amp;autoplay=1&mute=0`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ) : (
            <div id="thumbContainer1" className="thumbContainer">
              <img src={props.img} className="img-fluid modelThumbIcon" />
              <img
                onClick={props.onClickVideo}
                src={playIcon}
                className=" playIcon"
              />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TvAdsModel;
