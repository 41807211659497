import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useWindowDimensions from "../../useWindowDimensions";
import WOW from "wowjs";

import banner from "../../images/collaborate/collab-banner-2560.jpg";
import mbanner from "../../images/collaborate/collab-banner-640.jpg";
import collaborateicon1 from "../../images/collaborate/collaborateicon1.svg";
import collaborateicon2 from "../../images/collaborate/collaborateicon2.svg";
import collaborateicon3 from "../../images/collaborate/collaborateicon3.svg";
import parallax from "../../images/collaborate/parallax-banner-2560.jpg";
import arrowimg from "../../images/collaborate/arrowimg.svg";
import close from "../../images/close.svg";
import check from "../../images/check.svg";

import countryList from "../../country";
import BannerItem from "../../component/BannerItem/BannerItem";
import Button from "../../component/Button/Button";
import InputText from "../../component/InputText/InputText";

import axios from "axios";
import urldata from "../../urldata";

import Toast from "../../component/Toast/Toast";
import "./style.less";
import moment from "moment";

import loadergif from "../../images/loader.gif";
import Imageurldata from "../../Imageurldata";

const Collaborate = (props) => {

  useEffect(() => {
    window.scroll(0, 0);

    document.title =
      "Collaborate - Be a part of our esteemed list of vendor partners";
    document.getElementsByTagName("META")[3].content =
      "Be a part of our esteemed list of vendor partners - with whom we have successful long-term partnerships - for a commercially satisfying relationship.";
    document.getElementsByTagName("META")[4].content =
      "HOS Global Foods vendor partners, Business opportunities with HOS Global Foods";

    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const { height, width } = useWindowDimensions();
  const [name, setName] = useState("");
  const [nameerr, setNameerr] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailIderr, setEmailIderr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbererr, setPhoneNumbererr] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteerr, setWebsiteerr] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [companynameerr, setCompanyNameerr] = useState("");
  const [country, setCountry] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [productdescriptionmessage, setProductDescriptionMessage] = useState(
    ""
  );

  const [loading, setLoading] = useState(true);
  const [collaborateBanner, setcollaborateBanner] = useState([]);
  const [collaboratesection2, setcollaboratesection2] = useState([]);
  const [collaboratesection3, setcollaboratesection3] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);

    let url = new URL(urldata + "user/getCollaborateData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);

        const collaboratedata = response.data.data;
        setcollaborateBanner(collaboratedata.bannerData);
        setcollaboratesection2(collaboratedata.collaborateSecTwo);
        // console.log('collaboratedata.collaborateSecTwo----->', collaboratedata.collaborateSecTwo)
        setcollaboratesection3(collaboratedata.collaborateSecThree);
        setLoading(false);
      })
      .catch((error) => {
        alert("Something Went Wrong");
      });
  }, []);

 

  let toastProperties = null;

  const [
    productdescriptionmessageerr,
    setProductDescriptionMessageerr,
  ] = useState("");

  const submit = () => {
    //console.log(

    var formIsValid = true;

    if (!name) {
      formIsValid = false;
      setNameerr("*Please enter your name.");
    } else {
      setNameerr("");
    }

    if (typeof name !== "undefined") {
      if (!name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setNameerr("*Please enter alphabet characters only.");
      }
    }

    if (typeof emailId !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(emailId)) {
        formIsValid = false;
        setEmailIderr("*Please enter valid email-Id.");
      } else {
        setEmailIderr("");
      }
    }

    if (typeof phoneNumber !== "undefined") {
      if (!phoneNumber.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        setPhoneNumbererr("*Please enter valid mobile no.");
      } else {
        setPhoneNumbererr("");
      }
    }

    if (!companyname) {
      formIsValid = false;
      setCompanyNameerr("*Please enter your company name.");
    } else {
      setCompanyNameerr("");
    }

    if (!country) {
      formIsValid = false;
      setCountryerr("*Please enter your country.");
    } else {
      setCountryerr("");
    }

    if (!productdescriptionmessage) {
      formIsValid = false;
      setProductDescriptionMessageerr(
        "*Please enter your product description/message."
      );
    } else {
      setProductDescriptionMessageerr("");
    }

    if (formIsValid) {
      // //console.log(props.id);

      let data = {};
      data.name = name;
      data.email = emailId;
      data.phoneNumber = phoneNumber;
      data.website = website;
      data.companyName = companyname;
      data.country = country;
      data.message = productdescriptionmessage;
      data.date = moment().format("DD-MM-YYYY");
      data.time = moment().format("hh:mm a");

      let url = new URL(urldata + "user/collborateForm");

      axios({
        method: "post",
        url: url,
        data: data,
        withCredentials: true,
      })
        .then((response) => {
          setProductDescriptionMessage("");
          setCountry("");
          document.getElementById("country").selectedIndex = -1;
          setCompanyName("");
          setWebsite("");
          setPhoneNumber("");
          setEmailId("");
          setName("");
          showToast("success");
        })
        .catch((error) => {
          // alert("Something Went Wrong");
          showToast("danger");
        });
    }
  };

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "Your Form Sumbitted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "success2":
        toastProperties = {
          id,
          title: "Success",
          description: "Your Resume Uploaded Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setList([]);
    }

    setList([...list, toastProperties]);
  };

   if (loading) {
     return (
       <div className="loadergifdiv">
         <img src={loadergif} className="img-fluid" />
       </div>
     );
   }
  return (
    <>
      <section className="collaboratesec1 wow fadeIn">
        <BannerItem
          img={
            width > 767
              ? Imageurldata + collaborateBanner[0].imgUrl
              : Imageurldata + collaborateBanner[0].mbimgUrl
          }
          title={collaborateBanner[0].title}
          desc=""
        />
      </section>
      <section className="collaboratesec2 wow fadeIn">
        <div className="title">{collaboratesection2[0].title}</div>
        <Row className="m-0 text-center">
          <Col lg={4}>
            <div className="benefits_data_div">
              <div className="benefit_icon_div">
                <img
                  src={Imageurldata + collaboratesection2[0].img1}
                  className="img-fluid"
                />
              </div>
              <div className="benefit_data_line">
                {collaboratesection2[0].imgtitle1}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="benefits_data_div">
              <div className="benefit_icon_div">
                <img
                  src={Imageurldata + collaboratesection2[0].img2}
                  className="img-fluid"
                />
              </div>
              <div className="benefit_data_line">
                {collaboratesection2[0].imgtitle2}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="benefits_data_div">
              <div className="benefit_icon_div">
                <img
                  src={Imageurldata + collaboratesection2[0].img3}
                  className="img-fluid"
                />
              </div>
              <div className="benefit_data_line">
                {collaboratesection2[0].imgtitle3}
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="collaboratesec3 wow fadeIn">
        <div
          style={{
            backgroundImage: `url(${
              Imageurldata + collaboratesection3[0].img
            })`,
          }}
          className="parallaxContainer"
        >
          <div className="parallaxdata">
            <div className="arrowimg">
              <img src={arrowimg} alt="" className="img-fluid" />
            </div>
            <div className="parallaxline">{collaboratesection3[0].desc}</div>
          </div>
        </div>
      </section>
      <section className="collaboratesec4 wow fadeIn">
        <div className="title">GET IN TOUCH WITH US</div>
        <div className="desc">Tell us more about your business</div>
        <div className="shareyourfeedbackformdiv">
          <Row className="m-0">
            <Col lg={6}>
              <InputText
                value={name}
                onChange={(e) => setName(e.target.value)}
                title="Name*"
              />
              <div className="err">{nameerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                title="Email ID*"
              />
              <div className="err">{emailIderr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                title="Phone Number*"
              />
              <div className="err">{phoneNumbererr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                title="Website"
              />
              <div className="err">{websiteerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                value={companyname}
                onChange={(e) => setCompanyName(e.target.value)}
                title="Company Name*"
              />
              <div className="err">{companynameerr}</div>
            </Col>
            <Col lg={6}>
              <InputText
                dropdown
                list={countryList}
                onChange={(e) => setCountry(e.target.value)}
                title="Country*"
                id="country"
              />
              <div className="err">{countryerr}</div>
            </Col>

            <Col lg={12}>
              <InputText
                value={productdescriptionmessage}
                onChange={(e) => setProductDescriptionMessage(e.target.value)}
                title="Product Description/Message*"
              />
              <div className="err">{productdescriptionmessageerr} </div>
            </Col>
          </Row>
          <div className="btnContainer">
            <Button onClick={submit} title="SUBMIT" />
          </div>
        </div>
      </section>

      <Toast toastList={list} position="top-right" />
    </>
  );
};

export default Collaborate;
