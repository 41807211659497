import React, { useState,useEffect } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Input,
  Select,
} from "antd";
import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import http from "../../../../helpers/http";
import config from "../../../../config/config";
import _ from "lodash";
import helper from "../../../../helpers/helper";
import HtmlEditor from "../../../../components/HtmlEditor";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
  countries,
  locations:dbLocations,
  departments,
  experiences,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();
  const [locations, setLocations] = useState([]);

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const { TextArea } = Input;

  const { Option } = Select;

  const validate = (value) => {
    let schemaObj = {
      country: Joi.string().required().label("Country"),
      location: Joi.string().required().label("Location"),
      department: Joi.string().required().label("Department"),
      experience: Joi.string().required().label("Experience"),
      title: Joi.string().required().label("Title"),
      description: Joi.required().label("Description"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleCountryChange = (countryId) => {

    console.log({countryId})
    const filteredLocations = _.filter(dbLocations, (item) => {
      return item.country._id == countryId;
    });

    setLocations(filteredLocations);

    form.setFieldsValue({location:""});
    console.log({filteredLocations});
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "mobile_image"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.mobile_image) {
      formData.append("mobile_image", value.mobile_image.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  useEffect(() => {
   
  setLocations(dbLocations);
   
  }, [dbLocations])
  

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;

            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Country:"
              name="country"
              {...(formError.country && {
                help: formError.country,
                validateStatus: "error",
              })}
            >
              <Select onChange={handleCountryChange}>
                {countries &&
                  countries.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Location:"
              name="location"
              {...(formError.location && {
                help: formError.location,
                validateStatus: "error",
              })}
            >
              <Select>
                {locations &&
                  locations.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Department:"
              name="department"
              {...(formError.department && {
                help: formError.department,
                validateStatus: "error",
              })}
            >
              <Select>
                {departments &&
                  departments.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Experience:"
              name="experience"
              {...(formError.experience && {
                help: formError.experience,
                validateStatus: "error",
              })}
            >
              <Select>
                {experiences &&
                  experiences.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              required
              getValueProps={(i) => {}}
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              {/* <TextArea rows={4} /> */}
              <HtmlEditor
                id="create-editor-2"
                textareaName="description"
                height={350}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
