import { careersDetailsBanner, careersDetailsMobBanner } from "../images";

export const careerDetailBannerData = [
  { banImg: careersDetailsBanner, mobImg: careersDetailsMobBanner },
];

export const jobsData = [
  {
    id: 1,
    title: "Assistant regional Manager",
    location: "New Jersey",
    exp: "3-5 years",
    // link: "javascript:void(0)",
    sharLink: "javascript:void(0)",
    desc: [
      `Prepare and conduct independent, compliance and systems-based audits of our vendors as assigned to verify conformance to the Food Safety Quality, Sustainability, health and safety, and environmental sustainability requirements. Conducting rigorous reviews for information that enhances the assessment of business risk to improve the overall audit process. Leverage investigative skills and techniques to quickly identify risk situations and areas of non-conformance.`,
      ``,
      ``,
    ],
    list: [],
    skills: 
    "Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.",
  },
  {
    id: 2,
    title: "Assistant regional Manager",
    location: "New Jersey",
    exp: "3-5 years",
    // link: "javascript:void(0)",
    sharLink: "javascript:void(0)",
    desc: [],
    list: [
      `Prepare and conduct independent, compliance and systems-based audits of our vendors as assigned to verify conformance to the Food Safety Quality, Sustainability, health and safety, and environmental sustainability requirements.`,
      `Conducting rigorous reviews for information that enhances the assessment of business risk to improve the overall audit process.`,
      `Leverage investigative skills and techniques to quickly identify risk situations and areas of non-conformance. Skills : Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.`,
    ],
    skills:
      "Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.",
  },
  {
    id: 3,
    title: "Assistant regional Manager",
    location: "New Jersey",
    exp: "3-5 years",
    // link: "javascript:void(0)",
    sharLink: "javascript:void(0)",
    desc: [
      `Prepare and conduct independent, compliance and systems-based audits of our vendors as assigned to verify conformance to the Food Safety Quality, Sustainability, health and safety, and environmental sustainability requirements. Conducting rigorous reviews for information that enhances the assessment of business risk to improve the overall audit process. Leverage investigative skills and techniques to quickly identify risk situations and areas of non-conformance.`,
      ``,
      ``,
    ],
    list: [],
    skills: 
    "Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.",
  },
  {
    id: 4,
    title: "Assistant regional Manager",
    location: "New Jersey",
    exp: "3-5 years",
    // link: "javascript:void(0)",
    sharLink: "javascript:void(0)",
    desc: [],
    list: [
      `Prepare and conduct independent, compliance and systems-based audits of our vendors as assigned to verify conformance to the Food Safety Quality, Sustainability, health and safety, and environmental sustainability requirements.`,
      `Conducting rigorous reviews for information that enhances the assessment of business risk to improve the overall audit process.`,
      `Leverage investigative skills and techniques to quickly identify risk situations and areas of non-conformance. Skills : Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.`,
    ],
    skills:
      "Must have thorough knowledge of Costco, FSVP, FSMA, FSSC, BRC & HACPP. Working knowledge of grain handling processing, spices, frozen food and vegetables, snacks, culinary sauces will be preferred.",
  },
];
