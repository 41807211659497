import React from 'react'
import helper from '../helpers/helper';
import { Redirect,Route } from 'react-router-dom';
const CheckPermission = ({ component: Component,module, ...rest }) => {

    // let {pathname} = useLocation();
  
    let user;
    try {
      user = helper.getUser();
    } catch (error) {}
  
  
  
  
    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route
        {...rest}
        render={(props) =>
          user?.role?.permissions?.includes(module) ? <Component {...props} user={user} /> : <Redirect to="/admin" />
        }
      />
    );
  };

export default CheckPermission