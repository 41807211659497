import React, { useState, useEffect } from "react";
import banner from "../../images/media/banner.jpg";
import mbBanner from "../../images/media/mbBanner.png";
import BannerItem from "../../component/BannerItem/BannerItem";
import Button from "../../component/Button/Button";
import TvAds from "../../component/TvAds/TvAds";
import WOW from "wowjs";
import useWindowDimensions from "../../useWindowDimensions";
import "./style.less";
import PressRelease from "../../component/PressRelease/PressRelease";
import Events from "../../component/Events/Events";
import { Redirect } from "react-router-dom";
import loadergif from "../../images/loader.gif";
import axios from "axios";
import urldata from "../../urldata";
import Imageurldata from "../../Imageurldata";
const Media = (props) => {
  const { height, width } = useWindowDimensions();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pressRelease, setpressRelease] = useState([]);
  const [tvAds, settvAds] = useState([]);
  const [mediaBanner, setmediaBanner] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  useEffect(() => {
    let url = new URL(urldata + "user/getMediaData");

    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const pressreleasedata = response.data.data;
        settvAds(pressreleasedata.tvAds);
        setpressRelease(pressreleasedata.pressrelease);
        setmediaBanner(pressreleasedata.bannerData);
        // console.log(setAboutData);
        setLoading(false);
      })
      .catch((error) => {
        props.history.push("/500");
      });
    //console.log(props.match.params.id)
    switch (props.match.params.id) {
      case "tv-ads":
        setTab(0);
        break;

      case "press-release":
        setTab(1);
        break;
      // case "events":
      //   setTab(2)
      //   break

      default:
        props.history.push("/media/tv-ads");
        break;
    }
  }, [props.match.params.id]);

  if (loading) {
    return (
      <div className="loadergifdiv">
        <img src={loadergif} className="img-fluid" />
      </div>
    );
  }

  return (
    <>
      <BannerItem
        img={
          width > 640
            ? Imageurldata + mediaBanner[0].imgUrl
            : Imageurldata + mediaBanner[0].mbimgUrl
        }
        title={mediaBanner[0].title}
        color="white"
      />
      <div className="mediaTabContainer">
        <div
          onClick={() => props.history.push("tv-ads")}
          className={tab === 0 ? "active tab" : "tab"}
        >
          TV ADS
        </div>
        <div
          onClick={() => props.history.push("press-release")}
          className={tab === 1 ? "active tab" : "tab"}
        >
          PRESS RELEASE
        </div>
        {/* <div
          onClick={() => props.history.push("events")}
          className={tab === 2 ? "active tab" : "tab"}
        >
          EVENTS
        </div> */}
      </div>

      <section>{tab === 0 ? <TvAds tvAdsArr={tvAds} /> : null}</section>
      <section>
        {tab === 1 ? <PressRelease pressReleaseArr={pressRelease} /> : null}
      </section>
      {/* <section>{tab === 2 ? <Events /> : null}</section> */}
    </>
  );
};

export default Media;
