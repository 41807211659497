import React from "react"
import "./style.less"

const Button = (props) => {
  return (
    <div onClick={props.onClick} className={`button ${props.title === 'DOWNLOAD BACKUP' ? 'btnDownload': ''}`}>
      {props.title}
    </div>
  )
}

export default Button
