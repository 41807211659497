import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const CareerSubBanner = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [text1, setText1] = useState("");
  const [text1Err, setText1Err] = useState("");

  const [text2, setText2] = useState("");
  const [text2Err, setText2Err] = useState("");

  const [toastlist, setToastList] = useState([]);
  let toastProperties = null;

  
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };


  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Career section two texts saved Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  useEffect(() => {
    let url = new URL(urldata + "admin/getcareerssec2");
    // url.searchParams.append("screen", "careers");
    // let decoded = decodeURIComponent(url);
    setLoading(true);
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        let data = response.data.data[0];
        setText1(data.title ? data.title: '');
        setText2(data.desc ? data.desc: '');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  }, []);

  const addBannerData = () => {
    let data = {}
    data.title = text1
    data.desc = text2

    let url = new URL(urldata + "admin/addcareerssec2");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        setLoading(false);
        showToast('success')
      })
      .catch((error) => {
        // console.log(error);
        // showToast('danger')
        setLoading(false);
      });
  };

  const submit = () => {
    let formValid = true;

    if (!text1) {
      formValid = false;
      setText1Err("*Please add Text one");
    } else {
      setText1Err("");
    }
    if (!text2) {
      formValid = false;
      setText2Err("*Please add Text two");
    } else {
      setText2Err("");
    }

    if (formValid) {
      addBannerData();
    }
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1>Career Sub Banner Section</h1>
      <br></br>
      <Row className="adminhomeaboutSec1">
        {/*sub row 1*/}
        <Col className="column" lg={3}>
          <div className="title">Text 1:- </div>
        </Col>
        <Col className="column" lg={9}>
          <ReactQuill
            value={text1}
            modules={modules}
            onChange={(e) => {setText1(e)}}
          />
          <h4 className="err">{text1Err}</h4>
        </Col>

        {/*sub row 2*/}
        <Col className="column" lg={3}>
          <div className="title">Text 2:- </div>
        </Col>
        <Col className="column" lg={9}>
         
          <ReactQuill
            value={text2}
            modules={modules}
            onChange={(e) => {setText2(e)}}
          />
          <h4 className="err">{text2Err}</h4>
        </Col>
        <Col className="column d-f j-c a-c">
          <Button onClick={submit} title="SUBMIT" />
        </Col>
      <Toast toastList={toastlist} position="top-right" />

      </Row>
    </>
  );
};

export default CareerSubBanner;
