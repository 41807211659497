import React, { useState } from "react";
import "./style.less";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PressReleaseItem = (props) => {
  // console.log(props.date);
  const [hover, setHover] = useState(false);
  function test(e) {
    e.preventDefault();
    window.open(props.modelImg);
  }
  return (
    <Col lg={3} className="p-0 ">
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`pressReleaseItem ${hover ? "hover" : ""}`}
      >
        <div className="itemTitleImg">
          <img src={props.img} className="img-fluid" />
        </div>
        <div className="itemDesc">{props.desc}</div>
        <div className="itemDate">{props.date}</div>
        {props.link === "" ? (
          <div
            style={hover ? { opacity: 1 } : { opacity: 0 }}
            onClick={test}
            className="link c-p"
          >
            Read More
          </div>
        ) : (
          <a
            className="c-p"
            style={hover ? { opacity: 1 } : { opacity: 0 }}
            href={props.link}
            target="_blank"
          >
            Read More
          </a>
        )}
      </div>
    </Col>
  );
};

export default PressReleaseItem;
