import React, { useEffect } from "react";
import uniqid from "uniqid";
import "./style.less";

const PaginationComponent = (props) => {
  //console.log("totalPageArray", props.totalPageArray)
  const paginNumberList = props.totalPageArray.map((item, i) => (
    <div
      key={uniqid()}
      className={
        props.selectedPage + 1 === item
          ? "SelectedPaginationNumberTxt"
          : "paginationNumberTxt c-p"
      }
      onClick={() => props.paginationNoClick(item)}
    >
      {item}
    </div>
  ));

  //  //console.log(props.totalPageArray)
  return <div className="d-f a-c">{paginNumberList}</div>;
};

export default PaginationComponent;
