import React, { useState, useEffect } from "react";
import moment from "moment";

import MaterialTable from "material-table";
import axios from "axios";
import urldata from "../../../../urldata";
import Imageurldata from "../../../../Imageurldata";

import Button from "../../../../component/Button/Button";
import { Col, Row, Modal } from "react-bootstrap";
import InputText from "../../../../component/InputText/InputText";

import upload from "../../../../images/careers/upload.svg";
import Toast from "../../../../component/Toast/Toast";
import close from "../../../../images/close.svg";
import check from "../../../../images/check.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { computeHeadingLevel } from "@testing-library/dom";

// const date = moment('2021-05-04').toISOString()
// const dateObj = date;
// console.log(date);

const PressRelease = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [updateModel, setUpdateModel] = useState(false);

  const [pressId, setPressId] = useState("");

  const [show, setShow] = useState(false);

  const [pressTitle, setPressTitle] = useState("");
  const [pressTitleErr, setPressTitleErr] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");

  const [dateVal, setDateVal] = useState("");
  const [dateErr, setDateErr] = useState("");

  const [dateInSentenceFormat, setDateInSentenceFormat] = useState("");

  const [dateIso, setDateIso] = useState("");

  const [pressLink, setPressLink] = useState("");
  const [pressLinkErr, setPressLinkErr] = useState("");

  const [pressImg, setPressImg] = useState("");
  const [pressImgErr, setPressImgErr] = useState("");

  const [showImg, setShowImg] = useState("");
  const [imgNotPresent, setImgNotPresent] = useState('')

  const [toastlist, setToastList] = useState([]);

  let toastProperties = null;
  // let dateFormat;

  useEffect(() => {
    setLoading(true);

    let url = new URL(urldata + "admin/getPressRelase");
    axios({
      method: "post",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        //showToast("danger");
      });
  }, []);

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);
    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: `Press release ${updateModel ? 'updated':'saved'} Successfully`,
          backgroundColor: "#329F33",
          icon: check,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Something Went Wrong",
          description: "",
          backgroundColor: "red",
          icon: close,
        };
        break;
      case "delete":
        toastProperties = {
          id,
          title: "Success",
          description: "Press release deleted Successfully",
          backgroundColor: "#329F33",
          icon: check,
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([...toastlist, toastProperties]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUpdateModel(false);
    setPressTitle("");
    setDescription("");
    setPressLink("");
    setDateVal("");
    setDateInSentenceFormat("");
    setShow(true);

    setShowImg(false);
    setPressImg("");
  };

  //getting proper date format for dateVal and dateIso state
  const dateArrangements = (e) => {
    setDateVal(e.target.value);
    // const date = moment(e.target.value);
    // const date = moment(e.target.value).format('MMMM Do YYYY');
    const date = moment(e.target.value).format('LL');
    // console.log(date);
    // const dateObj = date._d;

    // const dateArr = dateObj.toString().split(" ");
    // const newDateArr = dateArr.filter((item, index) => {
    //   if (index === 1 || index === 2 || index === 3) {
    //     return item;
    //   }
    // });

    // let finalDateVal = `${newDateArr[1]} ${newDateArr[0]}, ${newDateArr[2]}`;

    setDateInSentenceFormat(date);
    setDateIso(moment(e.target.value).toISOString());
  };

  const setAllDates = (date) => {
    let properDateFormat = moment(date);
    let dateComponent = moment(date).format('L');
    const dateFormatForG = dateComponent.split("/")
    dateComponent = `${dateFormatForG[2]}-${dateFormatForG[0]}-${dateFormatForG[1]}`
    setDateVal(dateComponent);
    setDateInSentenceFormat(date);
  };

  const handleShowForUpdate = (rowData) => {
    setUpdateModel(true);
    setPressTitle(rowData.title);
    setDescription(rowData.desc);
    setPressLink(rowData.link);
    setShow(true);
    setPressId(rowData._id);
    
    setPressImg(Imageurldata+rowData.imgUrl);
    setShowImg(false);
    setImgNotPresent(rowData.imgUrl ? false: true)
    // console.log(rowData.date)
    setAllDates(rowData.date);
  };

  const updatePressRelease = () => {
    let formData = new FormData();
    formData.append("desc", description);
    formData.append("date", dateInSentenceFormat);
    formData.append("dateIso", dateIso);
    formData.append("link", pressLink);
    formData.append("title", pressTitle);

    if(imgNotPresent){
      formData.append("logo", pressImg);
      setImgNotPresent(false)
    }

    let url = new URL(urldata + "admin/updatePressRelase");

    url.searchParams.append("id", pressId);
    var decoded = decodeURIComponent(url);
    setLoading(true);
    axios({
      method: "post",
      url: decoded,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setShow(false);
        setLoading(false);
        showToast("success");

      })
      .catch((error) => {
        // showToast("danger");
        // console.log("error", error);
        setLoading(false);
      });
  };

  const addPressRelease = () => {
    let formData = new FormData();
    formData.append("logo", pressImg);
    formData.append("desc", description);
    formData.append("date", dateInSentenceFormat);
    formData.append("dateIso", dateIso);
    formData.append("link", pressLink);
    formData.append("title", pressTitle);

    let url = new URL(urldata + "admin/addPressRelase");

    setLoading(true);
    axios({
      method: "post",
      url: url,
      data: formData,
      withCredentials: true,
    })
      .then((response) => {
        const tempList = response.data.data.reverse();
        setList(tempList);
        setShow(false);
        setLoading(false);
        showToast('success')
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
        // showToast('danger')
      });
  };

  const submit = () => {
    let formIsValid = true;

    if (!pressTitle) {
      formIsValid = false;
      setPressTitleErr("*Please add press release Title");
    } else {
      setPressTitleErr("");
    }

    if (!description) {
      formIsValid = false;
      setDescriptionErr("*Please add press release description");
    } else {
      setDescriptionErr("");
    }

    if (!dateInSentenceFormat) {
      formIsValid = false;
      setDateErr("*Please add press release date");
    } else {
      setDateErr("");
    }

    if (!pressLink) {
      formIsValid = false;
      setPressLinkErr("*Please add press release link");
    } else {
      setPressLinkErr("");
    }

    if (!pressImg) {
      formIsValid = false;
      setPressImgErr("*Please add press release image");
    } else {
      setPressImgErr("");
    }
    if (formIsValid) {
      if (updateModel) {
        updatePressRelease();
      } else {
        addPressRelease();
      }
    }
  };

  const deleteBtnClick = (id) => {
    setLoading(true);
    let data = {};
    data.id = id;

    let url = new URL(urldata + "admin/deletePressRelase");

    axios({
      method: "post",
      url: url,
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        //   showToast("success");
        //   props.onHide(); props.history.push('/admin/login')
        // console.log(response.data.data);
        const templist = response.data.data.reverse();
        setList(templist);
        setLoading(false);
        showToast("delete");
      })
      .catch((error) => {
        // showToast("danger");
        // console.error(error);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ed1c24"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="addjobinlist text-right mb-4">
        <Button title="Add Press Release" onClick={handleShow} />
      </div>
      <MaterialTable
        title="Press List"
        columns={[
          { title: "Title", field: "title" },
          { title: "Description", field: "desc" },
          { title: "Date", field: "date" },
          { title: "Link", field: "link" },
          {
            field: "imgUrl",
            title: "Press Image",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title="View"
                  onClick={() => {
                    window.open(Imageurldata + rowData.imgUrl, "_blank");
                  }}
                ></Button>
              </div>
            ),
          },
          {
            field: "url",
            title: "Action  ",
            render: (rowData) => (
              <div className="d-f">
                <Button
                  title=" Update"
                  onClick={() => {
                    handleShowForUpdate(rowData);
                  }}
                ></Button>
                <Button
                  title=" Delete"
                  onClick={() => deleteBtnClick(rowData._id)}
                ></Button>
              </div>
            ),
          },
        ]}
        data={list}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        {...props}
        dialogClassName="addbannerModel"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="model" style={{ position: "relative" }}>
          <div className="title">
            {updateModel ? "Update Press Release" : "Add Press Release"}
          </div>

          <Row>
            {/* col - 0 */}
            <Col lg={3} className="column">
              <div className="titleitem">Title</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={pressTitle}
                onChange={(e) => setPressTitle(e.target.value)}
                type="text"
              />

              <div className="err">{pressTitleErr}</div>
            </Col>

            {/* col 1 */}
            <Col lg={3} className="column">
              <div className="titleitem">Description</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                maxLength="48"
              />
              <div className="count">{description?.length}/48</div>
              <div className="err">{descriptionErr}</div>

            </Col>

            {/* col 2 */}
            <Col lg={3} className="column">
              <div className="titleitem">Date</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={dateVal}
                onChange={dateArrangements}
                type="date"
                min="1972-01-01"
                max="2070-12-31"
              />
              <div className="err">{dateErr}</div>
            </Col>

            {/* col-3 */}
            <Col lg={3} className="column">
              <div className="titleitem">Link</div>
            </Col>
            <Col lg={9} className="column">
              <input
                value={pressLink}
                onChange={(e) => setPressLink(e.target.value)}
                type="text"
              />

              <div className="err">{pressLinkErr}</div>
            </Col>

            {/* col-4 */}
            <Col lg={3} className="column">
              <div className="titleitem">Press Release Image</div>
              <div className="desc">Size :- 196 × 33</div>
            </Col>
            <Col lg={9} className="column">
              <input
                id="bannerImage"
                accept="image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG,  image/svg, image/SVG"
                type="file"
                onChange={(e) => {
                  setPressImg(e.target.files[0]);
                  setShowImg(true);
                  setImgNotPresent(true);
                }}
                name="passport_proof_upload"
              />

              <label htmlFor="bannerImage" className="d-f" id="file-drag">
                <img src={upload} className="img-fluid" />
                <div className="chooseTxt">Choose File</div>
              </label>

              <div className="d-f jc-sb">
                <div className="fileTxt">File Format : JPG / png</div>
                <div className="fileTxt text-primary">
                  {pressImg ? pressImg.name : ""}
                </div>
              </div>
              <div className="err">{pressImgErr} </div>
            </Col>
          </Row>
          <div className="btnContainer btn_press mt-4">
            <Button onClick={submit} title="SUBMIT" />
          </div>

          <div className="text-right">
            {pressImg !== "" && (
              <img
                src={showImg ? URL.createObjectURL(pressImg) : pressImg}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Toast toastList={toastlist} position="top-right" />
    </>
  );
};

export default PressRelease;
